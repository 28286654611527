import { AxiosResponse } from 'axios';
import { catalogueApi } from '../../../services/apiHandlers/catalogueApi';
import {
  levelPayload,
  levelsAction,
} from '../../../models/services/module.catalogue';
import { NotificationErrorProvider } from '../../../utils/Notificationprovider';
import {
  requestMultipleLevelsFailure,
  requestMultipleLevelsSuccess,
} from '../../actions/catalogues/actions';
import { put } from 'redux-saga/effects';
import toast from 'react-hot-toast';
export function* startGetAllLevels(action: levelsAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.getAllLevels();
    if (response.data) {
      action._ && action._(response.data?.data?.result ?? []);
    }
  } catch (error: any) {
    NotificationErrorProvider(error);
  }
}
export function* getLevelById(action: levelsAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.getLevelById(
      '' + action.id,
    );
    if (response.data) {
      action._ && action._(response.data?.data?.result);
    }
  } catch (error: any) {
    NotificationErrorProvider(error);
  }
}
export function* startPostLevels(action: levelsAction) {
  try {
    console.log(' action.level', action.levelPayload);
    const response: AxiosResponse = yield catalogueApi.postLevels(
      action.levelPayload as levelPayload,
    );
    if (response.data) {
      action._ && action._();
    }
  } catch (error: any) {
    NotificationErrorProvider(error);
  }
}
export function* deleteLevels(action: levelsAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.deleteLevel(
      '' + action.id,
    );
    if (response.data) {
      action._ && action._();
    }
  } catch (error: any) {
    NotificationErrorProvider(error);
  }
}

const messages: Record<string, string> = {
  '0': 'Suppression effectuée avec succès',
  '1': 'Activation effectuée avec succès',
  '2': 'Désactivation effectuée avec succès',
};
export function* handleMultipleLevelsRequest(action: any) {
  try {
    const { idEntreprise, action: actionTypes, data, _ } = action.payload;
    console.log("actionTypes",actionTypes)
    const response: AxiosResponse =
      yield catalogueApi.actionMultipleLevelReducer(
        idEntreprise,
        actionTypes,
        data,
      );
    if (response.data) {
      yield put(requestMultipleLevelsSuccess(response?.data?.data?.result));
      if (messages[actionTypes]) {
        toast.success(messages[actionTypes]);
      }
      _ && _();
    }
  } catch (error: any) {
    requestMultipleLevelsFailure(error);
  }
}
