export const fields = [
    {
      name: 'designation',
      label: 'Désignation',
      required: true,
    },
    {
      name: 'conversionAmount',
      label: 'Montant de conversion  (€)',
      type: 'number',
      required: true,
    },
    {
      name: 'loyaltyPointsAssociated',
      label: 'Nombre de points à gagner',
      type: 'number',
      required: true,
    },
    {
      name: 'isActive',
      label: 'isActive',
      type: 'checkbox',
    },
  ];
  export const fieldsUpdates = [
    {
      name: 'designation',
      label: 'Désignation',
      required: true,
    },
    {
      name: 'conversionAmount',
      label: 'Montant de conversion  (€)',
      type: 'number',
      required: true,
    },
    {
      name: 'loyaltyPointsAssociated',
      label: 'Nombre de points à gagner',
      type: 'number',
      required: true,
    },
    {
      name: 'isActive',
      label: 'isActive',
      type: 'checkbox',
    },
  ];
  export const Recompense = [
    {
      name: 'designation',
      label: 'Désignation',
      required: true,
    },
    {
      name: 'subscriptionRewardTotalPt',
      label: 'Subscription Reward Total Points',
      type: 'number',
      required: true,
    },
    {
      name: 'firstOrderRewardTotalPt',
      label: 'First Order Reward Total Points',
      type: 'number',
      required: true,
    },
    {
      name: 'visitsRewardTotalPt',
      label: 'Visits Reward Total Points',
      type: 'number',
      required: true,
    },
    {
      name: 'nbOrdersForVisitsReward',
      label: 'Number of Orders for Visits Reward',
      type: 'number',
      required: true,
    },
    {
      name: 'rewardTotalPtRequired',
      label: 'Reward Total Points Required',
      type: 'number',
      required: true,
    },
    {
      name: 'rewardDiscountType',
      label: 'Reward Discount Type',
      type: 'number',
      required: true,
    },
    {
      name: 'rewardDiscountValue',
      label: 'Reward Discount Value',
      type: 'number',
      required: true,
    },
    {
      name: 'isActive',
      label: 'Is Active',
      type: 'checkbox',
    },
    {
      name: 'isSubscriptionRewardActive',
      label: 'Is Subscription Reward Active',
      type: 'checkbox',
    },
    {
      name: 'isFirstOrderRewardActive',
      label: 'Is First Order Reward Active',
      type: 'checkbox',
    },
    {
      name: 'isVisitsRewardActive',
      label: 'Is Visits Reward Active',
      type: 'checkbox',
    },
    {
      name: 'isCartReward',
      label: 'Is Cart Reward',
      type: 'checkbox',
    },
    {
      name: 'isArticlesReward',
      label: 'Is Articles Reward',
      type: 'checkbox',
    },
  ];