import React, { useEffect, useState } from 'react';
import ButtonElement from '../../../components/button/button';
import { languageData } from '../../../i18n';
import ArticlesIconPrimary from '../../../assets/icons/ArticlesIconPrimary.svg';
import InputField from '../../../components/Input/Input';
import SwitcherCheckbox from '../../../components/Catalogue/SwitcherCheckbox';
import Modal from '../../../components/Modal/Modal';
import ModalArticles from './ModalArticles';
import ModalArticlesEdit from './ModalArticlesEdit';

/**
 * Modal component description.
 *
 * @Modal
 *
 */

interface AddRecompensePropsPage {
  onClickSave?: () => void;
  onClickClose?: () => void;
  isActivated?: boolean;
  onChange?: any;
  rewardSettings?: any;
  errors?: any;
  handleRadioChangeEdit?: any;
  handleArrayChange?: any;
  handleCheckboxChange?: any;
  articles?: any;
  checheked?: boolean;
  setArticleName?: any;
  selectedIds?: any;
  subFamiles?: any;
  filteredItems?: any;
  setFilteredItems?: any;
  inputValue?: any;
  setInputValue?: any;
  filteredItemssubfamily?: any;
  setFilteredItemssubfamily?: any;
  inputValuesubfamily?: any;
  setInputValuesubfamily?: any;
  families?: any;

}

const EditRecompensePage: React.FC<AddRecompensePropsPage> = ({
  onClickSave,
  onClickClose,
  isActivated,
  onChange,
  rewardSettings,
  errors,
  handleRadioChangeEdit,
  handleArrayChange,
  handleCheckboxChange,
  articles,
  checheked,
  setArticleName,
  selectedIds,
  subFamiles,
  filteredItems,
  setFilteredItems,
  inputValue,
  setInputValue,
  filteredItemssubfamily,
  setFilteredItemssubfamily,
  inputValuesubfamily,
  setInputValuesubfamily,
  families,
}) => {
  const [showArticles, setshowArticles] = useState(false);
  const [showModalArticles, setshowModalArticles] = useState(false);
  useEffect(()=>{
    setArticleName('');
    setInputValue('');
    setInputValuesubfamily("")
  },[showModalArticles])
  return (
    <div className="fl_col gp40 pd40-t-b">
      <div className="fl_row ai_c jc_s">
        <div className="fl_row jc_fe w-full designation-field ">
          <div className="fl_row  w-full ai_e gp40">
            <InputField
              isRequired={true}
              name="designation"
              onChange={onChange}
              type="text"
              label={'Désignation'}
              style="form"
              value={rewardSettings?.designation}
              isDisabled={false}
              haserror={errors?.designation ? true : false}
              errorMsg={errors?.designation}
            />
          </div>
        </div>
      </div>
      <div className="fl_col gp10">
        <div className="fl_row gp40 tab-one">
          <div className=" fl_row fl-1 gp40">
            <div className="fl_col gp20 fl-1">
              <fieldset
                className={`fl_row gp20 ${rewardSettings?.isSubscriptionRewardActive ? 'active-fieldset' : ''}`}
              >
                <legend className="pd10-r-l">
                  <SwitcherCheckbox
                    ischecked={rewardSettings?.isSubscriptionRewardActive}
                    onChange={onChange}
                    label={'Récompense de bienvenue'}
                    name="isSubscriptionRewardActive"
                  />
                </legend>
                <div className="pd20-r-l">
                  <InputField
                    isRequired={true}
                    name="subscriptionRewardTotalPt"
                    value={rewardSettings?.subscriptionRewardTotalPt}
                    onChange={onChange}
                    type="number"
                    label={'Nombre de points à gagner'}
                    style="form"
                    isDisabled={false}
                    haserror={errors?.subscriptionRewardTotalPt ? true : false}
                    decimal
                    errorMsg={errors?.subscriptionRewardTotalPt}
                  />
                </div>
              </fieldset>
            </div>

            <div className="fl_col gp20 fl-1">
              <fieldset
                className={`fl_row gp20 ${rewardSettings?.isFirstOrderRewardActive ? 'active-fieldset' : ''}`}
              >
                <legend className="pd10-r-l">
                  <SwitcherCheckbox
                    ischecked={rewardSettings?.isFirstOrderRewardActive}
                    onChange={onChange}
                    label={'Récompense sur 1er achat'}
                    name="isFirstOrderRewardActive"
                  />
                </legend>
                <div className="pd20-r-l">
                  <InputField
                    isRequired={true}
                    name="firstOrderRewardTotalPt"
                    value={rewardSettings?.firstOrderRewardTotalPt}
                    onChange={onChange}
                    type="number"
                    label={'Nombre de points à gagner'}
                    style="form"
                    isDisabled={false}
                    haserror={errors?.firstOrderRewardTotalPt ? true : false}
                    decimal
                    errorMsg={errors?.firstOrderRewardTotalPt}
                  />
                </div>
              </fieldset>
            </div>

            <div className="fl_col gp20 fl-1">
              <fieldset
                className={`fl_row gp20 ${rewardSettings?.isVisitsRewardActive ? 'active-fieldset' : ''}`}
              >
                <legend className="pd10-r-l">
                  <SwitcherCheckbox
                    ischecked={rewardSettings?.isVisitsRewardActive}
                    onChange={onChange}
                    label={'Récompense sur nombre commande'}
                    name="isVisitsRewardActive"
                  />
                </legend>
                <div className="fl_row gp20 pd20-r-l">
                  <InputField
                    isRequired={true}
                    name="nbOrdersForVisitsReward"
                    value={rewardSettings?.nbOrdersForVisitsReward}
                    onChange={onChange}
                    type="number"
                    label={'Nombre de visite requis'}
                    style="form"
                    isDisabled={false}
                    haserror={errors?.nbOrdersForVisitsReward ? true : false}
                    decimal
                    errorMsg={errors?.nbOrdersForVisitsReward}
                  />
                  <InputField
                    isRequired={true}
                    name="visitsRewardTotalPt"
                    value={rewardSettings?.visitsRewardTotalPt}
                    onChange={onChange}
                    type="number"
                    label={'Nombre de points à gagner'}
                    style="form"
                    isDisabled={false}
                    haserror={errors?.visitsRewardTotalPt ? true : false}
                    decimal
                    errorMsg={errors?.visitsRewardTotalPt}
                  />
                </div>
              </fieldset>
            </div>
          </div>
        </div>
        <hr className="mg20-t-b" />
        <div className="tab-two w-fit">
          <div className="fl_row gp40">
            <div className="fl_row gp20">
              <fieldset
                className={`fl_row gp20 ${rewardSettings?.isArticlesReward ? 'active-fieldset' : ''}`}
              >
                <legend>
                  <div className="pd20-r-l">
                    <SwitcherCheckbox
                      ischecked={rewardSettings?.isArticlesReward}
                      onChange={onChange}
                      label={'Remise sur les articles'}
                      name="isArticlesReward"
                    />
                  </div>
                </legend>

                <div className="fl_row gp40 ai_c fl-1">
                  <InputField
                    isRequired={true}
                    name="articleRewardTotalPtRequired"
                    value={rewardSettings?.articleRewardTotalPtRequired}
                    onChange={onChange}
                    type="number"
                    label={'Nbr de points requis'}
                    style="form"
                    isDisabled={false}
                    haserror={
                      errors?.articleRewardTotalPtRequired ? true : false
                    }
                    decimal
                    errorMsg={errors?.articleRewardTotalPtRequired}
                  />
                  <InputField
                    isRequired={true}
                    name="articleRewardDiscountValue"
                    value={rewardSettings?.articleRewardDiscountValue}
                    onChange={onChange}
                    type="number"
                    label={'Valeur de remise'}
                    style="form"
                    isDisabled={false}
                    haserror={errors?.articleRewardDiscountValue ? true : false}
                    // decimal
                    errorMsg={errors?.articleRewardDiscountValue}
                  />
                  <div className="fl_row gp40 ai_c">
                    <div className="fl_col gp5">
                      <p className="p12 w-600 DarkBlueGray-txt label-field">
                        Type de remise
                      </p>
                      <div className="toggle-container">
                        <div className="item-type">
                          <input
                            type="radio"
                            id="toggle-euro-1"
                            name="articleRewardDiscountType"
                            checked={
                              rewardSettings?.articleRewardDiscountType == 0
                            }
                            onChange={() =>
                              handleRadioChangeEdit(
                                0,
                                'articleRewardDiscountType',
                              )
                            }
                          />
                          <label
                            htmlFor="toggle-euro-1"
                            className="toggle-item"
                            id="euro"
                          >
                            €
                          </label>
                        </div>

                        <div className="item-type">
                          <input
                            type="radio"
                            id="toggle-percent-1"
                            name="articleRewardDiscountType"
                            checked={
                              rewardSettings?.articleRewardDiscountType == 1
                            }
                            onChange={() =>
                              handleRadioChangeEdit(
                                1,
                                'articleRewardDiscountType',
                              )
                            }
                          />
                          <label
                            htmlFor="toggle-percent-1"
                            className="toggle-item"
                            id="percent"
                          >
                            %
                          </label>
                        </div>
                      </div>
                    </div>
                    <ButtonElement
                      reverse
                      buttonStyle="btn-white-primary"
                      type={'IconButton'}
                      text={'Articles'}
                      buttonSize={'large'}
                      onClick={() => setshowModalArticles(true)}
                      icon={ArticlesIconPrimary}
                      className="article-btn"
                    />
                  </div>
                </div>
              </fieldset>
            </div>
            <div className="fl_col gp20 fl-1">
              <fieldset
                className={`fl_row gp20 ${rewardSettings?.isCartReward ? 'active-fieldset' : ''}`}
              >
                <legend>
                  <div className="pd20-r-l">
                    <SwitcherCheckbox
                      ischecked={rewardSettings?.isCartReward}
                      onChange={onChange}
                      label={'Remise sur panier'}
                      name="isCartReward"
                    />
                  </div>
                </legend>
                <div className="fl_row gp40 ai_c">
                  <InputField
                    isRequired={true}
                    name="cartRewardTotalPtRequired"
                    value={rewardSettings?.cartRewardTotalPtRequired}
                    onChange={onChange}
                    type="number"
                    label={'Nbr de points requis'}
                    style="form"
                    isDisabled={false}
                    haserror={errors?.cartRewardTotalPtRequired ? true : false}
                    decimal
                    errorMsg={errors?.cartRewardTotalPtRequired}
                  />
                  <InputField
                    isRequired={true}
                    name="cartRewardDiscountValue"
                    value={rewardSettings?.cartRewardDiscountValue}
                    onChange={onChange}
                    type="number"
                    label={'Valeur de remise'}
                    style="form"
                    isDisabled={false}
                    haserror={errors?.cartRewardDiscountValue ? true : false}
                    // decimal
                    errorMsg={errors?.cartRewardDiscountValue}
                  />
                  <div className="fl_col gp5">
                    <p className="p12 w-600 DarkBlueGray-txt label-field">
                      Type de remise
                    </p>
                    <div className="toggle-container">
                      <div className="item-type">
                        <input
                          type="radio"
                          id="toggle-euro-2"
                          name="cartRewardDiscountType"
                          checked={rewardSettings?.cartRewardDiscountType == 0}
                          onChange={() =>
                            handleRadioChangeEdit(0, 'cartRewardDiscountType')
                          }
                        />
                        <label
                          htmlFor="toggle-euro-2"
                          className="toggle-item"
                          id="euro"
                        >
                          €
                        </label>
                      </div>

                      <div className="item-type">
                        <input
                          type="radio"
                          id="toggle-percent-2"
                          name="cartRewardDiscountType"
                          checked={rewardSettings?.cartRewardDiscountType == 1}
                          onChange={() =>
                            handleRadioChangeEdit(1, 'cartRewardDiscountType')
                          }
                        />
                        <label
                          htmlFor="toggle-percent-2"
                          className="toggle-item"
                          id="percent"
                        >
                          %
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="ModalDeletePointFidelite"
        version="custom"
        visibility={showModalArticles}
        close
        maxWidth={640}
        content={
          <ModalArticlesEdit
            families={families}
            articles={articles}
            handleArrayChange={handleArrayChange}
            handleCheckboxChange={handleCheckboxChange}
            setArticleName={setArticleName}
            checheked={checheked}
            onClickValidate={() => {
              setArticleName('');
              setshowModalArticles(false);
            }}
            selectedIds={selectedIds}
            rewardSettings={rewardSettings}
            onClickClose={() => {
              setArticleName('');
              setshowModalArticles(false);
            }}
            subFamiles={subFamiles}
            filteredItems={filteredItems}
            setFilteredItems={setFilteredItems}
            inputValue={inputValue}
            setInputValue={setInputValue}
            filteredItemssubfamily={filteredItemssubfamily}
            setFilteredItemssubfamily={setFilteredItemssubfamily}
            inputValuesubfamily={inputValuesubfamily}
            setInputValuesubfamily={setInputValuesubfamily}
            // isActivated
            // onChange={}
          />
        }
        onClickClose={() => setshowModalArticles(false)}
      />
    </div>
  );
};

export default EditRecompensePage;
