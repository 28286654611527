import * as actionTypes from '../actions/actionTypes';

const initialState = {
    loading: false,
    rewardRule: null,
    error: null,
  };
  
  const rewardRuleReducer = (state = initialState, action:any) => {
    switch (action.type) {
      case actionTypes.CREATE_REWARD_RULE_REQUEST:
        return { ...state, loading: true, error: null };
      case actionTypes.CREATE_REWARD_RULE_SUCCESS:
        return { ...state, loading: false, rewardRule: action.payload, error: null };
      case actionTypes.CREATE_REWARD_RULE_FAILURE:
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export default rewardRuleReducer;