import * as actionTypes from '../../actions/catalogues/actionTypes';

const initialState = {
  selectAll: false,
  selectedIds: [] as number[],
};

export const selectionReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_SELECT_ALL:
      return { ...state, selectAll: action.payload };
    case actionTypes.ClEAR_SELECT_ALL_MENU:
      return { ...state, selectAll: false, selectedIds: [] };
    case actionTypes.SET_SELECTED_IDS:
      return { ...state, selectedIds: action.payload };
    default:
      return state;
  }
};
export default selectionReducer;
