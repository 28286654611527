import * as actionTypes from '../../actions/catalogues/actionTypes';

const initialState = {
  selectAllCategories: false,
  selectedIdsCategories: [] as number[],
};

export const selectionCategoriesReducer = (
  state = initialState,
  action: any,
) => {
  switch (action.type) {
    case actionTypes.SET_SELECT_ALL_CATEGORIES:
      return { ...state, selectAllCategories: action.payload };
    case actionTypes.ClEAR_SELECT_ALL_MENU:
      return {
        ...state,
        selectAllCategories: false,
        selectedIdsCategories: [],
      };
    case actionTypes.SET_SELECTED_IDS_MENU_CATEGORIES:
      return { ...state, selectedIdsCategories: action.payload };
    default:
      return state;
  }
};
export default selectionCategoriesReducer;
