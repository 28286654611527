import React, { SetStateAction, useState } from 'react';
import { Dispatch } from 'redux';
import Modal from '../Modal/Modal';

interface RadioButtonGroupProps {
  selectedAction: string | null | any;
  setSelectedAction: any;
  handleSubmit?: (action: string) => void | undefined;
}

const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({
  selectedAction,
  setSelectedAction,
  handleSubmit
}) => {
  const handleActionChange = (value: string) => {
    setSelectedAction(value);
  };
  const [showModalDelete, setModalDelete] = useState(false);
  const [showDisableVisibility, setDisableVisibility] = useState(false);
  const [showEnableVisibility, setEnableVisibility] = useState(false);

  return (
    <div className="btns-action-select fl_row gp10">
      <button
        className="medium btn btn-red"
        type="button"
        onClick={() => setModalDelete(true)}
      >
        Supprimer
      </button>

      <button
        className="medium btn  btn-black-light"
        type="button"
        onClick={() => setDisableVisibility(true)}
      >
        Désactiver Visibilité
      </button>

      <button
        className="medium btn btn-primary-light"
        type="button"
        onClick={() => setEnableVisibility(true)}
      >
        Activer Visibilité
      </button>

      <Modal
        className="confirmation-modification"
        close
        version={'Alert'}
        type="confirm"
        target="any"
        confirm
        reject
        onClickConfirm={() => {
          handleSubmit && handleSubmit('0');
          setModalDelete(false);
        }}
        onClickClose={() => setModalDelete(false)}
        title={'Confirmer la modification de la visibilité'}
        visibility={showModalDelete}
        description={['Etes-vous sûr de vouloir supprimer?']}
      />

      <Modal
        className="confirmation-modification"
        close
        version={'Alert'}
        type="confirm"
        target="any"
        confirm
        reject
        onClickConfirm={() => {
          handleSubmit && handleSubmit('1');
          setEnableVisibility(false);
        }}
        onClickClose={() => setEnableVisibility(false)}
        title={'Confirmer la modification de la visibilité'}
        visibility={showEnableVisibility}
        description={['Etes-vous sûr de vouloir Activer Visibilité?']}
      />

      <Modal
        className="confirmation-modification"
        close
        version={'Alert'}
        type="confirm"
        target="any"
        confirm
        reject
        onClickConfirm={() => {
          handleSubmit && handleSubmit('2');
          setDisableVisibility(false);
        }}
        onClickClose={() => setDisableVisibility(false)}
        title={'Confirmer la modification de la visibilité'}
        visibility={showDisableVisibility}
        description={['Etes-vous sûr de vouloir Désactiver Visibilité?']}
      />
    </div>
  );
};

export default RadioButtonGroup;
