import React, { ReactNode, useState } from 'react';
import CheckBox from '../Catalogue/Checkbox';

/**
 * CartCheckboxParentElement component description.
 *
 * @CartCheckboxParentElement
 *
 */

interface CartCheckboxParentElementProps {
  name: string;
  open?: boolean;
  children?: ReactNode;
  isChecked?: boolean;
  isCheckedAll?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  circle?: boolean;
  stylepurpel?: boolean;
  // hideBg?: boolean;
  selectAll?: boolean;
  onChangeSelectAll?:(event: React.ChangeEvent<HTMLInputElement>) => void;
  BoxTypeSelectAll?:
    | 'fullySelected'
    | 'notFullySelected'
    | 'emptySelection'
    | 'squareBox'
    | 'roundedBox';
  boxType?:
    | 'fullySelected'
    | 'notFullySelected'
    | 'emptySelection'
    | 'squareBox'
    | 'roundedBox';
}

const CartCheckboxParentElement: React.FC<CartCheckboxParentElementProps> = ({
  name,
  children,
  onChange,
  isChecked,
  isCheckedAll,
  circle,
  stylepurpel,
  selectAll,
  // hideBg,&&tr
  onChangeSelectAll,
  BoxTypeSelectAll,
  boxType,
}) => {
  return (
    <div className="CartCheckboxParentElement">
      <div
        className={` ${circle ? 'circle-checkbox' : ''} ${stylepurpel ? 'stylepurpel-checkbox' : ''}`}
      >
        <div
          className={`wrapper-elements fl_col ${isChecked ? 'ActiveList' : ''}`}
        >
          <div
            className="CheckboxParent fl_row jc_s ai_c"
            // style={
            //   hideBg
            //     ? {
            //         backgroundColor: 'white',
            //       }
            //     : undefined
            // }
          >
            <div className="left-side fl_row jc_s w-full">
              <div className="fl_row gp10">
                <CheckBox
                  isChecked={isChecked}
                  boxType={boxType}
                  label={name}
                  id={name}
                  name={name}
                  circle={circle}
                  onChange={(e) => {
                    if (onChange) {
                      onChange(e);
                    }
                  }}
                />
              </div>
              {selectAll && (
                <CheckBox
                  boxType={BoxTypeSelectAll}
                  onChange={onChangeSelectAll}
                  isChecked={isCheckedAll}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {children && (
        <div className="ChildList fl_row fl_wrap gp10 pd10-r-l pd10-t-b">
          {children}
        </div>
      )}
    </div>
  );
};

export default CartCheckboxParentElement;
