import {
  applyMiddleware,
  createStore,
  combineReducers,
  compose,
  StoreEnhancer,
  Store,
} from 'redux';
import createSagaMiddleware, { SagaMiddleware } from 'redux-saga';
import authReducer from './reducers/authReducer';
import rootSaga from './sagas/saga';
//import storage from 'redux-persist/lib/storage';
//import { persistReducer } from 'redux-persist';
import tabsReducer from './reducers/tabsReducer';
import entrepriseReducer from './reducers/entrepriseReducer';
import taxesInfosReducer from './reducers/taxesInfosReducer';
import storage from 'redux-persist/lib/storage';
import persistReducer from 'redux-persist/es/persistReducer';
import redevanceReducer from './reducers/redevanceReducer';
import formesJuridiquesReducer from './reducers/formesJuridiquesReducer';
import bankReducer from './reducers/bankReducer';
import etablissementReducer from './reducers/etablissementReducer';
import combineEtablissementReducer from './reducers/combineEtablissementReducer';
import etablissementStateChangedReducer from './reducers/etablissementStateChangedReducer';
import roleReducer from './reducers/roleReducer';
import appUserReducer from './reducers/userReducer';
import catalogueReducer from './reducers/catalogue/catalogueReducer';
import uiCatalogueReducer from './reducers/catalogue/uiReducer';
import compositionReducer from './reducers/catalogue/compositionReducer';
import allergenesReducer from './reducers/catalogue/allergenesReducer';
import badgeReducer from './reducers/catalogue/badgesReducer';
import tvaReducer from './reducers/catalogue/tvaReducer';
import tagReducer from './reducers/catalogue/tagsReducer';
import allergenReducer from './reducers/catalogue/algReducer';
import familyReducer from './reducers/catalogue/familyReducer';
import subFamilyReducer from './reducers/catalogue/subFamilyReducer';
import articleReducer from './reducers/catalogue/articleReducer';
import folderReducer from './reducers/catalogue/fileManagerReducer';
import menuLevels from './reducers/catalogue/menuLevelReducer';
import subMenu from './reducers/catalogue/subMenuReducer';
import categoryCart from './reducers/catalogue/cartReducer';
import { companyMachineTypesReducer } from './reducers/TypeMachineReducer';
import { machineSuperTypesReducer } from './reducers/MachineTypeSuperReducer';
import { CreatecompanyMachineTypesReducer } from './reducers/CreateTypeMachineReducer';
import syncEstablishmentMachinesReducer from './reducers/SyncEtablissementmachineReducer';
import EstablishmentListMachinesReducer from './reducers/EtablissemenListtmachineReducer';
import EstablishmentListMachinesSuperReducer from './reducers/EtablissemenListtmachineSuperReducer';
import machineTypesSuperReducer from './reducers/PostMachineTypeSuperReducer';
import FilteredetablissementsReducer from './reducers/FilterdEtablissemntReducer';
import intervallesAbonnementReducer from './reducers/ListIntervalLesAbonnementSuccessReducer';
import abonnementByIdReducer from './reducers/GetAbonnementByIdReducer';
import AddabonnementReducer from './reducers/CreateAbonnmet';
import ListAbonnementReducer from './reducers/ListAbonnemntReducer';
import updateAbonnement from './reducers/updateAbonnementReducer';
import getListStatutsEstablishementsReducer from './reducers/getListStatutsEstablishementsReducer';
import updateUserReducer from './reducers/updateUserReducer';
import updateUserPasswordReducer from './reducers/updatePasswordReducer';
import { abonnementStatusReducer } from './reducers/statusAbonnementReducer';
import factureReducer from './reducers/factureReducer';
import factureReducerDetails from './reducers/factureReducerDetails';
import activateDisactivateCampanyReducer from './reducers/activateDisactivateCampany';
import activateDisactivateEtablissmentReducer from './reducers/activateDisactivateEtablissment';
import forgetPasswordLinkReducer from './reducers/forgetPasswordLink';
import resetPasswordLinkReducer from './reducers/resetPasswordLink';
import clientAbonnementReducer from './reducers/abonnementClient';
import toastReducer from './reducers/toggleReducer';
import companyReducer from './reducers/ChartCompanyReducer';
import ChartcompanyReducer from './reducers/GetChartCompanyReducer';
import onlineOrderReducer from './reducers/onlineOrderReducer';
import createOnlineOrderReducer from './reducers/CreateonlineOrderReducer';
import CreateaccountReducer from './reducers/acountsReducer';
import UpdateaccountReducer from './reducers/updateacountsReducer';
import MenuReducer from './reducers/catalogue/MenuReducer';
import Marketing from '../container/Marketing/Marketing';
import companiesReducer from './reducers/companymarketing';
import loyaltyReducer from './reducers/loyaltyReducer';
import loyaltyActivatedesactivatedReducer from './reducers/loyaltyActivatedesactivatedReducer';
import loyaltyRuleReducer from './reducers/loyaltyRuleReducer';
import LoyaltyRulesList from './reducers/LoyaltyRulesList';
import deleteLoyaltyRuleReducer from './reducers/deleteLoyaltyRuleReducer';
import updateLoyaltyRuleReducer from './reducers/updateLoyaltyRuleReducer';
import pointdeVenteReducer from './reducers/pointdeVenteReducer';
import establishmentEffected from './reducers/establishmentEffected';
import selectionReducer from './reducers/catalogue/selectionReducer';
import multipleLevelsReducer from './reducers/catalogue/multipleLevelReducer';
import searchArrayReducer from './reducers/catalogue/searchArrayReducer';
import selectionMenuReducer from './reducers/catalogue/selectionMenuReducer';
import selectionCategoriesReducer from './reducers/catalogue/selectionCategoriesReducer';
import actionCategoriesReducer from './reducers/catalogue/actionCategoriesReducer';
import actionMenusReducer from './reducers/catalogue/actionMenusReducer';
import recomponseArticalesReducer from './reducers/recomponseArticalesReducer';
import rewardRuleReducer from './reducers/rewardRuleReducer';
import getrewardRuleReducer from './reducers/getrewardRuleReducer';
import estabmishmentRewordEffected from './reducers/estabmishmentRewordEffected';

/**
 * Type definition for the middleware.
 */
// eslint-disable-next-line
type Middleware = SagaMiddleware<any>;

/**
 * Interface for Redux store with saga task.
 */
interface StoreWithSagaTask extends Store {
  // eslint-disable-next-line
  sagaTask: any;
}

/**
 * Function to bind middleware.
 * @param middleware - List of middlewares.
 * @returns Composed middleware.
 */
const bindMiddleware = (middleware: Middleware[]): StoreEnhancer => {
  // eslint-disable-next-line
  const composeEnhancers =
    typeof window === 'object' &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          trace: true,
          traceLimit: 25,
        })
      : compose;
  return composeEnhancers(applyMiddleware(...middleware)) as StoreEnhancer;
};
/***persistor config */
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
};

/**
 * Root reducer combining all reducers.
 */
const rootReducer = combineReducers({
  auth: authReducer,
  tabs: tabsReducer,
  entreprise: entrepriseReducer,
  etablissement: etablissementReducer,
  combineEtablissementReducer: combineEtablissementReducer,
  etablissementStateChangedReducer: etablissementStateChangedReducer,
  taxesInfos: taxesInfosReducer,
  redevance: redevanceReducer,
  formesJuridiques: formesJuridiquesReducer,
  bankAccount: bankReducer,
  role: roleReducer,
  appUser: appUserReducer,
  catalogue: catalogueReducer,
  uiCatalogue: uiCatalogueReducer,
  compositionStates: compositionReducer,
  allergenesStates: allergenesReducer,
  badgeReducer: badgeReducer,
  tvaReducer: tvaReducer,
  tagReducer: tagReducer,
  allergenReducer: allergenReducer,
  familyReducer: familyReducer,
  subFamilyReducer: subFamilyReducer,
  articleReducer: articleReducer,
  folderReducer: folderReducer,
  menuLevelsStates: menuLevels,
  subMenuStates: subMenu,
  categoryCart: categoryCart,
  companyMachineTypes: companyMachineTypesReducer,
  companyMachineSuperTypes: machineSuperTypesReducer,
  addcompanyMachineTypes: CreatecompanyMachineTypesReducer,
  syncEstablishmentMachines: syncEstablishmentMachinesReducer,
  EstablishmentListMachines: EstablishmentListMachinesReducer,
  EstablishmentListMachinesSuper: EstablishmentListMachinesSuperReducer,
  AddEstablishmentMachinesSuper: machineTypesSuperReducer,
  Filteredetablissements: FilteredetablissementsReducer,
  intervallesAbonnementList: intervallesAbonnementReducer,
  AbonnementById: abonnementByIdReducer,
  CreateAbonnement: AddabonnementReducer,
  ListAbonnement: ListAbonnementReducer,
  updateAbonnement: updateAbonnement,
  getListStatutsEsta: getListStatutsEstablishementsReducer,
  updateUser: updateUserReducer,
  updatedPassword: updateUserPasswordReducer,
  abonnementStatus: abonnementStatusReducer,
  facture: factureReducer,
  factureDetails: factureReducerDetails,
  activateandDisactivateEtablissment: activateDisactivateEtablissmentReducer,
  activateandDisactivateCampany: activateDisactivateCampanyReducer,
  forgetPasswordLink: forgetPasswordLinkReducer,
  resetPasswordLink: resetPasswordLinkReducer,
  clientAbonnement: clientAbonnementReducer,
  toast: toastReducer,
  Chartcompany: companyReducer,
  GetChartcompany: ChartcompanyReducer,
  onlineOrder: onlineOrderReducer,
  createOnlineOrder: createOnlineOrderReducer,
  CreateAccount: CreateaccountReducer,
  UpdateAccount: UpdateaccountReducer,
  MenuReducer: MenuReducer,
  MarketingListCompanyReducer: companiesReducer,
  loyaltyReducerReducer: loyaltyReducer,
  loyaltyActivatedesactivatedReducer: loyaltyActivatedesactivatedReducer,
  loyaltyRuleReducer: LoyaltyRulesList,
  deleteLoyaltyRuleReducer: deleteLoyaltyRuleReducer,
  updateLoyaltyRuleReducer: updateLoyaltyRuleReducer,
  pointdeVenteReducer: pointdeVenteReducer,
  establishmentEffected: establishmentEffected,
  selectionReducer: selectionReducer,
  multipleLevelsReducer: multipleLevelsReducer,
  searchArrayReducer: searchArrayReducer,
  selectionMenuReducer: selectionMenuReducer,
  actionMenusReducer: actionMenusReducer,
  selectionCategoriesReducer: selectionCategoriesReducer,
  actionCategoriesReducer: actionCategoriesReducer,
  recomponseArticalesReducer: recomponseArticalesReducer,
  rewardRuleReducer: rewardRuleReducer,
  getrewardRuleReducer: getrewardRuleReducer,
  estabmishmentRewordEffected: estabmishmentRewordEffected,
});
/*function `persistReducer` to create a new reducer that adds persistence functionality to the existing
`rootReducer`. */
// eslint-disable-next-line
const persistedReducer = persistReducer<any>(persistConfig, rootReducer);
/**
 * Function to configure Redux store.
 * @param initialState - Initial state of the store.
 * @returns Configured Redux store.
 */
// eslint-disable-next-line
function configureStore(initialState: {}) {
  const sagaMiddleware: Middleware = createSagaMiddleware();
  const store: StoreWithSagaTask = createStore(
    persistedReducer,
    initialState,
    bindMiddleware([sagaMiddleware]),
  ) as unknown as StoreWithSagaTask;

  store.sagaTask = sagaMiddleware.run(rootSaga);

  return store;
}

export default configureStore;
