import React from 'react';
import ButtonElement from '../../components/button/button';
import CartCheckboxChildElement from '../../components/Carts/CartCheckboxChildElement';
import CartCheckboxParentElement from '../../components/Carts/CartCheckboxParentElement';
import { languageData } from '../../i18n';
import CloseBtnPrimary from '../../../assets/icons/CloseBtnPrimary.svg';
import InputField from '../../components/Input/Input';
import CheckBox from '../../components/Catalogue/Checkbox';

/**
 * Modal component description.
 *
 * @Modal
 *
 */

interface ConfirmModalPointProps {
  onClickConfirm?: () => void;
  onClickClose?: () => void;
  isActivated?: boolean;
  onChange?: () => void;
}

const ConfirmModalPoint: React.FC<ConfirmModalPointProps> = ({
  onClickConfirm,
  onClickClose,
  isActivated,
  onChange,
}) => {
  return (
    <div className="ConfirmModalPoint fl_col gp40">
      <div className="fl_col gp10">
        <p className="p20 w-600">
        Alerte : Point(s) de vente déjà assigné(s)
        </p>
        <p className="p14">
        Un ou plusieurs des points de vente choisis ont déjà été affectés à une autre règle. Voulez-vous les écraser et les affecter à cette règle ?
 
        </p>
      </div>
      <div className="ModalFooter">
        <div className="wrapperFooter">
          <div className="fl_row gp12">
            <div className="fl-1">
              <ButtonElement
                type={'TextButton'}
                text={languageData.fr.Modal.cancel}
                buttonSize={'small'}
                onClick={onClickClose}
              />
            </div>
            <div className="fl-3">
              <ButtonElement
                type={'TextButton'}
                text="Valider"
                buttonSize={'small'}
                buttonStyle="btn-primary"
                onClick={onClickConfirm}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModalPoint;
