import * as actionTypes from '../actions/actionTypes';

const initialState = {
  loading: false,
  ListrewardRule: null,
  error: null,
};

const getrewardRuleReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.GET_REWARD_RULE_REQUEST:
      return { ...state, loading: true, error: null };
    case actionTypes.GET_REWARD_RULE_SUCCESS:
      return {
        ...state,
        loading: false,
        ListrewardRule: action.payload,
        error: null,
      };
    case actionTypes.GET_REWARD_RULE_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default getrewardRuleReducer;
