import toast from 'react-hot-toast';
import { serviceCampanyMachineTypeModels } from '../../models/services/module.CampanyMachineType';
import { serviceEntrepriseModels } from '../../models/services/module.entreprise';

import axiosInstance, { BaseUrl, authUploadAxios } from './apiGate';
import { serviceMarketingModels } from '../../models/services/module.serviceMarketingModels';

const entrepriseApi = {
  add: async (body: serviceEntrepriseModels.Entreprise) => {
    try {
      const response = await axiosInstance.post(
        BaseUrl + '/v1/addCompany',
        body,
      );

      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getAllCompanies: async (
    body: servicePaginationModels.PaginationModelType,
  ) => {
    try {
      const response = await axiosInstance.post(
        BaseUrl +
          `/v1/getListCompanies?page=${body.paginationModel.page}&pageSize=${body.paginationModel.pageSize}&claimType=${'Entreprise'}&module=${'Géneral.Affichage'}`,
        body?.paginationModel?.body ? body?.paginationModel?.body : body,
      );

      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getAllCompaniesWithEstablishements: async (
    body: servicePaginationModels.PaginationModelType,
  ) => {
    try {
      const response = await axiosInstance.get(
        BaseUrl +
          `/GMPV/getCompanies?page=${body.paginationModel.page}&pageSize=${body.paginationModel.pageSize}&module=${body.module}`,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getCompanyByIdInfos: async (id: number) => {
    try {
      const response = await axiosInstance.get(
        BaseUrl + `/v1/getInfosCompany?companyId=${id}`,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getCompanyByIdSheet: async (id: number) => {
    try {
      const response = await axiosInstance.get(
        BaseUrl + `/v1/getCompanySheet?companyId=${id}`,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getCompanyFinancialInfos: async (id: number) => {
    try {
      const response = await axiosInstance.get(
        BaseUrl + `/v1/getCompanyFinancialInfos?companyId=${id}`,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  editCompanyFinancialInfos: async (
    body: serviceEntrepriseModels.financialInformation,
  ) => {
    try {
      const response = await axiosInstance.put(
        BaseUrl + `/v1/updateFinancialInfosCompany?companyId=${body.id}`,
        body,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  editCompanyGenaralInfos: async (
    body: serviceEntrepriseModels.formDataGeneralInfosTypeActionPayload,
  ) => {
    try {
      // const formData = new FormData();
      // formData.append('email', body.email);
      // formData.append(
      //   'fixed_line_telephone_number',
      //   body.fixed_line_telephone_number,
      // );
      // formData.append('logo', body.logo ?? '');
      // formData.append('mobile_telephone_number', body.mobile_telephone_number);
      const response = await authUploadAxios.put(
        BaseUrl + `/v1/updateInfosCompany?companyId=${body.id}`,
        body,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getCampanyMachineTypes: async (idCampany: number) => {
    try {
      const response = await axiosInstance.get(
        BaseUrl + `/v1/getCompanyMachineTypes?companyId=${idCampany}`,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getCampanyMachineTypesbysuperType: async (
    idCampany: number,
    superType: string,
  ) => {
    try {
      const response = await axiosInstance.get(
        BaseUrl +
          `/v1/GetMachineTypesBySuperType?companyId=${idCampany}&pageSize=1000&superType=${superType}`,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getCampanyMachineSuperTypes: async () => {
    try {
      const response = await axiosInstance.get(
        BaseUrl + `/v1/getMachineSuperTypes`,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addCompanyMachineType: async (
    payload: serviceCampanyMachineTypeModels.MachineTypeRequest,
  ) => {
    try {
      const response = await axiosInstance.post(
        BaseUrl + `/v1/addCompanyMachineTypes?companyId=${payload.companyId}`,
        payload,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateCampanyMachineType: async (machineTypeId: number, body: string) => {
    try {
      const response = await axiosInstance.put(
        BaseUrl +
          `/v1/updateCompanyMachineTypes?machineTypeId=${machineTypeId}`,
        { designation: body },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteCampanyMachineType: async (machineTypeId: number) => {
    try {
      const response = await axiosInstance.put(
        BaseUrl + `/v1/DeleteMachineTypes?id=${machineTypeId}`,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  getExistingCompanys: async () => {
    try {
      const response = await axiosInstance.get(
        BaseUrl + `/v1/getExistingCompanys`,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  activateDeactivateCampany: async (idCompany: number, data: boolean) => {
    try {
      const response = await axiosInstance.put(
        BaseUrl + `/v1/activateDeactivateCompany?idCompany=${idCompany}`,
        data,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  getListCompaniesForMarketingModule: async () => {
    try {
      const response = await axiosInstance.get(
        BaseUrl +
          `/v1/getListCompaniesForMarketingModule?claimType=Entreprise&module=Géneral.Affichage`,
      );

      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  fetchLoyaltyStatus: async (companyId: number) => {
    try {
      const response = await axiosInstance.get(
        BaseUrl + `/v1/getIsLoyaltyCentralizedCompany?companyId=${companyId}`,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  activateDeactivateLoyalty: async (
    companyId: number,
    IsLoyaltyCentralized: boolean,
  ) => {
    try {
      const response = await axiosInstance.get(
        BaseUrl +
          `/v1/activateDeactivateIsLoyaltyCentralizedForCompany?companyId=${companyId}&IsLoyaltyCentralized=${IsLoyaltyCentralized}`,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  createLoyaltyRule: async (
    companyId: number,
    body: serviceMarketingModels.LoyaltyRulePayload,
  ) => {
    try {
      const response = await axiosInstance.post(
        BaseUrl + `/v1/createLoyaltyRule?companyId=${companyId}`,
        body,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  getLoyaltyRules: async (companyId: number) => {
    try {
      const response = await axiosInstance.get(
        BaseUrl + `/v1/getlListLoyaltyRules?companyId=${companyId}`,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  deleteLoyaltyRule: async (loyaltyRuleId: number) => {
    try {
      const response = await axiosInstance.delete(
        BaseUrl + `/v1/deleteLoyaltyRuleById?loyaltyRuleId=${loyaltyRuleId}`,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  updateLoyaltyRule: async (loyaltyRuleId: number, body: string) => {
    try {
      const response = await axiosInstance.put(
        BaseUrl + `/v1/updateLoyaltyRule?loyaltyRuleId=${loyaltyRuleId}`,
        body,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  getlistEstablishments: async (companyId: number, loyaltyRuleId: number) => {
    try {
      const response = await axiosInstance.get(
        BaseUrl +
          `/v1/getListEstablishementsForLoyaltyRule?companyId=${companyId}&loyaltyRuleId=${loyaltyRuleId}`,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  affectLoyaltyRuleToEstablishmentsApi: async (
    loyaltyRuleId: number,
    establishmentIds: number[],
    forcedToCrush: boolean,
  ) => {
    try {
      if (forcedToCrush) {
        console.log('forcedToCrush', forcedToCrush);
        const response = await axiosInstance.put(
          BaseUrl +
            `/v1/affectLoyaltyRuleToEstablishments?loyaltyRuleId=${loyaltyRuleId}&forcedToCrush=${forcedToCrush}`,
          establishmentIds,
        );
        return response.data;
      } else {
        const response = await axiosInstance.put(
          BaseUrl +
            `/v1/affectLoyaltyRuleToEstablishments?loyaltyRuleId=${loyaltyRuleId}`,
          establishmentIds,
        );
        return response.data;
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  affectRewodRuleToEstablishmentsApi: async (
    loyaltyRuleId: number,
    body: number[],
    forcedToCrush: boolean,
  ) => {
    try {
      if (forcedToCrush) {
        console.log('forcedToCrush', loyaltyRuleId,body,forcedToCrush);
        const response = await axiosInstance.put(
          BaseUrl +
            `/v1/affectRewardRuleToEstablishments?loyaltyRuleId=${loyaltyRuleId}&forcedToCrush=${forcedToCrush}`,
          body,
        );
        return response.data;
      } else {
        const response = await axiosInstance.put(
          BaseUrl +
            `/v1/affectRewardRuleToEstablishments?loyaltyRuleId=${loyaltyRuleId}`,
          body,
        );
        return response.data;
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default entrepriseApi;
