import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  setValidationRequestedFiscalEtablissement,
  unsavedChangesRegister,
  verifyTaxesInfosEtablissement,
} from '../../../store/actions/actions';
import tabs_list from '../../../data/tabsList';
import { validateFiscalInfoEtablissement } from './ValidateFiscalInfoEtablissement';
import { useUnsavedChanges } from './useUnsavedChanges';
import { serviceEtablissementModels } from '../../../models/services/module.etablissement';

interface FiscalFormState {
  siretError: boolean | null;
  siretErrorMsg: string;
  tvaError: boolean | null;
  tvaErrorMsg: string;
  nafError: boolean | undefined;
  nafErrorMsg: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  verifyData: () => void;
  newFormData: serviceEtablissementModels.EtablissementState;
}

export const useFiscalFormStateEtablissement = (): FiscalFormState => {
  const dispatch = useDispatch();
  const [siretError, setSiretError] = useState<boolean | null>(null);
  const [siretErrorMsg, setSiretErrorMsg] = useState<string>('');
  const [tvaError, setTvaError] = useState<boolean | null>(null);
  const [tvaErrorMsg, setTvaErrorMsg] = useState<string>('');
  const [nafError, setNafError] = useState<boolean | undefined>(false);
  const [nafErrorMsg, setNafErrorMsg] = useState<string>('');
  const { newFormData } = useUnsavedChanges();


  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
  
      dispatch(setValidationRequestedFiscalEtablissement(false));
      if (name === 'sireT_code') setSiretError(false);
      if (name === 'vaT_Intracommunity') setTvaError(false);
      if (name === 'naF_code_id') setNafError(false);

      const newPayload = {
        ...newFormData?.payload,
        fiscalInformation: {
          ...newFormData?.payload?.fiscalInformation,
          [name]: value,
        },
      };
      
      const array = [
        {
          key: tabs_list[1].name,
          value: { type: 'action', payload: { payload: newPayload } },
        },
      ];
      dispatch(unsavedChangesRegister(array));
    },
    [newFormData],
  );
  const verifyData = useCallback(() => {
    const errors = validateFiscalInfoEtablissement(
      newFormData?.payload?.fiscalInformation ?? {},
    );

    setSiretError(errors.siret_error);
    setSiretErrorMsg(errors.siret_error_msg);
    setTvaError(errors.tva_error);
    setTvaErrorMsg(errors.tva_error_msg);
    setNafError(errors.naf_error);
    setNafErrorMsg(errors.naf_error_msg);

    if (!errors.siret_error && !errors.tva_error && !errors.naf_error) {
      dispatch(setValidationRequestedFiscalEtablissement(true));
      dispatch(
        verifyTaxesInfosEtablissement(newFormData?.payload?.fiscalInformation),
      );
    }
  }, [newFormData]);

  
  return {
    siretError,
    siretErrorMsg,
    tvaError,
    tvaErrorMsg,
    nafError,
    nafErrorMsg,
    handleChange,
    verifyData,
    newFormData,
  };
};
