export const INIT_APP = 'INIT_APP';
//Auth
export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const DETECTED_ERROR = 'DETECTED_ERROR';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const FAILURE = 'FAILURE';
//export const LOGGED_USER = 'LOGGED_USER';
export const CANCEL_LOGIN = 'CANCEL_LOGIN';
export const RESET_LOGGED_IN = 'RESET_LOGGED_IN';
export const CLEAR_ERROR_MESSAGE = 'CLEAR_ERROR_MESSAGE';

// tabs
export const ADD_TAB = 'ADD_TAB';
export const OPEN_TAB = 'OPEN_TAB';
export const REMOVE_TAB = 'REMOVE_TAB';
export const SELECT_TAB = 'SELECT_TAB';
export const UPDATE_TABS = 'UPDATE_TABS';
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
export const SET_ACTIVE_TAB_CONTENT = 'SET_ACTIVE_TAB_CONTENT';
export const RESET_TABS = 'RESET_TABS';
// entreprise
export const ADD_ENTREPRISE = 'ADD_ENTREPRISE';
export const ADD_ENTREPRISE_GENERAL_INFO = 'ADD_ENTREPRISE_GENERAL_INFO';
export const ADD_ENTREPRISE_SUCCESS = 'ADD_ENTREPRISE_SUCCESS';
export const ADD_ENTREPRISE_FAILURE = 'ADD_ENTREPRISE_FAILURE';
export const EDIT_ENTREPRISE_GENERAL_INFO = 'EDIT_ENTREPRISE_GENERAL_INFO';
export const EDIT_ENTREPRISE_FINANCIAL_INFO = 'EDIT_ENTREPRISE_FINANCIAL_INFO';
export const REMOVE_ENTREPRISE = 'REMOVE_ENTREPRISE';
export const GET_ENTREPRISE = 'GET_ENTREPRISE';
export const GET_ALL_ENTREPRISE = 'GET_ALL_ENTREPRISE';
export const SET_ALL_ENTREPRISE = 'SET_ALL_ENTREPRISE';
export const SET_ALL_ENTREPRISE_TOTAL_PAGE_NUMBER =
  'SET_ALL_ENTREPRISE_TOTAL_PAGE_NUMBER';
export const SET_COMPANY_SHEET = 'SET_COMPANY_SHEET';
export const SET_COMPANY_INFOS = 'SET_COMPANY_INFOS';
export const GET_COMPANY_SHEET = 'GET_COMPANY_SHEET';
export const GET_COMPANY_INFOS = 'GET_COMPANY_INFOS';
export const SET_COMPANY_FINANCIAL_INFOS = 'SET_COMPANY_FINANCIAL_INFOS';
export const GET_COMPANY_FINANCIAL_INFOS = 'GET_COMPANY_FINANCIAL_INFOS';
export const EDIT_ENTREPRISE_SUCCESS = 'EDIT_ENTREPRISE_SUCCESS';
export const EDIT_ENTREPRISE_FAILURE = 'EDIT_ENTREPRISE_FAILURE';
export const RESET_EDIT_RESPONSE = 'RESET_EDIT_RESPONSE';
export const RESET_ADD_SUCCESS_STATE = 'RESET_ADD_SUCCESS_STATE';
export const ADD_ENTREPRISE_FORM_DATA = 'ADD_ENTREPRISE_FORM_DATA';
export const ADD_COMPANY_STEP = 'ADD_COMPANY_STEP';
export const RESET_ENTREPRISE_FORM_DATA = 'RESET_ENTREPRISE_FORM_DATA';
export const UNSAVED_CHANGES_DATA = 'UNSAVED_CHANGES_DATA';
/***************** form states actions */
export const ADD_COMPANY_VALIDATION_REQUESTED =
  'ADD_COMPANY_VALIDATION_REQUESTED';
export const ADD_COMPANY_VALIDATION_REQUESTED_STEP2 =
  'ADD_COMPANY_VALIDATION_REQUESTED_STEP2';
export const ADD_COMPANY_VALIDATION_REQUESTED_FISCAL =
  'ADD_COMPANY_VALIDATION_REQUESTED_FISCAL';
export const ADD_COMPANY_STEPS_DATA = 'ADD_COMPANY_STEPS_DATA';
export const EDIT_ENTREPRISE_FORM_DATA_FICHE =
  'EDIT_ENTREPRISE_FORM_DATA_FICHE';
export const EDIT_ENTREPRISE_FORM_VALIDATION_REQUESTED =
  'EDIT_ENTREPRISE_FORM_VALIDATION_REQUESTED';
export const EDIT_ENTREPRISE_FORM_SET_DIRTY = 'EDIT_ENTREPRISE_FORM_SET_DIRTY';
export const EDIT_ENTREPRISE_FORM_DATA_FINANCIAL =
  'EDIT_ENTREPRISE_FORM_DATA_FINANCIAL';
export const EDIT_ENTREPRISE_FORM_VALIDATION_REQUESTED_FINANCIAL =
  'EDIT_ENTREPRISE_FORM_VALIDATION_REQUESTED_FINANCIAL';
export const GET_ALL_ENTREPRISE_ESTABLISHEMENTS =
  'GET_ALL_ENTREPRISE_ESTABLISHEMENTS';
export const GET_ALL_ENTREPRISE_ESTABLISHEMENTS_SUCCESS =
  'GET_ALL_ENTREPRISE_ESTABLISHEMENTS_SUCCESS';
/************************ */
// etablissement
export const ADD_ETABLISSEMENT = 'ADD_ETABLISSEMENT';
export const ADD_ETABLISSEMENT_GENERAL_INFO = 'ADD_ETABLISSEMENT_GENERAL_INFO';
export const ADD_ETABLISSEMENT_SUCCESS = 'ADD_ETABLISSEMENT_SUCCESS';
export const ADD_ETABLISSEMENT_FAILURE = 'ADD_ETABLISSEMENT_FAILURE';
export const EDIT_ETABLISSEMENT_GENERAL_INFO =
  'EDIT_ETABLISSEMENT_GENERAL_INFO';
export const EDIT_ETABLISSEMENT_FINANCIAL_INFO =
  'EDIT_ETABLISSEMENT_FINANCIAL_INFO';
export const REMOVE_ETABLISSEMENT = 'REMOVE_ETABLISSEMENT';
export const GET_ETABLISSEMENT = 'GET_ETABLISSEMENT';
export const GET_ALL_ETABLISSEMENT = 'GET_ALL_ETABLISSEMENT';
export const SET_ALL_ETABLISSEMENT = 'SET_ALL_ETABLISSEMENT';
export const SET_ALL_ETABLISSEMENT_TOTAL_PAGE_NUMBER =
  'SET_ALL_ETABLISSEMENT_TOTAL_PAGE_NUMBER';
export const SET_ETABLISSEMENT_SHEET = 'SET_ETABLISSEMENT_SHEET';
export const SET_ETABLISSEMENT_INFOS = 'SET_ETABLISSEMENT_INFOS';
export const GET_ETABLISSEMENT_SHEET = 'GET_ETABLISSEMENT_SHEET';
export const GET_ETABLISSEMENT_INFOS = 'GET_ETABLISSEMENT_INFOS';
export const SET_ETABLISSEMENT_FINANCIAL_INFOS =
  'SET_ETABLISSEMENT_FINANCIAL_INFOS';
export const GET_ETABLISSEMENT_FINANCIAL_INFOS =
  'GET_ETABLISSEMENT_FINANCIAL_INFOS';
export const EDIT_ETABLISSEMENT_SUCCESS = 'EDIT_ETABLISSEMENT_SUCCESS';
export const EDIT_ETABLISSEMENT_FAILURE = 'EDIT_ETABLISSEMENT_FAILURE';
export const RESET_EDIT_ETABLISSEMENT_RESPONSE =
  'RESET_EDIT_ETABLISSEMENT_RESPONSE';
export const RESET_ADD_ETABLISSEMENT_SUCCESS_STATE =
  'RESET_ADD_ETABLISSEMENT_SUCCESS_STATE';
export const SELECT_COMPANY_EXISTING_CREATE_ETABLISSEMENT =
  'SELECT_COMPANY_EXISTING_CREATE_ETABLISSEMENT';
export const RESET_COMPANY_EXISTING_CREATE_ETABLISSEMENT =
  'RESET_COMPANY_EXISTING_CREATE_ETABLISSEMENT';
export const ETABLISSEMENT_STATE = 'ETABLISSEMENT_STATE';
/************************* */
export const ADD_ETABLISSEMENT_FORM_DATA = 'ADD_ETABLISSEMENT_FORM_DATA';
export const ADD_ETABLISSEMENT_STEP = 'ADD_ETABLISSEMENT_STEP';
export const RESET_ETABLISSEMENT_FORM_DATA = 'RESET_ETABLISSEMENT_FORM_DATA';
export const UNSAVED_CHANGES_DATA_ETABLISSEMENT =
  'UNSAVED_CHANGES_DATA_ETABLISSEMENT';
/***************** form states actions */
export const ADD_ETABLISSEMENT_VALIDATION_REQUESTED =
  'ADD_ETABLISSEMENT_VALIDATION_REQUESTED';
export const ADD_ETABLISSEMENT_VALIDATION_REQUESTED_STEP2 =
  'ADD_ETABLISSEMENT_VALIDATION_REQUESTED_STEP2';
export const ADD_ETABLISSEMENT_VALIDATION_REQUESTED_FISCAL =
  'ADD_ETABLISSEMENT_VALIDATION_REQUESTED_FISCAL';
export const ADD_ETABLISSEMENT_STEPS_DATA = 'ADD_ETABLISSEMENT_STEPS_DATA';
/************************ */
// // taxes infos_ETABLISSEMENT
export const VERIFY_TAXES_INFOS_ETABLISSEMENT =
  'VERIFY_TAXES_INFOS_ETABLISSEMENT';
export const VERIFY_TAXES_INOFS_RESPONSE_ETABLISSEMENT =
  'VERIFY_TAXES_INOFS_RESPONSE_ETABLISSEMENT';
export const RESET_VERIF_RESPONSE_ETABLISSEMENT =
  'RESET_VERIF_RESPONSE_ETABLISSEMENT';
// taxes infos
export const VERIFY_TAXES_INFOS = 'VERIFY_TAXES_INFOS';
export const VERIFY_TAXES_INOFS_RESPONSE = 'VERIFY_TAXES_INOFS_RESPONSE';
export const RESET_VERIF_RESPONSE = 'RESET_VERIF_RESPONSE';
export const GET_CODES_NAF = 'GET_CODES_NAF';
export const GET_CODES_NAF_SUCCESS = 'GET_CODES_NAF_SUCCESS';
// redevance
export const ADD_REDEVANCE = 'ADD_REDEVANCE';
export const EDIT_REDEVANCE = 'EDIT_REDEVANCE';
export const GET_REDEVANCE = 'GET_REDEVANCE';
export const REMOVE_REDEVANCE = 'REMOVE_REDEVANCE';
// formes juridiques
export const GET_FORMES_JURIDIQUES = 'GET_FORMES_JURIDIQUES';
export const SET_FORMES_JURIDIQUES = 'SET_FORMES_JURIDIQUES';
// bank info
export const GET_BANK_ACCOUNT_TYPES = 'GET_BANK_ACCOUNT_TYPES';
export const GET_BANK_ACCOUNT_TYPES_SUCCESS = 'GET_BANK_ACCOUNT_TYPES_SUCCESS';
// roles
export const GET_ROLES_LIST = 'GET_ROLES_LIST';
export const GET_ETABLISSEMENT_ROLES_LIST = 'GET_ETABLISSEMENT_ROLES_LIST';
export const RESET_ROLES_LIST = 'RESET_ROLES_LIST';
export const GET_ROLES_LIST_SUCCESS = 'GET_ROLES_LIST_SUCCESS';
export const ADD_ROLE = 'ADD_ROLE';
export const ADD_ROLE_SUCCESS = 'ADD_ROLE_SUCCESS';
export const RESET_ADD_ROLE_SUCCESS = 'RESET_ADD_ROLE_SUCCESS';
export const ADD_ROLE_FAILURE = 'ADD_ROLE_FAILURE';
export const DELETE_ROLE = 'DELETE_ROLE';
export const SET_ROLES_NUMBER = 'SET_ROLES_NUMBER';
export const SET_ROLES_TOTAL_PAGE_NUMBER = 'SET_ROLES_TOTAL_PAGE_NUMBER';
export const RESET_ADD_RESPONSE = 'RESET_ADD_RESPONSE';
export const ADD_NEW_ROLE = 'ADD_NEW_ROLE';
export const GET_ROLES_ID = 'GET_ROLES_ID';
export const STORE_ID = 'STORE_ID';
export const START_GET_ROLES_ID = 'START_GET_ROLES_ID';
export const UPDATE_ROLE_REQUEST = 'UPDATE_ROLE_REQUEST';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_FAILURE = 'UPDATE_ROLE_FAILURE';
export const DELETE_ROLE_REQUEST = 'DELETE_ROLE_REQUEST';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_FAILURE = 'DELETE_ROLE_FAILURE';
// users
export const GET_COMPANY_USERS_LIST = 'GET_COMPANY_USERS_LIST';
export const GET_ETABLISSEMENT_USERS_LIST = 'GET_ETABLISSEMENT_USERS_LIST';
export const RESET_USERS_LIST = 'RESET_USERS_LIST';
export const GET_USERS_LIST = 'GET_USERS_LIST';
export const GET_USERS_LIST_SUCCESS = 'GET_USERS_LIST_SUCCESS';
export const ADD_USER = 'ADD_USER';
export const USED_EMAIL_ACTION = 'USED_EMAIL_ACTION';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const RESET_ADD_USER_SUCCESS = 'RESET_ADD_USER_SUCCESS';
export const ADD_USER_FAILURE = 'ADD_USER_FAILURE';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const SET_USERS_NUMBER = 'SET_USERS_NUMBER';
export const SET_USERS_TOTAL_PAGE_NUMBER = 'SET_USERS_TOTAL_PAGE_NUMBER';
export const RESET_ADD_USER_RESPONSE = 'RESET_ADD_USER_RESPONSE';
export const SET_USER_FORM_DATA = 'SET_USER_FORM_DATA';
export const RESET_USER_FORM_DATA = 'RESET_USER_FORM_DATA';
export const SET_USER_FORM_VALIDATION_REQUESTED =
  'SET_USER_FORM_VALIDATION_REQUESTED';
export const GET_USER_BY_ID = 'GET_USER_BY_ID';
export const GET_USER_BY_ID_SUCCESS = 'GET_USER_BY_ID_SUCCESS';
export const RESET_USER_FORM_DATA_DETAILS = 'RESET_USER_FORM_DATA_DETAILS';
export const SET_USER_FORM_DATA_DETAILS = 'SET_USER_FORM_DATA_DETAILS';
export const SET_USER_FORM_VALIDATION_REQUESTED_DETAILS =
  'SET_USER_FORM_VALIDATION_REQUESTED_DETAILS';
export const EDIT_USER = 'EDIT_USER';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const RESET_EDIT_USER_SUCCESS = 'RESET_EDIT_USER_SUCCESS';
export const CHECK_USER_EMAIL = 'CHECK_USE_EMAIL';
export const CHECK_USER_EMAIL_SUCCESS = 'CHECK_USER_EMAIL_SUCCESS';
export const RESET_CHECK_USER_EMAIL = 'RESET_CHECK_USER_EMAIL';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const EMAIL_INVALID = 'EMAIL_INVALID';
export const RESET_EMAIL_INVALID = 'RESET_EMAIL_INVALID';
export const SET_ACTIVE_EMAIL_ERROR = 'SET_ACTIVE_EMAIL_ERROR';
export const RESET_ACTIVE_EMAIL_ERROR = 'RESET_ACTIVE_EMAIL_ERROR';
//CombineEtablissement

export const COMBINE_ETABLISSEMENT = 'COMBINE_ETABLISSEMENT';

//TypeMachine
export const FETCH_COMPANY_MACHINE_TYPES_REQUEST =
  'FETCH_COMPANY_MACHINE_TYPES_REQUEST';
export const FETCH_COMPANY_MACHINE_TYPES_SUCCESS =
  'FETCH_COMPANY_MACHINE_TYPES_SUCCESS';
export const FETCH_COMPANY_MACHINE_TYPES_FAILURE =
  'FETCH_COMPANY_MACHINE_TYPES_FAILURE';
//MachineSuperType
export const FETCH_MACHINE_SUPER_TYPES_REQUEST =
  'FETCH_MACHINE_SUPER_TYPES_REQUEST';
export const FETCH_MACHINE_SUPER_TYPES_SUCCESS =
  'FETCH_MACHINE_SUPER_TYPES_SUCCESS';
export const FETCH_MACHINE_SUPER_TYPES_FAILURE =
  'FETCH_MACHINE_SUPER_TYPES_FAILURE';
// create Campany machine type
export const ADD_COMPANY_MACHINE_TYPE_REQUEST =
  'ADD_COMPANY_MACHINE_TYPE_REQUEST';
export const ADD_COMPANY_MACHINE_TYPE_SUCCESS =
  'ADD_COMPANY_MACHINE_TYPE_SUCCESS';
export const ADD_COMPANY_MACHINE_TYPE_FAILURE =
  'ADD_COMPANY_MACHINE_TYPE_FAILURE';
// update Campany machine type
export const UPDATE_COMPANY_MACHINE_TYPE_REQUEST =
  'UPDATE_COMPANY_MACHINE_TYPE_REQUEST';
export const UPDATE_COMPANY_MACHINE_TYPE_SUCCESS =
  'UPDATE_COMPANY_MACHINE_TYPE_SUCCESS';
export const UPDATE_COMPANY_MACHINE_TYPE_FAILURE =
  'UPDATE_COMPANY_MACHINE_TYPE_FAILURE';

// async etablissement machine

export const SYNC_ESTABLISHMENT_MACHINES_REQUEST =
  'SYNC_ESTABLISHMENT_MACHINES_REQUEST';
export const SYNC_ESTABLISHMENT_MACHINES_SUCCESS =
  'SYNC_ESTABLISHMENT_MACHINES_SUCCESS';
export const SYNC_ESTABLISHMENT_MACHINES_FAILURE =
  'SYNC_ESTABLISHMENT_MACHINES_FAILURE';

//list machine
export const ESTABLISHMENT_List_MACHINES_REQUEST =
  'ESTABLISHMENT_List_MACHINES_REQUEST';
export const ESTABLISHMENT_List_MACHINES_SUCCESS =
  'ESTABLISHMENT_List_MACHINES_SUCCESS';
export const ESTABLISHMENT_List_MACHINES_FAILURE =
  'ESTABLISHMENT_List_MACHINES_FAILURE';

//list machine super Grouped
export const ESTABLISHMENT_List_MACHINES_SUPER_REQUEST =
  'ESTABLISHMENT_List_MACHINES_SUPER_REQUEST';
export const ESTABLISHMENT_List_MACHINES_SUPER_SUCCESS =
  'ESTABLISHMENT_List_MACHINES_SUPER_SUCCESS';
export const ESTABLISHMENT_List_MACHINES_SUPER_FAILURE =
  'ESTABLISHMENT_List_MACHINES_SUPER_FAILURE';
//affectMachineToTypes
export const AFFECT_MACHINE_TO_TYPES_REQUEST =
  'AFFECT_MACHINE_TO_TYPES_REQUEST';
export const AFFECT_MACHINE_TO_TYPES_SUCCESS =
  'AFFECT_MACHINE_TO_TYPES_SUCCESS';
export const AFFECT_MACHINE_TO_TYPES_FAILURE =
  'AFFECT_MACHINE_TO_TYPES_FAILURE';
//getAllListEtablisment filtered
export const GET_LIST_ESTABLISSEMENTS_REQUEST_ALL =
  'GET_LIST_ESTABLISSEMENTS_REQUEST_ALL';
export const GET_LIST_ESTABLISSEMENTS_SUCCESS_ALL =
  'GET_LIST_ESTABLISSEMENTS_SUCCESS_ALL';
export const GET_LIST_ESTABLISSEMENTS_FAILURE_ALL =
  'GET_LIST_ESTABLISSEMENTS_FAILURE_ALL';

/******************************* */
//get GelListIntervallesAbonnment

export const GET_LIST_INTERVALLES_ABONNEMENT_REQUEST =
  'GET_LIST_INTERVALLES_ABONNEMENT_REQUEST';
export const GET_LIST_INTERVALLES_ABONNEMENT_SUCCESS =
  'GET_LIST_INTERVALLES_ABONNEMENT_SUCCESS';
export const GET_LIST_INTERVALLES_ABONNEMENT_FAILURE =
  'GET_LIST_INTERVALLES_ABONNEMENT_FAILURE';

//get GetAbonnementById
export const GET_ABONNEMENT_BY_ID_REQUEST = 'GET_ABONNEMENT_BY_ID_REQUEST';
export const GET_ABONNEMENT_BY_ID_SUCCESS = 'GET_ABONNEMENT_BY_ID_SUCCESS';
export const GET_ABONNEMENT_BY_ID_FAILURE = 'GET_ABONNEMENT_BY_ID_FAILURE';
export const RESET_ABONNEMENT_STATE = 'RESET_ABONNEMENT_STATE';

//create Abonnement
export const CREATE_ABONNEMENT_REQUEST = 'CREATE_ABONNEMENT_REQUEST';
export const CREATE_ABONNEMENT_SUCCESS = 'CREATE_ABONNEMENT_SUCCESS';
export const CREATE_ABONNEMENT_FAILURE = 'CREATE_ABONNEMENT_FAILURE';

//allAbonnement
export const FETCH_ABONNEMENT_DATA_REQUEST = 'FETCH_ABONNEMENT_DATA_REQUEST';
export const FETCH_ABONNEMENT_DATA_SUCCESS = 'FETCH_ABONNEMENT_DATA_SUCCESS';
export const FETCH_ABONNEMENT_DATA_FAILURE = 'FETCH_ABONNEMENT_DATA_FAILURE';

// update Abonnement

export const UPDATE_ABONNEMENT_REQUEST = 'UPDATE_ABONNEMENT_REQUEST';
export const UPDATE_ABONNEMENT_SUCCESS = 'UPDATE_ABONNEMENT_SUCCESS';
export const UPDATE_ABONNEMENT_FAILURE = 'UPDATE_ABONNEMENT_FAILURE';

//dropdawnListofCampany

export const FETCH_DROPDOWN_LIST_OF_CAMPANY_REQUEST =
  'FETCH_DROPDOWN_LIST_OF_CAMPANY_REQUEST';
export const FETCH_DROPDOWN_LIST_OF_CAMPANY_SUCCESS =
  'FETCH_DROPDOWN_LIST_OF_CAMPANY_SUCCESS';
export const FETCH_DROPDOWN_LIST_OF_CAMPANY_FAILURE =
  'FETCH_DROPDOWN_LIST_OF_CAMPANY_FAILURE';
///********************** */
// get List Statuts Establishements

export const GET_LIST_STATUTS_ESTABLISSEMENTS_REQUEST =
  'GET_LIST_STATUTS_ESTABLISSEMENTS_REQUEST';
export const GET_LIST_STATUTS_ESTABLISSEMENTS_SUCCESS =
  'GET_LIST_STATUTS_ESTABLISSEMENTS_SUCCESS';
export const GET_LIST_STATUTS_ESTABLISSEMENTS_FAILURE =
  'GET_LIST_STATUTS_ESTABLISSEMENTS_FAILURE';

/********************* */
//update user secion

export const UPDATE_USER_SECTION_REQUEST = 'UPDATE_USER_SECTION_REQUEST';
export const UPDATE_USER_SECTION_SUCCESS = 'UPDATE_USER_SECTION_SUCCESS';
export const UPDATE_USER_SECTION_FAILURE = 'UPDATE_USER_SECTION_FAILURE';
//update user Password secion

export const UPDATE_USER_PASSWORD_SECTION_REQUEST =
  'UPDATE_USER_PASSWORD_SECTION_REQUEST';
export const UPDATE_USER_PASSWORD_SECTION_SUCCESS =
  'UPDATE_USER_PASSWORD_SECTION_SUCCESS';
export const UPDATE_USER_PASSWORD_SECTION_FAILURE =
  'UPDATE_USER_PASSWORD_SECTION_FAILURE';

// Update abonnment status
export const UPDATE_ABONNEMENT_STATUS_REQUEST =
  'UPDATE_ABONNEMENT_STATUS_REQUEST';
export const UPDATE_ABONNEMENT_STATUS_SUCCESS =
  'UPDATE_ABONNEMENT_STATUS_SUCCESS';
export const UPDATE_ABONNEMENT_STATUS_FAILURE =
  'UPDATE_ABONNEMENT_STATUS_FAILURE';

// Get List Facture

export const GET_LIST_FACTURE_REQUEST = 'GET_LIST_FACTURE_REQUEST';
export const GET_LIST_FACTURE_SUCCESS = 'GET_LIST_FACTURE_SUCCESS';
export const GET_LIST_FACTURE_FAILURE = 'GET_LIST_FACTURE_FAILURE';

// Get Facture By Id

export const GET_FACTURE_BY_ID_REQUEST = 'GET_FACTURE_BY_ID_REQUEST';
export const GET_FACTURE_BY_ID_SUCCESS = 'GET_FACTURE_BY_ID_SUCCESS';
export const GET_FACTURE_BY_ID_FAILURE = 'GET_FACTURE_BY_ID_FAILURE';

//get List Abonnements Client

export const GET_LIST_ABONNEMENTS_CLIENT_REQUEST =
  'GET_LIST_ABONNEMENTS_CLIENT_REQUEST';
export const GET_LIST_ABONNEMENTS_CLIENT_SUCCESS =
  'GET_LIST_ABONNEMENTS_CLIENT_SUCCESS';
export const GET_LIST_ABONNEMENTS_CLIENT_FAILURE =
  'GET_LIST_ABONNEMENTS_CLIENT_FAILURE';

// activate Deactivate Establishement

export const ACTIVATE_DEACTIVATE_ESTABLISSEMENT_REQUEST =
  'ACTIVATE_DEACTIVATE_ESTABLISSEMENT_REQUEST';
export const ACTIVATE_DEACTIVATE_ESTABLISSEMENT_SUCCESS =
  'ACTIVATE_DEACTIVATE_ESTABLISSEMENT_SUCCESS';
export const ACTIVATE_DEACTIVATE_ESTABLISSEMENT_FAILURE =
  'ACTIVATE_DEACTIVATE_ESTABLISSEMENT_FAILURE';

// activate Deactivate Campany

export const ACTIVATE_DEACTIVATE_CAMPANY_REQUEST =
  'ACTIVATE_DEACTIVATE_CAMPANY_REQUEST';
export const ACTIVATE_DEACTIVATE_CAMPANY_SUCCESS =
  'ACTIVATE_DEACTIVATE_CAMPANY_SUCCESS';
export const ACTIVATE_DEACTIVATE_CAMPANY_FAILURE =
  'ACTIVATE_DEACTIVATE_CAMPANY_FAILURE';

// fotget password link

export const FORGET_PASSWORD_LINK_REQUEST = 'FORGET_PASSWORD_LINK_REQUEST';
export const FORGET_PASSWORD_LINK_SUCCESS = 'FORGET_PASSWORD_LINK_SUCCESS';
export const FORGET_PASSWORD_LINK_FAILURE = 'FORGET_PASSWORD_LINK_FAILURE';

// reset password

export const RESET_PASSWORD_LINK_REQUEST = 'RESET_PASSWORD_LINK_REQUEST';
export const RESET_PASSWORD_LINK_SUCCESS = 'RESET_PASSWORD_LINK_SUCCESS';
export const RESET_PASSWORD_LINK_FAILURE = 'RESET_PASSWORD_LINK_FAILURE';

// toggle toast
export const TOGGLE_TOAST = 'TOGGLE_TOAST';

//clear error
export const CLEAR_ERROR = 'CLEAR_ERROR';

//chart_grafique

export const SUBMIT_COMPANY_DATA_REQUEST = 'SUBMIT_COMPANY_DATA_REQUEST';
export const SUBMIT_COMPANY_DATA_SUCCESS = 'SUBMIT_COMPANY_DATA_SUCCESS';
export const SUBMIT_COMPANY_DATA_FAILURE = 'SUBMIT_COMPANY_DATA_FAILURE';

//Get chart_grafique
export const GET_COMPANY_DATA_REQUEST = 'GET_COMPANY_DATA_REQUEST';
export const GET_COMPANY_DATA_SUCCESS = 'GET_COMPANY_DATA_SUCCESS';
export const GET_COMPANY_DATA_FAILURE = 'GET_COMPANY_DATA_FAILURE';

//order_Details
export const FETCH_ONLINE_ORDER_DETAILS_REQUEST = 'FETCH_ONLINE_ORDER_DETAILS_REQUEST';
export const FETCH_ONLINE_ORDER_DETAILS_SUCCESS = 'FETCH_ONLINE_ORDER_DETAILS_SUCCESS';
export const FETCH_ONLINE_ORDER_DETAILS_FAILURE = 'FETCH_ONLINE_ORDER_DETAILS_FAILURE';

//create order

export const CREATE_ONLINE_ORDER_REQUEST = 'CREATE_ONLINE_ORDER_REQUEST';
export const CREATE_ONLINE_ORDER_SUCCESS = 'CREATE_ONLINE_ORDER_SUCCESS';
export const CREATE_ONLINE_ORDER_FAILURE = 'CREATE_ONLINE_ORDER_FAILURE';

// clear reducer 
export const CLEAR_REDUCER = 'CLEAR_REDUCER';

//create acounts
export const CREATE_ACCOUNT_REQUEST = 'CREATE_ACCOUNT_REQUEST';
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS';
export const CREATE_ACCOUNT_FAILURE = 'CREATE_ACCOUNT_FAILURE';

//create acounts
export const UPDATE_ACCOUNT_REQUEST = 'UPDATE_ACCOUNT_REQUEST';
export const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS';
export const UPDATE_ACCOUNT_FAILURE = 'UPDATE_ACCOUNT_FAILURE';
// company marketing
export const FETCH_COMPANIES_REQUEST = 'FETCH_COMPANIES_REQUEST';
export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS';
export const FETCH_COMPANIES_FAILURE = 'FETCH_COMPANIES_FAILURE';
//getIsLoyaltyCentralizedCompany
export const FETCH_LOYALTY_STATUS_REQUEST = 'FETCH_LOYALTY_STATUS_REQUEST';
export const FETCH_LOYALTY_STATUS_SUCCESS = 'FETCH_LOYALTY_STATUS_SUCCESS';
export const FETCH_LOYALTY_STATUS_FAILURE = 'FETCH_LOYALTY_STATUS_FAILURE';

export const SET_INPUT_VALUE = 'SET_INPUT_VALUE';
export const CLEAR_SET_INPUT_VALUE = 'CLEAR_SET_INPUT_VALUE';

// actionTypes.ts
export const ACTIVATE_DEACTIVATE_LOYALTY_REQUEST = 'ACTIVATE_DEACTIVATE_LOYALTY_REQUEST';
export const ACTIVATE_DEACTIVATE_LOYALTY_SUCCESS = 'ACTIVATE_DEACTIVATE_LOYALTY_SUCCESS';
export const ACTIVATE_DEACTIVATE_LOYALTY_FAILURE = 'ACTIVATE_DEACTIVATE_LOYALTY_FAILURE';

// actionTypes.ts
export const CREATE_LOYALTY_RULE_REQUEST = 'CREATE_LOYALTY_RULE_REQUEST';
export const CREATE_LOYALTY_RULE_SUCCESS = 'CREATE_LOYALTY_RULE_SUCCESS';
export const CREATE_LOYALTY_RULE_FAILURE = 'CREATE_LOYALTY_RULE_FAILURE';
// actionTypes.ts
export const GET_LIST_LOYALTY_RULES_REQUEST = 'GET_LIST_LOYALTY_RULES_REQUEST';
export const GET_LIST_LOYALTY_RULES_SUCCESS = 'GET_LIST_LOYALTY_RULES_SUCCESS';
export const GET_LIST_LOYALTY_RULES_FAILURE = 'GET_LIST_LOYALTY_RULES_FAILURE';

// actionTypes.ts
export const DELETE_LOYALTY_RULE_REQUEST = 'DELETE_LOYALTY_RULE_REQUEST';
export const DELETE_LOYALTY_RULE_SUCCESS = 'DELETE_LOYALTY_RULE_SUCCESS';
export const DELETE_LOYALTY_RULE_FAILURE = 'DELETE_LOYALTY_RULE_FAILURE';

// actionTypes.ts
export const UPDATE_LOYALTY_RULE_REQUEST = 'UPDATE_LOYALTY_RULE_REQUEST';
export const UPDATE_LOYALTY_RULE_SUCCESS = 'UPDATE_LOYALTY_RULE_SUCCESS';
export const UPDATE_LOYALTY_RULE_FAILURE = 'UPDATE_LOYALTY_RULE_FAILURE';

// actionTypes.js
export const FETCH_ESTABLISHMENTS_REQUEST = 'FETCH_ESTABLISHMENTS_REQUEST';
export const FETCH_ESTABLISHMENTS_SUCCESS = 'FETCH_ESTABLISHMENTS_SUCCESS';
export const FETCH_ESTABLISHMENTS_FAILURE = 'FETCH_ESTABLISHMENTS_FAILURE';

// actionTypes.js
export const AFFECT_LOYALTY_RULE_TO_ESTABLISHMENTS_REQUEST = 'AFFECT_LOYALTY_RULE_TO_ESTABLISHMENTS_REQUEST';
export const AFFECT_LOYALTY_RULE_TO_ESTABLISHMENTS_SUCCESS = 'AFFECT_LOYALTY_RULE_TO_ESTABLISHMENTS_SUCCESS';
export const AFFECT_LOYALTY_RULE_TO_ESTABLISHMENTS_FAILURE = 'AFFECT_LOYALTY_RULE_TO_ESTABLISHMENTS_FAILURE';

//clear the error
export const SET_CLEAR_ERROR = 'SET_CLEAR_ERROR';

export const ACTION_MULTIPLE_ARTICLES_REQUEST = 'ACTION_MULTIPLE_ARTICLES_REQUEST';
export const ACTION_MULTIPLE_ARTICLES_SUCCESS = 'ACTION_MULTIPLE_ARTICLES_SUCCESS';
export const ACTION_MULTIPLE_ARTICLES_FAILURE = 'ACTION_MULTIPLE_ARTICLES_FAILURE';


export const FETCH_ARTICLES_REQUEST = 'FETCH_ARTICLES_REQUEST';
export const FETCH_ARTICLES_SUCCESS = 'FETCH_ARTICLES_SUCCESS';
export const FETCH_ARTICLES_FAILURE = 'FETCH_ARTICLES_FAILURE';


export const CREATE_REWARD_RULE_REQUEST = 'CREATE_REWARD_RULE_REQUEST';
export const CREATE_REWARD_RULE_SUCCESS = 'CREATE_REWARD_RULE_SUCCESS';
export const CREATE_REWARD_RULE_FAILURE = 'CREATE_REWARD_RULE_FAILURE';

export const GET_REWARD_RULE_REQUEST = 'GET_REWARD_RULE_REQUEST';
export const GET_REWARD_RULE_SUCCESS = 'GET_REWARD_RULE_SUCCESS';
export const GET_REWARD_RULE_FAILURE = 'GET_REWARD_RULE_FAILURE';

export const UPDATE_REWARD_RULE_REQUEST_ACTIVATE_DISACTIVATE_REQUEST = 'UPDATE_REWARD_RULE_REQUEST_ACTIVATE_DISACTIVATE_REQUEST';
export const UPDATE_REWARD_RULE_REQUEST_ACTIVATE_DISACTIVATE_SUCCESS = 'UPDATE_REWARD_RULE_REQUEST_ACTIVATE_DISACTIVATE_SUCCESS';
export const UPDATE_REWARD_RULE_REQUEST_ACTIVATE_DISACTIVATE_FAILURE = 'UPDATE_REWARD_RULE_REQUEST_ACTIVATE_DISACTIVATE_FAILURE';

export const AFFECT_REWORD_RULE_TO_ESTABLISHMENTS_REQUEST = 'AFFECT_REWORD_RULE_TO_ESTABLISHMENTS_REQUEST';
export const AFFECT_REWORD_RULE_TO_ESTABLISHMENTS_SUCCESS = 'AFFECT_REWORD_RULE_TO_ESTABLISHMENTS_SUCCESS';
export const AFFECT_REWORD_RULE_TO_ESTABLISHMENTS_FAILURE = 'AFFECT_REWORD_RULE_TO_ESTABLISHMENTS_FAILURE';