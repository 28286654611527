import { AxiosResponse } from 'axios';
import { catalogueApi } from '../../../services/apiHandlers/catalogueApi';
import {
  MenuPayload,
  menuAction,
} from '../../../models/services/module.catalogue';
import { NotificationErrorProvider } from '../../../utils/Notificationprovider';
import { put } from 'redux-saga/effects';
import {
  FAILDGetMenuEtatDeStock,
  fetchActionMenusFailure,
  fetchActionMenusSuccess,
  SUCCESSGetMenuEtatDeStock,
} from '../../actions/catalogues/actions';
import toast from 'react-hot-toast';
export function* startGetAllMenu(action: menuAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.getAllMenusList();
    if (response.data) {
      action._ && action._(response.data?.data?.result ?? []);
    }
  } catch (error: any) {
    NotificationErrorProvider(error);
  }
}
export function* startGetAllMenuStock(action: menuAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.getAllMenusListStock({
      IdEstablishment: action?.IdEstablishment as number,
      EtatStock: action?.EtatStock as number,
      nom: (action?.nom as string) ?? undefined,
    });
    if (response.data) {
      action._ && action._(response.data?.data?.result ?? []);
      yield put(SUCCESSGetMenuEtatDeStock(response.data?.data?.result));
    }
  } catch (error: any) {
    NotificationErrorProvider(error);
    yield put(FAILDGetMenuEtatDeStock(error));
  }
}
export function* startPostMenu(action: menuAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.postMenu(
      action.menu as MenuPayload,
    );
    if (response.data) {
      action._ && action._();
    }
  } catch (error) {
    console.log('ddddddd');
    NotificationErrorProvider(error);
  }
}
export function* startGetMenubyId(action: menuAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.getMenuById(
      action.id ?? '',
    );
    if (response.data) {
      action._ && action._(response.data?.data?.result);
    }
  } catch (error: any) {
    NotificationErrorProvider(error);
  }
}
export function* startDeleteMenubyId(action: menuAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.deleteMenuById(
      action.id ?? '',
    );
    if (response.data) {
      action._ && action._();
    }
  } catch (error: any) {
    NotificationErrorProvider(error);
  }
}
const messages: Record<string, string> = {
  '0': 'Suppression effectuée avec succès',
  '1': 'Activation effectuée avec succès',
  '2': 'Désactivation effectuée avec succès',
};
export function* actionMultipleMenuReducerSaga(action: any) {
  try {
    const { IdEntreprise, ActionMenus, data, _ } = action.payload;
    const response: AxiosResponse =
      yield catalogueApi.actionMultipleMenuReducer(
        IdEntreprise,
        ActionMenus,
        data,
      );
    if (response.data) {
      yield put(fetchActionMenusSuccess(response?.data?.data?.result));
      if (messages[ActionMenus]) {
        toast.success(messages[ActionMenus]);
      }
      _ && _();
    }
  } catch (error: any) {
    fetchActionMenusFailure(error);
  }
}
