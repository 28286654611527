import * as actionTypes from '../../actions/catalogues/actionTypes';

const initialState = {
  searchArrayResults: [],
  searchArrayResultsCategories: [],
  searchArrayResultsMenu: [],
};

const searchArrayReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_SEARCH_RESULTS:
      return { ...state, searchArrayResults: action.payload };
    case actionTypes.SET_SEARCH_RESULTS_CATEGORIES:
      return { ...state, searchArrayResultsCategories: action.payload };
    case actionTypes.SET_SEARCH_RESULTS_MENU:
      return { ...state, searchArrayResultsMenu: action.payload };
    default:
      return state;
  }
};

export default searchArrayReducer;
