import * as actionTypes from '../../actions/catalogues/actionTypes';
const initialState = {
  loading: false,
  data: null,
  error: null,
};

const actionCategoriesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.FETCH_ACTION_CATEGORIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FETCH_ACTION_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionTypes.UPDATE_SUCCESS:
      return { ...state, loading: false, data: null };
    case actionTypes.FETCH_ACTION_CATEGORIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default actionCategoriesReducer;
