import * as actionTypes from '../actions/actionTypes';

const initialState = {
  loading: false,
  establishmentEffected: null,
  error: null,
};

const establishmentEffected = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.AFFECT_LOYALTY_RULE_TO_ESTABLISHMENTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.AFFECT_LOYALTY_RULE_TO_ESTABLISHMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        establishmentEffected: action.payload,
        error: null,
      };
    case actionTypes.SET_CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    case actionTypes.AFFECT_REWORD_RULE_TO_ESTABLISHMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default establishmentEffected;
