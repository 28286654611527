import React from 'react';
import { ModalProps } from '../../../models/services/module.modals';
import ModalUploadFileController from './ModalUploadFileController';
import { AlertModalController } from './AlertModalController';
import ItemModalController from './ItemModalController';
export default function ModalController(props: ModalProps) {
  const { version } = props;
  let modalContentBody;
  let modalContentFooter;
    switch (version) {
    case 'Item':
      modalContentBody = ItemModalController(props).modalContentBody;
      modalContentFooter = ItemModalController(props).modalContentFooter;
      break;
    case 'Alert':
      modalContentBody = AlertModalController(props).modalContentBody;
      modalContentFooter = AlertModalController(props).modalContentFooter;
      break;
    case 'uploadFile':
      modalContentFooter = <></>;
      modalContentBody = ModalUploadFileController(props).modalContentBody;
      break;
  }
  return { modalContentBody,modalContentFooter };
}
