import React, { ChangeEvent, useState } from 'react';
import SelectInput from '../../Input/SelectedInputCatalogue';
import FieldName from '../FieldName';
import ButtonElement from '../../button/button';
import PlusIconWhite from '../../../assets/icons/PlusIconWhite.svg';
import SearchIconGrey from '../../../assets/icons/SearchIconGrey.svg';
import { Option } from '../../../utils/catalogueHelper/FamilyHelper';
import FilterIconCircleGrey from '../../../assets/icons/FilterIconCircleGrey.svg';
import RadioButtonGroup from '../../radioButton/RadioButtonGroup';
import { ArticleSaved } from '../../../models/services/module.catalogue';

/**
 * HeaderArticle component description.
 *
 * @HeaderArticle
 *
 */

interface HeaderArticleProps {
  title?: string;
  type: 'listing' | 'create';
  onSearch?: (e: ChangeEvent<HTMLInputElement>) => void;
  families?: Option[];
  subFamilies?: Option[];
  onSelect?: (type: 'family' | 'subfamily', id: number) => void;
  onNewClick?: () => void;
  hideAdd?: boolean;
  hideSelectFamily?: boolean;
  checked?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  setSelectedAction?: any;
  selectedAction?: any;
  handleSubmit?: (action: string) => void;
  articles?: ArticleSaved[] | undefined;
  onClick?: () => void;
  showFilter?: boolean;
  onClickActiveVisiblity?: () => void;
  onClickDisableVisiblity?: () => void;
  onClickDelete?: () => void;
  selectedIds?: any;
}

const HeaderArticle: React.FC<HeaderArticleProps> = ({
  title,
  type,
  onSearch,
  families,
  subFamilies,
  onSelect,
  onNewClick,
  hideAdd,
  hideSelectFamily,
  checked,
  onChange,
  setSelectedAction,
  selectedAction,
  handleSubmit,
  articles,
  onClick,
  showFilter,
  onClickActiveVisiblity,
  onClickDisableVisiblity,
  onClickDelete,
  selectedIds,
}) => {
  const SelectFamily = () => (
    <SelectInput
      hideOptionItem
      onChange={(selectedValue) =>
        onSelect && onSelect('family', selectedValue)
      }
      placeholder="Famille"
      target="dropdown"
      options={families ?? []}
    />
  );
  const Search = () => (
    <div className="Search search-inp">
      <img src={SearchIconGrey} alt="" />
      <FieldName placeholder="Recherche..." onChange={onSearch} />
    </div>
  );
  const SelectSubFamily = () => (
    <div className="SelectFamily">
      <SelectInput
        hideOptionItem
        onChange={(selectedValue) =>
          onSelect && onSelect('subfamily', selectedValue)
        }
        placeholder="Sous-Famille"
        target="dropdown"
        options={subFamilies ?? []}
      />
    </div>
  );
  const [showFilterSelect, setShowFilterSelect] = useState(false);
  const HandelFilterSelect = () => {
    setShowFilterSelect((prevState) => !prevState);
  };

  const renderHeaderContent = () => {
    switch (type) {
      case 'listing':
        return (
          <div
            className={`fl_col gp10 ${showFilterSelect ? '' : 'disable-select-filter'} ${showFilter ? 'showFilter' : ''}`}
          >
            <div className="sub-search fl_row gp16 ai_e fl_wrap">
              <div className="fl_row jc_c ai_c field-filter">{Search()}</div>
              <img
                className="btn-filter-search hide"
                src={FilterIconCircleGrey}
                alt=""
                onClick={HandelFilterSelect}
              />
              {!hideSelectFamily && (
                <div className="search-wrapper  fl_row gp16 ai_e">
                  <div className="fl_row jc_c ai_c field-filter">
                    {SelectFamily()}
                  </div>
                  {subFamilies && subFamilies?.length > 0 && (
                    <div className="fl_row jc_c ai_c field-filter">
                      {SelectSubFamily()}
                    </div>
                  )}
                </div>
              )}
            </div>
            {(articles ?? [])?.length > 0 && (
              <div className="fl_row ai_c gp20 filter-selection">
                <div className="fl_row ai_c gp10">
                  <input
                    type="checkbox"
                    checked={checked}
                    onChange={onChange}
                  />
                  <label>Tout sélectionner</label>
                </div>
                {selectedIds?.length <= 0 ? (
                  ''
                ) : (
                  <RadioButtonGroup
                    selectedAction={selectedAction}
                    setSelectedAction={setSelectedAction}
                    handleSubmit={handleSubmit}
                  />
                )}
              </div>
            )}
          </div>
        );
      case 'create':
        return <h1>Create</h1>;
      default:
        return null;
    }
  };

  return (
    <div className="HeaderArticle">
      <div className="header-wrapper pd20-r-l">{renderHeaderContent()}</div>
      {!hideAdd && (
        <ButtonElement
          onClick={onNewClick}
          className="btnSearch"
          icon={PlusIconWhite}
          radius="rd2"
          textSize="p15 w-500"
          text="Nouveau"
          type="IconButton"
          buttonStyle="btn-primary"
          buttonSize={'medium'}
          textColor="white-txt"
        />
      )}
    </div>
  );
};

export default HeaderArticle;
