import { useState } from 'react';
import SelectInputMarketing from '../../../components/Input/SelectInputMarketing';
import SelectInputArticles from '../../../components/Input/SelectInputArticles';

export default function RecompenseArticleFilterSelect({
  families,
  inputValue,
  setInputValue,
  filteredItems,
  setFilteredItems,
  filteredItemssubfamily,
  setFilteredItemssubfamily,
  inputValuesubfamily,
  setInputValuesubfamily,
  subFamiles,
}: any) {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdownsubfamily, setShowDropdownsubfamily] = useState(false);
  const filterItems = (value: string) => {
    if (value.trim() === '') {
      setFilteredItems(families);
    } else {
      const filtered = families?.filter((companie: any) =>
        companie?.name?.toLowerCase().includes(value?.toLowerCase()),
      );
      setFilteredItems(filtered);
    }
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setInputValue(value);
    filterItems(value);
    setShowDropdown(true);
  };

  //sub family

  const filterItemssubfamily = (value: string) => {
    if (value.trim() === '') {
      setFilteredItemssubfamily(subFamiles);
    } else {
      const filtered = subFamiles?.filter((companie: any) =>
        companie?.name?.toLowerCase().includes(value?.toLowerCase()),
      );
      setFilteredItemssubfamily(filtered);
    }
  };
  const handleChangesubfamily = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setInputValuesubfamily(value);
    filterItemssubfamily(value);
    setShowDropdownsubfamily(true);
  };

  console.log('filterItems', inputValue);
  return (
    <div className="RecompenseArticleFilterSelect">
      <div className="fl_row gp20">
        <div className="fl-1 fl_col gp5">
          <p className="p14 OxfordBlue01-txt">Famille</p>
          <SelectInputArticles
            target="search"
            options={families}
            setInputValue={setInputValue}
            filterItems={filterItems}
            inputValue={inputValue}
            setFilteredItems={setFilteredItems}
            filteredItems={filteredItems}
            setShowDropdown={setShowDropdown}
            showDropdown={showDropdown}
            onChange={handleChange}
            isRequired
          />
        </div>
        <div className="fl-1 fl_col gp5">
          <p className="p14 OxfordBlue01-txt">Sous famille</p>
          <SelectInputArticles
            target="search"
            options={subFamiles}
            setInputValue={setInputValuesubfamily}
            filterItems={filterItemssubfamily}
            inputValue={inputValuesubfamily}
            setFilteredItems={setFilteredItemssubfamily}
            filteredItems={filteredItemssubfamily}
            setShowDropdown={setShowDropdownsubfamily}
            showDropdown={showDropdownsubfamily}
            onChange={handleChangesubfamily}
            isRequired
          />
        </div>
      </div>
    </div>
  );
}
