import React from 'react';
import ButtonElement from '../../components/button/button';
import { languageData } from '../../i18n';

/**
 * ModalConfirmationCentralisation component description.
 *
 * @ModalConfirmationCentralisation
 *
 */

interface ModalConfirmationCentralisationProps {
  onClickConfirm?: () => void;
  onClickClose?: () => void;
}

const ModalConfirmationCentralisation: React.FC<
  ModalConfirmationCentralisationProps
> = ({ onClickConfirm, onClickClose }) => {
  return (
    <div className="ModalConfirmationCentralisation fl_col gp20">
      <p className="p18 w-600  DarkBlueGray-txt txt_center">
        Êtes-vous sûr de vouloir modifier la centralisation des points de
        fidélité ?  <br />
      </p>
      <p className="p18 w-400 DarkBlueGray-txt">
        Cette action pourrait affecter la gestion des points de fidélité des
        différents points de vente.
      </p>

      <div className="ModalFooter">
        <div className="wrapperFooter">
          <div className="fl_row gp12">
            <div className="fl-1">
              <ButtonElement
                type={'TextButton'}
                text={languageData.fr.Modal.cancel}
                buttonSize={'small'}
                onClick={onClickClose}
              />
            </div>
            <div className="fl-3">
              <ButtonElement
                type={'TextButton'}
                text="Confirmer"
                buttonSize={'small'}
                buttonStyle="btn-primary"
                onClick={onClickConfirm}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalConfirmationCentralisation;
