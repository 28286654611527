import React from 'react';
import ButtonElement from '../../../components/button/button';
import CartCheckboxChildElement from '../../../components/Carts/CartCheckboxChildElement';
import CartCheckboxParentElement from '../../../components/Carts/CartCheckboxParentElement';
import { languageData } from '../../../i18n';
import RightArrowAssign from '../../../assets/icons/RightArrowAssign.svg';
import InputField from '../../../components/Input/Input';
import CheckBox from '../../../components/Catalogue/Checkbox';
import RecompenseArticleFilterSelect from './RecompenseArticleFilterSelect';
import { Family } from '../../../models/services/module.catalogue';

/**
 * Modal component description.
 *
 * @Modal
 *
 */

interface ModalArticlesProps {
  onClickValidate: () => void;
  onClickClose: () => void;
  articles?: any;
  handleArrayChange?: any;
  handleCheckboxChange?: any;
  checheked?: boolean;
  rewardSettings?: any;
  setArticleName?: any;
  families?: Family[];
  filteredItems?: any;
  setFilteredItems?: any;
  inputValue?: any;
  setInputValue?: any;
  filteredItemssubfamily?: any;
  setFilteredItemssubfamily?: any;
  inputValuesubfamily?: any;
  setInputValuesubfamily?: any;
  subFamiles?: any;
}

const ModalArticles: React.FC<ModalArticlesProps> = ({
  onClickValidate,
  onClickClose,
  articles,
  handleArrayChange,
  handleCheckboxChange,
  checheked,
  rewardSettings,
  setArticleName,
  families,
  filteredItems,
  setFilteredItems,
  inputValue,
  setInputValue,
  filteredItemssubfamily,
  setFilteredItemssubfamily,
  inputValuesubfamily,
  setInputValuesubfamily,
  subFamiles,
}) => {
  console.log('articles', articles);
  return (
    <div className="ModalArticles">
      <div className="listing-list fl_col gp20">
        <div className="step-header fl_col gp20">
          <div className="fl_row gp40 ai_c jc_s header-container">
            <p className="p16 w-700">Liste des articles</p>
            <div className="search-inp fl-1">
              <InputField
                onChange={(e) => setArticleName(e.target.value)}
                type="text"
                placeholder="Recherche..."
                style="search"
              />
            </div>
          </div>
          <RecompenseArticleFilterSelect
            families={families}
            subFamiles={subFamiles}
            filteredItems={filteredItems}
            setFilteredItems={setFilteredItems}
            inputValue={inputValue}
            setInputValue={setInputValue}
            filteredItemssubfamily={filteredItemssubfamily}
            setFilteredItemssubfamily={setFilteredItemssubfamily}
            inputValuesubfamily={inputValuesubfamily}
            setInputValuesubfamily={setInputValuesubfamily}
          />
        </div>
        <CartCheckboxParentElement
          name={languageData.fr.navMenu.selectionnerTous}
          isChecked={checheked}
          onChange={handleArrayChange}
        >
          {articles?.map((article: any) => (
            <div
              title="Vous ne pouvez pas sélectionner cet article, car il est affecté à une autre règle"
              className={
                article?.linkedToAnotherRewardRule
                  ? 'fl_row gp5 ai_c disabled-item'
                  : 'fl_row gp5 ai_c '
              }
            >
              <CartCheckboxChildElement
                name={
                  article?.linkedToAnotherRewardRule
                    ? article?.name
                    : article?.name
                }
                isChecked={article?.isChecked}
                onChange={() => handleCheckboxChange(article?.id)}
                disabled={article?.linkedToAnotherRewardRule}
              />
              {article?.linkedToAnotherRewardRule && (
                <img width={'20px'} src={RightArrowAssign} alt="" />
              )}
              <p className="p12 w-600 designation-name">
                {article?.linkedToAnotherRewardRule
                  ? article?.rewardRuleName
                  : ''}
              </p>
            </div>
          ))}
        </CartCheckboxParentElement>
      </div>
      <div className="ModalFooter">
        <div className="wrapperFooter">
          <div className="fl_row gp12">
            <div className="fl-1">
              <ButtonElement
                type={'TextButton'}
                text={languageData.fr.Modal.cancel}
                buttonSize={'small'}
                onClick={onClickClose}
              />
            </div>
            <div className="fl-3">
              <ButtonElement
                type={'TextButton'}
                text="Valider"
                buttonSize={'small'}
                buttonStyle="btn-primary"
                onClick={onClickValidate}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalArticles;
