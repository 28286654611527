import axios from 'axios';
import axiosInstance, { BaseUrl, unAuthAxios } from './apiGate';

const commandEnLigneApi = {
  getChartData: async (CompanyId: string) => {
    try {
      const response = await axiosInstance.get(
        BaseUrl +
          `/ClickAndCollectGraphicChart/GetClickAndCollectGraphicChart?CompanyId=${CompanyId}`,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  postChartData: async (formData: any) => {
    try {
      const response = await axios.post(
        BaseUrl + `/ClickAndCollectGraphicChart`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  GetfetchArticlesRecompense: async (
    companyId: number,
    idFamily?: number,
    idSubFamily?: number,
    nom?: string,
    rewardRuleId?: string | null,
  ) => {
    try {
      const params: { [key: string]: any } = {};

      if (companyId !== undefined) params.companyId = companyId;
      if (idFamily !== undefined) params.idFamily = idFamily;
      if (idSubFamily !== undefined) params.idSubFamily = idSubFamily;
      if (nom) params.nom = nom;
      if (rewardRuleId) params.rewardRuleId = rewardRuleId;

      const queryString = new URLSearchParams(params).toString();

      const response = await axiosInstance.get(
        `${BaseUrl}/v1/getArticlesForRewardRule?${queryString}`,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  createRewardRule: async (companyId: number, data: any) => {
    try {
      const response = await axiosInstance.post(
        BaseUrl + `/v1/createRewardRule?companyId=${companyId}`,
        data,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  getRewardRule: async (companyId: number) => {
    try {
      const response = await axiosInstance.get(
        BaseUrl + `/v1/getListRewardRules?companyId=${companyId}`,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  deleteRewardRule: async (loyaltyRuleId: number) => {
    try {
      const response = await axiosInstance.delete(
        BaseUrl + `/v1/deleteRewardRuleById?rewardRuleId=${loyaltyRuleId}`,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  updateRewardRule: async (rewardRuleId: number, data: any) => {
    try {
      const response = await axiosInstance.put(
        BaseUrl + `/v1/updateRewardRule?rewardRuleId=${rewardRuleId}`,
        data,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  GetListEstablishementsForRewardRule: async (
    companyId: number,
    rewardRuleId: number,
  ) => {
    try {
      const response = await axiosInstance.get(
        BaseUrl +
          `/v1/getListEstablishementsForRewardRule?companyId=${companyId}&rewardRuleId=${rewardRuleId}`,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateActivatedisactivateRule: async (
    rewardRuleId: number,
    isActive: boolean,
  ) => {
    try {
      const response = await axiosInstance.put(
        BaseUrl +
          `/v1/activateDeactivateRewardRuleById?rewardRuleId=${rewardRuleId}&isActive=${isActive}`,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default commandEnLigneApi;
