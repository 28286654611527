import Table from '../../../components/Table/Table';
import Tbody from '../../../components/Table/Tbody-table';
import Td from '../../../components/Table/Td-table';
import Thead from '../../../components/Table/Thead-table';
import Tr from '../../../components/Table/Tr-table';
import DesktopTableContainer from '../../Table/DesktopTableContainer';
import MarketsIconCircle from '../../../assets/icons/MarketsIconCircle.svg';
import EditGreyIcon from '../../../assets/icons/EditGreyIcon.svg';
import DeleteGreyIcon from '../../../assets/icons/DeleteGreyIcon.svg';
import CardContainer from '../../CardContainer';
import TableHeaderCustom from '../../../components/Table/TableHeaderCustom';
import Modal from '../../../components/Modal/Modal';
import { useCallback, useEffect, useState } from 'react';
import AddRecompensePage from './AddRecompensePage';
import EditRecompensePage from './EditRecompensePage';
import ModalDeleteRecompense from './ModalDeleteRecompense';
import ModalPointsDeVentes from '../ModalPointsDeVentes';
import {
  ActivateDisactivateRewardRuleRequest,
  affectRewardRuleToEstablishments,
  createRewardRuleRequest,
  fetchArticlesRequest,
  getRewardRuleRequest,
  setClearTheError,
} from '../../../store/actions/actions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import commandEnLigneApi from '../../../services/apiHandlers/commandEnLigne';
import ConfirmModalPoint from '../ConfirmModalPointFidelite';
import { Family } from '../../../models/services/module.catalogue';
import { NotificationErrorProvider } from '../../../utils/Notificationprovider';
import {
  getFamily,
  getSubFamily,
} from '../../../store/actions/catalogues/actions';
import toast from 'react-hot-toast';
export const fieldsUpdates = [
  {
    name: 'designation',
    label: 'Désignation',
    required: true,
  },
  {
    name: 'subscriptionRewardTotalPt',
    label: 'Subscription Reward Total Points',
    type: 'number',
    required: false,
  },
  {
    name: 'firstOrderRewardTotalPt',
    label: 'First Order Reward Total Points',
    type: 'number',
    required: false,
  },
  {
    name: 'visitsRewardTotalPt',
    label: 'Visits Reward Total Points',
    type: 'number',
    required: false,
  },
  {
    name: 'nbOrdersForVisitsReward',
    label: 'Number of Orders for Visits Reward',
    type: 'number',
    required: false,
  },
  // {
  //   name: 'rewardTotalPtRequired',
  //   label: 'Reward Total Points Required',
  //   type: 'number',
  //   required: false,
  // },
  // {
  //   name: 'rewardDiscountType',
  //   label: 'Reward Discount Type',
  //   type: 'number',
  //   required: false,
  // },
  // {
  //   name: 'rewardDiscountValue',
  //   label: 'Reward Discount Value',
  //   type: 'number',
  //   required: false,
  // },
  {
    name: 'isActive',
    label: 'Is Active',
    type: 'checkbox',
  },
  {
    name: 'isSubscriptionRewardActive',
    label: 'Is Subscription Reward Active',
    type: 'checkbox',
  },
  {
    name: 'isFirstOrderRewardActive',
    label: 'Is First Order Reward Active',
    type: 'checkbox',
  },
  {
    name: 'isVisitsRewardActive',
    label: 'Is Visits Reward Active',
    type: 'checkbox',
  },
  {
    name: 'isCartReward',
    label: 'Is Cart Reward',
    type: 'checkbox',
  },
  {
    name: 'isArticlesReward',
    label: 'Is Articles Reward',
    type: 'checkbox',
  },
  {
    name: 'articlesIds',
    label: 'Articles IDs',
    type: 'array',
    required: false,
  },
  {
    name: 'cartRewardDiscountType',
    label: 'Cart Reward Discount Type',
    type: 'number',
    required: false,
  },
  {
    name: 'cartRewardTotalPtRequired',
    label: 'Cart Reward Total Points Required',
    type: 'number',
    required: false,
  },
  {
    name: 'cartRewardDiscountValue',
    label: 'Cart Reward Discount Value',
    type: 'number',
    required: false,
  },
  {
    name: 'articleRewardDiscountType',
    label: 'Article Reward Discount Type',
    type: 'number',
    required: false,
  },
  {
    name: 'articleRewardTotalPtRequired',
    label: 'Article Reward Total Points Required',
    type: 'number',
    required: false,
  },
  {
    name: 'articleRewardDiscountValue',
    label: 'Article Reward Discount Value',
    type: 'number',
    required: false,
  },
];

export default function RecompenseTable({ setShowRecompenseTable }: any) {
  const { inputtheValue } = useSelector(
    (state: { MarketingListCompanyReducer: { inputtheValue: number } }) =>
      state.MarketingListCompanyReducer,
  );
  const { error } = useSelector(
    (state: any) => state.estabmishmentRewordEffected,
  );
  const { articles } = useSelector(
    (state: any) => state.recomponseArticalesReducer,
  );
  const { ListrewardRule } = useSelector(
    (state: any) => state.getrewardRuleReducer,
  );

  const { error: rewordError } = useSelector(
    (state: any) => state.rewardRuleReducer,
  );
  const [showAddRecompense, setShowAddRecompense] = useState(false);
  const [showEditRecompense, setShowEditRecompense] = useState(false);
  const [showRemoveRecompense, setShowDeleteRecompense] = useState(false);
  const [showModalPointsDeVentes, setShowModalPointsDeVentes] = useState(false);
  const [showArticles, setshowArticles] = useState(false);
  const [selectedBooks, setSelectedBooks] = useState<Set<number>>(new Set());
  const [localBooks, setLocalBooks] = useState<any[]>([]);
  const [singlreward, SetSinglreward] = useState<any>({});
  const [editSinglreward, SetEditSinglreward] = useState<any>({});
  const [listOfEstablisment, setListOfEstablisment] = useState<any>([]);
  const [localBooksEstab, setLocalBooksEstab] = useState<any>([]);
  const [ModalConfirmModalPoint, setModalConfirmModalPoint] = useState(false);
  const [articleName, setArticleName] = useState('');
  const [selectedBooksEstab, setSelectedBooksEstab] = useState<Set<number>>(
    new Set(),
  );

  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [families, setfamilies] = useState<Family[]>();
  const [subFamiles, setsubFamiles] = useState<any[]>();
  const [inputValue, setInputValue] = useState<string | number>('');
  const [filteredItems, setFilteredItems] = useState<any[]>();

  //sub family
  const [inputValuesubfamily, setInputValuesubfamily] = useState<
    string | number
  >('');
  const [filteredItemssubfamily, setFilteredItemssubfamily] = useState<any[]>();

  interface RewardSettings {
    designation: string;
    isActive: boolean;
    isSubscriptionRewardActive: boolean;
    subscriptionRewardTotalPt: number | null;
    isFirstOrderRewardActive: boolean;
    firstOrderRewardTotalPt: number | null;
    isVisitsRewardActive: boolean;
    nbOrdersForVisitsReward: number | null;
    visitsRewardTotalPt: number | null;
    isCartReward: boolean;
    isArticlesReward: boolean;
    // rewardTotalPtRequired: number;
    // rewardDiscountType: number;
    // rewardDiscountValue: number;
    articlesIds: number[];
    cartRewardDiscountType: number | null;
    cartRewardTotalPtRequired: number | null;
    cartRewardDiscountValue: number | null;
    articleRewardDiscountType: number | null;
    articleRewardTotalPtRequired: number | null;
    articleRewardDiscountValue: number | null;
  }

  const [rewardSettings, setRewardSettings] = useState<RewardSettings>({
    designation: '',
    isActive: true,
    isSubscriptionRewardActive: false,
    subscriptionRewardTotalPt: null,
    isFirstOrderRewardActive: false,
    firstOrderRewardTotalPt: null,
    isVisitsRewardActive: false,
    nbOrdersForVisitsReward: null,
    visitsRewardTotalPt: null,
    isCartReward: false,
    isArticlesReward: false,
    // rewardTotalPtRequired: 0,
    // rewardDiscountType: 0,
    // rewardDiscountValue: 0,
    articlesIds: [],
    cartRewardDiscountType: 0,
    cartRewardTotalPtRequired: null,
    cartRewardDiscountValue: null,
    articleRewardDiscountType: 0,
    articleRewardTotalPtRequired: null,
    articleRewardDiscountValue: null,
  });

  // Use useEffect to update rewardSettings when editSinglreward changes
  useEffect(() => {
    if (editSinglreward) {
      setRewardSettings({
        designation: editSinglreward.designation || '',
        isActive: editSinglreward.isActive,
        isSubscriptionRewardActive:
          editSinglreward.isSubscriptionRewardActive || false,
        subscriptionRewardTotalPt:
          editSinglreward.subscriptionRewardTotalPt || null,
        isFirstOrderRewardActive:
          editSinglreward.isFirstOrderRewardActive || false,
        firstOrderRewardTotalPt:
          editSinglreward.firstOrderRewardTotalPt || null,
        isVisitsRewardActive: editSinglreward.isVisitsRewardActive || false,
        nbOrdersForVisitsReward:
          editSinglreward.nbOrdersForVisitsReward || null,
        visitsRewardTotalPt: editSinglreward.visitsRewardTotalPt || null,
        isCartReward: editSinglreward.isCartReward || false,
        isArticlesReward: editSinglreward.isArticlesReward || false,
        // rewardTotalPtRequired: editSinglreward.rewardTotalPtRequired || 0,
        // rewardDiscountType: editSinglreward.rewardDiscountType || 0,
        // rewardDiscountValue: editSinglreward.rewardDiscountValue || 0,
        articlesIds: editSinglreward.articlesIds || [],
        cartRewardDiscountType: editSinglreward.cartRewardDiscountType || 0,
        cartRewardTotalPtRequired:
          editSinglreward.cartRewardTotalPtRequired || null,
        cartRewardDiscountValue:
          editSinglreward.cartRewardDiscountValue || null,
        articleRewardDiscountType:
          editSinglreward.articleRewardDiscountType || 0,
        articleRewardTotalPtRequired:
          editSinglreward.articleRewardTotalPtRequired || null,
        articleRewardDiscountValue:
          editSinglreward.articleRewardDiscountValue || null,
      });
    }
  }, [editSinglreward]);
  console.log('rewardSettings', rewardSettings);
  useEffect(() => {
    setRewardSettings({
      designation: '',
      isActive: true,
      isSubscriptionRewardActive: false,
      subscriptionRewardTotalPt: null,
      isFirstOrderRewardActive: false,
      firstOrderRewardTotalPt: null,
      isVisitsRewardActive: false,
      nbOrdersForVisitsReward: null,
      visitsRewardTotalPt: null,
      isCartReward: false,
      isArticlesReward: false,
      // rewardTotalPtRequired: 0,
      // rewardDiscountType: 0,
      // rewardDiscountValue: 0,
      articlesIds: [],
      cartRewardDiscountType: 0,
      cartRewardTotalPtRequired: null,
      cartRewardDiscountValue: null,
      articleRewardDiscountType: 0,
      articleRewardTotalPtRequired: null,
      articleRewardDiscountValue: null,
    });
  }, [showAddRecompense]);
  const [errors, setErrors] = useState<Record<string, string>>({});

  const dispatch = useDispatch();

  const deleteRecompense = useCallback(
    async (id: number) => {
      try {
        await commandEnLigneApi.deleteRewardRule(id);
        setShowDeleteRecompense(false);
        dispatch(getRewardRuleRequest(inputtheValue));
      } catch (error) {
        console.error('Failed to delete reward rule:', error);
      }
    },
    [dispatch, inputtheValue],
  );

  const fetchEstablismentList = useCallback(async (id: number) => {
    try {
      const response =
        await commandEnLigneApi.GetListEstablishementsForRewardRule(
          inputtheValue,
          id,
        );
      setListOfEstablisment(response?.data?.result);
    } catch (error) {
      console.error('Failed to fetch establishment list:', error);
    }
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setRewardSettings((prevState: RewardSettings) => {
      const updatedSettings = {
        ...prevState,
        [name]: type === 'checkbox' ? checked : value,
      };

      if (name === 'isCartReward' && checked) {
        updatedSettings.isArticlesReward = false;
      } else if (name === 'isArticlesReward' && checked) {
        updatedSettings.isCartReward = false;
      }

      return updatedSettings;
    });
  };
  const handleRadioChange = (value: 0 | 1, name: string) => {
    setRewardSettings((prevState: RewardSettings) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleRadioChangeEdit = (value: 0 | 1, name: string) => {
    SetEditSinglreward((prevState: RewardSettings) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleInputChangeupdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    console.log('name', name, value ? 'false' : 'true');
    SetEditSinglreward((prevState: RewardSettings) => {
      const updatedSettings = {
        ...prevState,
        [name]: type === 'checkbox' ? checked : value,
      };

      if (name === 'isCartReward' && checked) {
        updatedSettings.isArticlesReward = false;
      } else if (name === 'isArticlesReward' && checked) {
        updatedSettings.isCartReward = false;
      }

      return updatedSettings;
    });
  };
  useEffect(() => {
    const deepCopyArticles = cloneDeep(articles);
    setLocalBooks(
      deepCopyArticles.map((article: any) => ({
        ...article,
        isChecked: false,
      })),
    );
  }, [articles]);
  const handleSelectionChange = (id: number, isChecked: boolean) => {
    const article = articles.find((item: any) => item.id === id);

    if (!article || article.linkedToAnotherRewardRule) return; // Do nothing if disabled

    setSelectedIds((prev) =>
      isChecked ? [...prev, id] : prev.filter((itemId) => itemId !== id),
    );
  };
  const handleSelectAllEdit = () => {
    const selectableArticles = articles.filter(
      (article: any) => !article.linkedToAnotherRewardRule,
    );

    if (selectedIds.length === selectableArticles.length) {
      setSelectedIds([]); // Deselect all
    } else {
      setSelectedIds(selectableArticles.map((article: any) => article.id)); // Select all selectable
    }
  };

  useEffect(() => {
    if (articles.length > 0) {
      // Initialize selectedIds based on linkedToActualRewardRule (even if linkedToAnotherRewardRule = true)
      const initiallySelected = articles
        .filter((item: any) => item.linkedToActualRewardRule)
        .map((item: any) => item.id);

      setSelectedIds(initiallySelected);
    }
  }, [articles]);
  useEffect(() => {
    SetEditSinglreward((prev: any) => ({
      ...prev,
      articlesIds: selectedIds,
    }));
  }, [selectedIds]); // Ensure this dependency is updated to reflect changes in selectedIds

  const handleCheckboxChangeEstablisment = (id: any) => {
    setLocalBooksEstab((prevLocalBooks: any) =>
      prevLocalBooks.map((establishment: any) =>
        establishment.id === id
          ? { ...establishment, isChecked: !establishment.isChecked }
          : establishment,
      ),
    );
    setSelectedBooksEstab((prevSelectedBooks: Set<any>) => {
      const updatedBooks = new Set(prevSelectedBooks);
      if (updatedBooks.has(id)) {
        updatedBooks.delete(id);
      } else {
        updatedBooks.add(id);
      }
      return updatedBooks;
    });
  };
  const handleSelectAll = () => {
    if (selectedBooksEstab.size === localBooksEstab.length) {
      // Deselect all
      setSelectedBooksEstab(new Set());
      setLocalBooksEstab((prevLocalBooks: any) =>
        prevLocalBooks.map((establishment: any) => ({
          ...establishment,
          isChecked: false,
        })),
      );
    } else {
      // Select all
      const allIds = localBooksEstab.map((book: any) => book.id);
      setSelectedBooksEstab(new Set(allIds));
      setLocalBooksEstab((prevLocalBooks: any) =>
        prevLocalBooks.map((establishment: any) => ({
          ...establishment,
          isChecked: true,
        })),
      );
    }
  };

  useEffect(() => {
    const deepCopyEstablishments = cloneDeep(listOfEstablisment);
    setLocalBooksEstab(deepCopyEstablishments);

    const initiallySelected = deepCopyEstablishments
      .filter((establishment: any) => establishment.isChecked)
      .map((establishment: any) => establishment.id);
    setSelectedBooksEstab(new Set(initiallySelected));
  }, [listOfEstablisment]);

  const handleArrayChange = () => {
    const selectableBooks = localBooks.filter(
      (book) => !book.linkedToAnotherRewardRule,
    );
    const allSelectableIds = new Set(selectableBooks.map((book) => book.id));

    // Check if all selectable books are already selected
    const allSelected = selectableBooks.every((book) =>
      selectedBooks.has(book.id),
    );

    if (allSelected) {
      // Deselect all selectable books
      setSelectedBooks((prevSelected) => {
        const newSelected = new Set(prevSelected);
        selectableBooks.forEach((book) => newSelected.delete(book.id));
        return newSelected;
      });
      setLocalBooks((prevLocalBooks) =>
        prevLocalBooks.map((book) => ({
          ...book,
          isChecked: false,
        })),
      );
      setRewardSettings((prevState) => ({
        ...prevState,
        articlesIds: [],
      }));
    } else {
      // Select all selectable books
      setSelectedBooks(allSelectableIds);
      setLocalBooks((prevLocalBooks) =>
        prevLocalBooks.map((book) => ({
          ...book,
          isChecked: allSelectableIds.has(book.id),
        })),
      );
      setRewardSettings((prevState) => ({
        ...prevState,
        articlesIds: Array.from(allSelectableIds),
      }));
    }
  };
  const handleCheckboxChange = (id: any) => {
    setLocalBooks((prevLocalBooks: any) =>
      prevLocalBooks.map((book: any) =>
        book.id === id ? { ...book, isChecked: !book.isChecked } : book,
      ),
    );

    const updatedBooks = new Set(selectedBooks);
    if (updatedBooks.has(id)) {
      updatedBooks.delete(id);
    } else {
      updatedBooks.add(id);
    }
    setSelectedBooks(updatedBooks);

    setRewardSettings((prevState: any) => ({
      ...prevState,
      articlesIds: Array.from(updatedBooks),
    }));
  };

  const validateForm = (): boolean => {
    const newErrors: Record<string, string> = {};

    fieldsUpdates.forEach((field) => {
      const { name, required, type } = field;
      if (
        required &&
        (!rewardSettings[name as keyof RewardSettings] ||
          (type === 'number' &&
            isNaN(Number(rewardSettings[name as keyof RewardSettings]))))
      ) {
        newErrors[name] = `${field.label} est obligatoire`;
      }
      if (
        type === 'number' &&
        rewardSettings[name as keyof RewardSettings] !== undefined
      ) {
        const value = Number(rewardSettings[name as keyof RewardSettings]);
        if (isNaN(value) || value < 0) {
          newErrors[name] = `${field.label} must be a valid positive number`;
        }
      }

      if (
        name === 'subscriptionRewardTotalPt' &&
        rewardSettings.isSubscriptionRewardActive
      ) {
        if (Number(rewardSettings[name]) === 0) {
          newErrors[name] = `Nombre de points à gagner doit être supérieur à 0`;
        }
      }

      if (
        name === 'firstOrderRewardTotalPt' &&
        rewardSettings.isFirstOrderRewardActive
      ) {
        if (Number(rewardSettings[name]) === 0) {
          newErrors[name] = `Nombre de points à gagner doit être supérieur à 0`;
        }
      }

      if (
        name === 'visitsRewardTotalPt' &&
        rewardSettings.isVisitsRewardActive
      ) {
        if (Number(rewardSettings[name]) === 0) {
          newErrors[name] =
            `Nombre de points à gagner doit être supérieur à 0e`;
        }
      }

      if (
        name === 'articleRewardTotalPtRequired' &&
        rewardSettings.isArticlesReward
      ) {
        if (Number(rewardSettings[name]) === 0) {
          newErrors[name] = `Nombre de points à gagner doit être supérieur à 0`;
        }
      }

      if (
        name === 'articleRewardDiscountValue' &&
        rewardSettings.isArticlesReward
      ) {
        if (Number(rewardSettings[name]) === 0) {
          newErrors[name] = `Nombre de points à gagner doit être supérieur à 0`;
        }
      }
      if (name === 'cartRewardTotalPtRequired' && rewardSettings.isCartReward) {
        console.log('e');
        if (Number(rewardSettings[name]) === 0) {
          newErrors[name] = `Nombre de points à gagner doit être supérieur à 0`;
        }
      }

      if (name === 'cartRewardDiscountValue' && rewardSettings.isCartReward) {
        if (Number(rewardSettings[name]) === 0) {
          newErrors[name] = `Nombre de points à gagner doit être supérieur à 0`;
        }
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (validateForm()) {
        setShowAddRecompense(false);
        dispatch(
          createRewardRuleRequest(inputtheValue, rewardSettings, () => {
            dispatch(getRewardRuleRequest(inputtheValue));
            setSelectedIds([]);
          }),
        );
      } else {
        setShowAddRecompense(true);
      }
    } catch (error) {
      setShowAddRecompense(true);
      console.log('An error occurred:', error);
    }
  };
  const handleEditSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (validateForm()) {
        setShowEditRecompense(false);
        await commandEnLigneApi.updateRewardRule(
          editSinglreward?.id,
          editSinglreward,
        );
        dispatch(getRewardRuleRequest(inputtheValue));
        dispatch(
          fetchArticlesRequest(
            inputtheValue,
            typeof inputValue === 'number' ? inputValue : undefined,
            typeof inputValuesubfamily === 'number'
              ? inputValuesubfamily
              : undefined,
            articleName,
            editSinglreward?.id,
          ),
        );
        setSelectedIds([]);
      } else {
        console.log('Please fix the errors in the form.');
      }
    } catch (error) {
      // toast.error(
      //   "Une erreur s'est produite lors de la mise à jour de la règle de récompense.",
      // );
      console.log('Please fix the errors in the form.');
    }
  };
  const fetchFamily = (_c?: (families: Family[]) => void) => {
    try {
      dispatch(
        getFamily((_) => {
          setfamilies(_);
        }),
      );
    } catch (error) {
      NotificationErrorProvider(error);
    }
  };

  useEffect(() => {
    dispatch(
      getSubFamily(inputValue as any, (_) => {
        let subFamiles = _?.inverseIdSubFamilyNavigation;
        setsubFamiles(subFamiles);
      }),
    );
  }, [dispatch, inputValue]);
  useEffect(() => {
    if (!inputtheValue) return;

    if (Object.keys(editSinglreward).length === 0) {
      dispatch(
        fetchArticlesRequest(
          inputtheValue,
          typeof inputValue === 'number' ? inputValue : undefined,
          typeof inputValuesubfamily === 'number'
            ? inputValuesubfamily
            : undefined,
          articleName,
        ),
      );
      fetchFamily();
    } else {
      dispatch(
        fetchArticlesRequest(
          inputtheValue,
          typeof inputValue === 'number' ? inputValue : undefined,
          typeof inputValuesubfamily === 'number'
            ? inputValuesubfamily
            : undefined,
          articleName,
          editSinglreward?.id,
        ),
      );
    }

    fetchFamily();
  }, [
    dispatch,
    articleName,
    inputValue,
    inputValuesubfamily,
    editSinglreward?.id,
  ]);

  const fetchArticles = () => {
    setshowArticles(true);
  };
  useEffect(() => {
    if (!inputtheValue) return;
    dispatch(getRewardRuleRequest(inputtheValue));
  }, [dispatch, inputtheValue]);
  console.log('articleName', articleName);
  useEffect(() => {
    dispatch(
      fetchArticlesRequest(
        inputtheValue,
        typeof inputValue === 'number' ? inputValue : undefined,
        typeof inputValuesubfamily === 'number'
          ? inputValuesubfamily
          : undefined,
        articleName,
      ),
    );
    dispatch(getRewardRuleRequest(inputtheValue));
  }, [showAddRecompense, inputtheValue, articleName]);
  const goBackAndLoadData = () => {
    if (!inputtheValue) return;
    dispatch(getRewardRuleRequest(inputtheValue));
    setShowAddRecompense(false);
    SetEditSinglreward({});
    setErrors({});
  };
  const activateDesactivateRule = (id: number, rule: { isActive: boolean }) => {
    const newIsActive = !rule.isActive;
    dispatch(
      ActivateDisactivateRewardRuleRequest(id, newIsActive, () => {
        dispatch(getRewardRuleRequest(inputtheValue));
      }),
    );
  };

  const confirmeffectedIstablishmenttrue = () => {
    dispatch(
      affectRewardRuleToEstablishments(
        editSinglreward?.id as number,
        Array.from(selectedBooksEstab) as any,
        true,
        () => {
          setModalConfirmModalPoint(false);
          // setShowModalEditPointFidelite(false);
          setShowModalPointsDeVentes(false);
        },
      ),
    );
  };

  const confirmeffectedIstablishmentfalse = () => {
    dispatch(
      affectRewardRuleToEstablishments(
        editSinglreward?.id as number,
        Array.from(selectedBooksEstab) as any,
        false,
        () => {
          setShowModalPointsDeVentes(false);
        },
      ),
    );
  };

  useEffect(() => {
    if (
      error?.codeFI?.code === 945 &&
      error?.codeFI?.message ===
        'you_have_establishements_affected_in_another_rule_that_you_can_crush'
    ) {
      setModalConfirmModalPoint(true);
    }
  }, [error]);

  const confirmeffectedIstablishment = () => {
    if (
      !error ||
      error?.codeFI?.code !== 945 ||
      error?.codeFI?.message !==
        'you_have_establishements_affected_in_another_rule_that_you_can_crush'
    ) {
      confirmeffectedIstablishmentfalse();
    }
  };

  return (
    <div className="RecompenseTable fl_col gp10 h-full w-full">
      {!showAddRecompense && !showEditRecompense && (
        <>
          <TableHeaderCustom
            type={'add'}
            title="Récompense des Clients"
            largeTitle
            onClickAdd={() => {
              SetEditSinglreward({});
              setShowAddRecompense(true);
            }}
            textAdd="Ajouter"
            onClickBack={() => setShowRecompenseTable(false)}
          />
          <CardContainer type="standard" fullHeight>
            <DesktopTableContainer>
              <Table className="Table TableEtablissement rd12">
                <Thead>
                  <Tr>
                    <Td type="text" value={'Désignation'} />
                    <Td
                      type="text"
                      value={'Récompense de bienvenue'}
                      width={15}
                    />
                    <Td
                      type="text"
                      value={'Récompense sur 1er achat'}
                      width={10}
                    />
                    <Td
                      type="text"
                      value={'Récompense de commandes'}
                      width={10}
                    />
                    <Td type="text" value={'Remise sur'} />

                    <Td type="text" value={'Points de vente'} />
                    <Td type="text" value={'Statut'} />
                    <Td type="emptyRow" width={3} />
                    <Td type="emptyRow" width={3} />
                  </Tr>
                </Thead>
                <Tbody>
                  {ListrewardRule?.map((rule: any) => (
                    <Tr key={rule?.id}>
                      <Td type="text" value={rule?.designation} />
                      <Td
                        type="pts"
                        isActive={rule?.isSubscriptionRewardActive}
                        value={rule?.subscriptionRewardTotalPt}
                        width={15}
                      />
                      <Td
                        type="pts"
                        isActive={rule?.isFirstOrderRewardActive}
                        value={rule?.firstOrderRewardTotalPt}
                        width={15}
                      />
                      <Td
                        type="pts"
                        isActive={rule?.isVisitsRewardActive}
                        value={rule?.visitsRewardTotalPt}
                        width={15}
                      />
                      <Td type="text" value={rule?.isArticeOrCart} />
                      <Td
                        type="icon"
                        iconSrc={MarketsIconCircle}
                        onClick={() => {
                          SetEditSinglreward(rule);
                          setShowModalPointsDeVentes(true);
                          fetchEstablismentList(rule?.id);
                        }}
                      />
                      <Td
                        type="switcher"
                        statusToggle={'active'}
                        ischecked={rule?.isActive}
                        onChange={() => activateDesactivateRule(rule?.id, rule)}
                        width={15}
                      />
                      <Td
                        type="icon"
                        iconSrc={EditGreyIcon}
                        onClick={() => {
                          SetEditSinglreward(rule);

                          setShowEditRecompense(true);
                          setErrors({});
                        }}
                        width={3}
                      />
                      <Td
                        type="icon"
                        iconSrc={DeleteGreyIcon}
                        onClick={() => {
                          SetSinglreward(rule);
                          setShowDeleteRecompense(true);
                        }}
                        width={3}
                      />
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </DesktopTableContainer>
          </CardContainer>
        </>
      )}
      {showAddRecompense && (
        <>
          <TableHeaderCustom
            type={'save'}
            title="Ajouter règle de récompense"
            largeTitle
            name="isActive"
            onClickAdd={() => setShowAddRecompense(false)}
            textAdd="save"
            onChange={handleInputChange}
            ischecked={rewardSettings?.isActive}
            onClickBack={goBackAndLoadData}
            onClickSave={handleSubmit}
          />
          <CardContainer type="standard" fullHeight>
            <AddRecompensePage
              fetchArticles={fetchArticles}
              handleInputChange={handleInputChange}
              rewardSettings={rewardSettings}
              articles={localBooks}
              setshowArticles={setshowArticles}
              showArticles={showArticles}
              handleArrayChange={handleArrayChange}
              handleCheckboxChange={handleCheckboxChange}
              checheked={
                selectedBooks.size ===
                  localBooks.filter((a: any) => !a.linkedToAnotherRewardRule)
                    .length && localBooks.length > 0
              }
              // isChecked={
              //   selectedIds &&
              //   selectedIds.length ===
              //     articles.filter((a: any) => !a.linkedToAnotherRewardRule).length
              // }
              errors={errors}
              setArticleName={setArticleName}
              families={families}
              handleRadioChange={handleRadioChange}
              subFamiles={subFamiles}
              filteredItems={filteredItems}
              setFilteredItems={setFilteredItems}
              inputValue={inputValue}
              setInputValue={setInputValue}
              filteredItemssubfamily={filteredItemssubfamily}
              setFilteredItemssubfamily={setFilteredItemssubfamily}
              inputValuesubfamily={inputValuesubfamily}
              setInputValuesubfamily={setInputValuesubfamily}
            />
          </CardContainer>
        </>
      )}
      {showEditRecompense && (
        <>
          <TableHeaderCustom
            type={'save'}
            title="Modifier règle de récompense"
            largeTitle
            onClickAdd={() => setShowEditRecompense(false)}
            textAdd="save"
            onChange={handleInputChangeupdate}
            name="isActive"
            ischecked={rewardSettings?.isActive}
            onClickBack={() => {
              SetEditSinglreward({});
              setShowEditRecompense(false);
            }}
            onClickSave={handleEditSubmit}
          />
          <CardContainer type="standard" fullHeight>
            <EditRecompensePage
          
              rewardSettings={rewardSettings}
              onChange={handleInputChangeupdate}
              errors={errors}
              handleRadioChangeEdit={handleRadioChangeEdit}
              handleArrayChange={handleSelectAllEdit}
              handleCheckboxChange={handleSelectionChange}
              articles={articles}
              selectedIds={selectedIds}
              setArticleName={setArticleName}
              subFamiles={subFamiles}
              families={families}
              filteredItems={filteredItems}
              setFilteredItems={setFilteredItems}
              inputValue={inputValue}
              setInputValue={setInputValue}
              filteredItemssubfamily={filteredItemssubfamily}
              setFilteredItemssubfamily={setFilteredItemssubfamily}
              inputValuesubfamily={inputValuesubfamily}
              setInputValuesubfamily={setInputValuesubfamily}
            />
          </CardContainer>
        </>
      )}
      {showRemoveRecompense && <></>}

      <Modal
        className="ModalDeleteRecompense"
        version="custom"
        visibility={showRemoveRecompense}
        close
        maxWidth={640}
        content={
          <ModalDeleteRecompense
            onClickConfirm={() => deleteRecompense(singlreward?.id)}
            onClickClose={() => setShowDeleteRecompense(false)}
            // isActivated
            // onChange={}
          />
        }
        onClickClose={() => setShowDeleteRecompense(false)}
      />
      {/* Modal for 'Points de ventes' */}
      <Modal
        className="ModalPointsDeVentes"
        version="custom"
        visibility={showModalPointsDeVentes}
        close
        maxWidth={640}
        content={
          <ModalPointsDeVentes
            onClickValidate={confirmeffectedIstablishment}
            onClickClose={() => setShowModalPointsDeVentes(false)}
            localBooks={localBooksEstab}
            handleCheckboxChange={handleCheckboxChangeEstablisment}
            checked={
              selectedBooksEstab.size === localBooksEstab.length &&
              localBooksEstab.length > 0
            }
            onChange={handleSelectAll}
            confirmeffectedIstablishmentfalse={
              confirmeffectedIstablishmentfalse
            }
            error={
              error?.codeFI?.code === 945 &&
              error?.codeFI?.message ===
                'you_have_establishements_affected_in_another_rule_that_you_can_crush'
            }
          />
        }
        onClickClose={() => {
          setShowModalPointsDeVentes(false);
          dispatch(setClearTheError());
        }}
      />

      <Modal
        className="ConfirmModalPoint"
        version="custom"
        visibility={ModalConfirmModalPoint}
        close
        maxWidth={640}
        content={
          <ConfirmModalPoint
            onClickConfirm={confirmeffectedIstablishmenttrue}
            onClickClose={() => {
              setModalConfirmModalPoint(false);
              dispatch(setClearTheError());
            }}
            // isActivated
            // onChange={}
          />
        }
        onClickClose={() => {
          setModalConfirmModalPoint(false);
          dispatch(setClearTheError());
        }}
      />
    </div>
  );
}
