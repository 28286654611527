import React from 'react';
import CheckBox from '../Catalogue/Checkbox';

/**
 * CartCheckboxChildElement component description.
 *
 * @CartCheckboxChildElement
 *
 */

interface CartCheckboxChildElementProps {
  name: string;
  isChecked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  circle?: boolean;
  disabled?: boolean;
  className?: string;
}

const CartCheckboxChildElement: React.FC<CartCheckboxChildElementProps> = ({
  name,
  isChecked,
  onChange,
  circle,
  disabled,
  className,
}) => {
  return (
    <div className={`CartCheckboxChildElement ${className ? className : ''}`}>
      <div className="CheckboxChild fl_row jc_s">
        <div className="left-side fl_row ai_c gp10 pd16-r-l">
          <CheckBox
            label={name}
            name={name}
            id={name}
            onChange={onChange}
            isChecked={isChecked}
            boxType="fullySelected"
            disabled={disabled}
          />
          {circle && <span className="circle-checkbox-shape"></span>}
        </div>
      </div>
    </div>
  );
};

export default CartCheckboxChildElement;
