import React, { useState } from 'react';
import AddElementIcon from '../../assets/icons/AddElementCirclePrimaryIcon.svg';
import AddElementCircleWhiteIcon from '../../assets/icons/AddElementCircleWhiteIcon.svg';
import CatalogueIconPrimary from '../../assets/icons/CatalogueIconPrimary.svg';
import GlossaireIconPrimary from '../../assets/icons/GlossaireIconPrimary.svg';
import SaveIconWhite from '../../assets/icons/SaveIconTransparentWhite.svg';
import SynchronizeIconBleu from '../../assets/icons/SynchronizeIconBleu.svg';
import LeftArrowReturnGrey from '../../assets/icons/LeftArrowReturnGrey.svg';
import InfoIconGrey from '../../assets/icons/InfoIconGrey.svg';
import TrashIconRed from '../../assets/icons/TrashIconRed.svg';
import CardContainer from '../../container/CardContainer';
import { languageData } from '../../i18n';
import { serviceEntrepriseModels } from '../../models/services/module.entreprise';
import { serviceEtablissmentMachineTypeModels } from '../../models/services/module.EtablisementMachineType';
import { serviceTabsModels } from '../../models/services/module.tabs';
import ButtonElement from '../button/button';
import InputField from '../Input/Input';
import MultiSelect from '../Input/MultiSelect';
import ToggleElement from '../Input/ToggleElement';
import ToggleSelect from '../Input/ToggleSelect';

/**
 * TableHeader component description.
 *
 * @TableHeader
 *
 */

interface TableHeaderProps {
  type:
    | 'title'
    | 'add'
    | 'save'
    | 'role'
    | 'adduser'
    | 'edituser'
    | 'synch'
    | 'filter'
    | 'catalogue';
  title?: string;
  largeTitle?: boolean;
  description?: string;
  slot?: number;
  textAdd?: string;
  onClickAdd?: () => void;
  onClickSave?: any;
  className?: string;
  labelSwitcher?: string;
  onChange?: any;
  onClickDelete?: () => void;
  onClickEtatDeStock?: () => void;
  ischecked?: boolean;
  hideDelete?: boolean;
  roleById?: serviceTabsModels.IRole;
  handleRoleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  rolePermissions?: any;
  haserror?: boolean;
  errorMsg?: string;
  filteredAddButton?: boolean;
  isClaimsValid?: boolean;
  activeMiniTab?: string;
  fichShow?: boolean;
  claimEtablissementAjout?: boolean;
  claimEtablissementModification?: boolean;
  claimEtablissementFicheModification?: boolean;
  claimsUser?: boolean;
  claimsRolesUsers?: boolean;
  claimUserFio?: boolean;
  isDefault?: boolean;
  companies_list?: serviceEntrepriseModels.companyListItem[];
  setCombineSearch?: any;
  status?: serviceEtablissmentMachineTypeModels.getList[];
  textAddMobile?: string;
  onClickGlossaire?: () => void;
  onClickOpenCart?: () => void;
  disabled?: boolean;
  isEtablishement?: boolean;
  hasPassword?: boolean;
  showSync?: boolean;
  isAdmin?: boolean;
  toggleSwitcher?: boolean;
  toggleLabel?: string;
  onClickBack?: () => void;
  hideNav?: boolean;
  infoTxt?: string;
  name?: string;
}

const TableHeaderCustom: React.FC<TableHeaderProps> = ({
  type,
  title,
  largeTitle,
  description,
  slot,
  onClickAdd,
  onClickSave,
  textAdd,
  className,
  labelSwitcher,
  onChange,
  onClickDelete,
  ischecked,
  hideDelete,
  rolePermissions,
  haserror,
  errorMsg,
  filteredAddButton,
  isClaimsValid,
  fichShow,
  claimEtablissementAjout,
  claimEtablissementModification,
  claimEtablissementFicheModification,
  claimsUser,
  claimUserFio,
  companies_list,
  setCombineSearch,
  status,
  textAddMobile,
  onClickGlossaire,
  onClickEtatDeStock,
  onClickOpenCart,
  disabled,
  isEtablishement,
  hasPassword,
  showSync,
  isAdmin,
  claimsRolesUsers,
  toggleSwitcher,
  toggleLabel,
  onClickBack,
  hideNav,
  infoTxt,
  name,
}) => {
  const HeaderContent = () => {
    const [selected, setSelected] = useState<string | null>(null);
    const [showSearchMode, setShowSearchMode] = useState(false);
    const [showFilterOptions, setShowFilterOptions] = useState(false);
    const HandelFilterOptions = () => {
      setShowFilterOptions((prevState) => !prevState);
    };

    switch (type) {
      case 'title':
        return toggleSwitcher ? (
          <div
            className={`header-wrapper pd12-t-b fl_row ai_c rd12 fl_col gp10`}
          >
            <div className="fl_row jc_s ai_c w-full fl_wrap">
              <div className="fl_col gp10 row-1">
                <div className="fl_row gp30 ai_c">
                  {onClickBack && !hideNav ? (
                    <img
                      onClick={onClickBack}
                      src={LeftArrowReturnGrey}
                      className="c-p nav-arrow"
                      width={40}
                      alt=""
                    />
                  ) : (
                    ''
                  )}
                  {title && (
                    <p
                      className={`w-900 DarkJungleGreen-txt ${largeTitle ? 'p30' : 'p18'}`}
                    >
                      {title}
                    </p>
                  )}
                </div>
                {description && <p>{description}</p>}
              </div>
              <div className="fl_row gp10 ai_c row-2">
                <InputField
                  style="switcher"
                  name="active"
                  checked={ischecked}
                  onChange={onChange}
                />
                <p className="p14 OxfordBlue02-txt txt-nowrap w-500">
                  {toggleLabel}
                </p>
                {infoTxt && (
                  <div className="info-field ai_c">
                    <img title={infoTxt} src={InfoIconGrey} />
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div
            className={`header-wrapper pd12-t-b fl_row ai_c rd12 fl_col gp10`}
          >
            {title && (
              <div className="fl_row gp30 ai_c">
                {onClickBack && !hideNav ? (
                  <img
                    onClick={onClickBack}
                    src={LeftArrowReturnGrey}
                    className="c-p nav-arrow"
                    width={40}
                    alt=""
                  />
                ) : (
                  ''
                )}
                {title && (
                  <p
                    className={`w-900 DarkJungleGreen-txt ${largeTitle ? 'p30' : 'p18'}`}
                  >
                    {title}
                  </p>
                )}
              </div>
            )}
            {description && <p>{description}</p>}
          </div>
        );

      case 'save':
        return (
          <div
            className={`header-wrapper fl_row ai_c rd12 ${title ? 'jc_s' : 'jc_fe'}`}
          >
            {/* {(isClaimsValid && fichShow) ||
            (claimEtablissementModification &&
              claimEtablissementFicheModification) ? ( */}
            <>
              <div className="fl_row gp30 ai_c">
                {onClickBack && !hideNav ? (
                  <img
                    onClick={onClickBack}
                    src={LeftArrowReturnGrey}
                    className="c-p nav-arrow"
                    width={40}
                    alt=""
                  />
                ) : (
                  ''
                )}
                {title && (
                  <p className="p18 w-900 DarkJungleGreen-txt">{title}</p>
                )}
              </div>
              <div className="fl_row gp20 ai_c">
                {onChange && (
                  <InputField
                    style="switcher"
                    checked={ischecked}
                    label={ischecked ? "Activé" : "Désactivé"}
                    onChange={onChange}
                    name={name}
                  />
                )}

                <ButtonElement
                  buttonStyle="btn-primary"
                  type={'IconButton'}
                  text={
                    disabled
                      ? languageData.global.Modal.loading
                      : languageData.global.Modal.save
                  }
                  buttonSize={'large'}
                  onClick={onClickSave}
                  icon={SaveIconWhite}
                  reverse
                  isDisabled={disabled}
                />
              </div>
            </>

            {/* ) : null} */}
          </div>
        );
        break;
      case 'add':
        return (
          <div className={`header-wrapper fl_row jc_s ai_c rd12 fl_wrap gp20`}>
            <div className="table-info fl_col gp10">
              <div className="fl_row gp40">
                <div className="fl_row gp30 ai_c">
                  {onClickBack && !hideNav ? (
                    <img
                      onClick={onClickBack}
                      src={LeftArrowReturnGrey}
                      className="c-p nav-arrow"
                      width={40}
                      alt=""
                    />
                  ) : (
                    ''
                  )}
                  {title && (
                    <p
                      className={`w-900 DarkJungleGreen-txt ${largeTitle ? 'p30' : 'p18'}`}
                    >
                      {title}
                    </p>
                  )}
                </div>
                {slot && <span className="counter p12 w-500"> {slot}</span>}
              </div>
              {description && (
                <p className="p14 Independence-txt description-title">
                  {description}
                </p>
              )}
            </div>

            {/* {isAdmin || claimsRolesUsers ? ( */}

            <div className="fl-1 fl_row jc_fe">
              <div className="btn-add gp8" onClick={onClickAdd}>
                <img src={AddElementIcon} alt="" />

                {textAdd ? (
                  <p className="">
                    <span className="textAdd p16 w-700 primary-txt">
                      {textAdd}
                    </span>{' '}
                    <span className="textAddMobile p16 w-700 primary-txt">
                      {textAddMobile}
                    </span>
                  </p>
                ) : (
                  <p className="p16 w-700 primary-txt">
                    {' '}
                    {languageData.fr.add}
                  </p>
                )}
              </div>
            </div>

            {/*  ) : null} */}
          </div>
        );
        break;
      case 'role':
        return (
          <div className="header-wrapper fl_row ai_c rd12 jc_s">
            <div className="fl_row gp20 ai_c left-side-header">
              <InputField
                style="form"
                label={languageData.fr.SubMenuLabel.Intitule_role}
                name="name"
                value={rolePermissions?.role?.name}
                onChange={onChange}
                haserror={haserror}
                errorMsg={errorMsg}
              />
              <div className="fl_row">
                <InputField
                  style="switcher"
                  label={labelSwitcher}
                  name="active"
                  checked={rolePermissions?.role?.active}
                  onChange={onChange}
                />
              </div>
            </div>

            <div className="fl_row gp20 right-side-header">
              {hideDelete && (
                <ButtonElement
                  buttonStyle="btn-red-light"
                  type={'IconButton'}
                  text={languageData.global.supprimer}
                  buttonSize={'large'}
                  onClick={onClickDelete}
                  icon={TrashIconRed}
                  reverse
                />
              )}

              <ButtonElement
                buttonStyle="btn-primary"
                type={'TextButton'}
                text={languageData.global.Modal.save}
                buttonSize={'large'}
                onClick={onClickSave}
              />
            </div>
          </div>
        );
        break;

      case 'adduser':
        return (
          <div className="header-wrapper fl_row ai_c rd12 jc_s">
            {title && <p className="p18 w-900 DarkJungleGreen-txt">{title}</p>}
            <div className="fl_row gp20 ai_c">
              <div className="fl_row">
                <InputField
                  style="switcher"
                  label={labelSwitcher}
                  onChange={onChange}
                  checked={ischecked}
                />
              </div>
              <ButtonElement
                buttonStyle="btn-primary"
                type={'IconButton'}
                text={languageData.global.Modal.save}
                buttonSize={'large'}
                onClick={onClickSave}
                icon={SaveIconWhite}
                reverse
              />
            </div>
          </div>
        );
      case 'edituser':
        return (
          <div className="header-wrapper fl_row ai_c rd12 jc_s">
            {title && <p className="p18 w-900 DarkJungleGreen-txt">{title}</p>}
            <div className="fl_row gp20 ai_c">
              {/* {hasPassword && (
                <div className="fl_row">
                  <InputField
                    style="switcher"
                    checked={ischecked}
                    label={labelSwitcher}
                    onChange={onChange}
                  />
                </div>
              )} */}

              <div className="fl_row">
                <InputField
                  style="switcher"
                  checked={ischecked}
                  label={labelSwitcher}
                  onChange={onChange}
                />
              </div>

              <div className="fl_row buttons-wrapper gp40 jc_s">
                <ButtonElement
                  buttonStyle="btn-red-light"
                  type={'IconButton'}
                  text={languageData.global.supprimer}
                  buttonSize={'large'}
                  onClick={onClickDelete}
                  icon={TrashIconRed}
                  reverse
                />

                <ButtonElement
                  reverse
                  buttonStyle="btn-primary"
                  type={'IconButton'}
                  text={languageData.global.Modal.save}
                  buttonSize={'large'}
                  onClick={onClickSave}
                  icon={SaveIconWhite}
                />
              </div>
            </div>
          </div>
        );
      case 'synch':
        return (
          <div className={`header-wrapper fl_row jc_s ai_c rd12 fl_wrap gp20`}>
            <div className="table-info fl_col gp10">
              <div className="fl_row gp40">
                {title && (
                  <p className="p18 w-900 DarkJungleGreen-txt">{title}</p>
                )}
                {slot && <span className="counter p12 w-500"> {slot}</span>}
              </div>
              {description && (
                <p className="p14 Independence-txt description-title">
                  {description}
                </p>
              )}
            </div>
            {isAdmin || showSync ? (
              <div className="btn-synch gp8" onClick={onClickAdd}>
                <img src={SynchronizeIconBleu} alt="" />
                <p className="p16 w-700 primary-txt"> Synchroniser</p>
              </div>
            ) : null}
          </div>
        );
        break;
      case 'filter':
        const handleCheckbox = (id: string) => {
          setSelected(id);
        };
        return (
          <div className={`header-wrapper fl_row jc_s ai_c rd12 fl_wrap gp20`}>
            <div className="table-info fl_col gp10 pd12-t-b">
              <div className="fl_row gp40">
                {title && (
                  <p className="p18 w-900 DarkJungleGreen-txt">{title}</p>
                )}
              </div>
            </div>
            <div className="filter-table fl_row ai_c gp20">
              <InputField
                type="text"
                name="name_PhoneNumber"
                onChange={onChange}
                style="filter"
                isDisabled={false}
                allowOnlyLetters
                placeholder="Rechercher..."
              />

              {/* <MultiSelect
                // classList="hide"
                // onChange={onChange}
                setCombineSearch={setCombineSearch}
                // name="companyId"
                companies_list={companies_list || []}
                list={[
                  'Five Pizza Original AZ',
                  'FIO Etablissement  AMEP VEEJffR AfZddAZAZA',
                  'FIO Etablissement  na APEMRfRRPRp',
                  'Five Pizza Original XD',
                  'FIO Etablissement  nananananannana',
                  'FIO Etablissement  bababababbababababaff',
                  'Five Pizza Original TG',
                  'FIO Etablissement  AMEP VEEJR AZAZAZA',
                  'FIO Etablissement  na APEMfRRRPRp',
                  'Five Pizza Original NH',
                  'FIO Etablissement  nananananfffannana',
                  'FIO Etablissement  bababababbababababa',
                  'Five Pizza Original JTT',
                  'FIO Etablissement  AMEP VEEJRd AfZAZAZA',
                  'FIO Etablissement  na APEMRfRRPRffp',
                  'Five Pizza Original PME',
                  'FIO Etablissement  nananananffannana',
                  'FIO Etablissement  bababababbffababababa',
                ]}
                onChange={function (selected: string[]): void {
                  throw new Error('Function not implemented.');
                }}
              /> */}
              <ToggleSelect>
                {status?.map((item: any) => (
                  <ToggleElement
                    key={item?.id}
                    name="status_CompanyId"
                    label={item?.name}
                    id={String(item?.id)}
                    value={item?.id}
                    active={selected === item?.name}
                    onClick={() => handleCheckbox(item?.name)}
                    onChange={onChange}
                  />
                ))}
              </ToggleSelect>
              {textAdd && (
                <div className="btn-add gp8" onClick={onClickAdd}>
                  <img src={AddElementIcon} alt="" />

                  {textAdd ? (
                    <p className="">
                      <span className="textAdd p16 w-700 primary-txt">
                        {textAdd}
                      </span>{' '}
                      <span className="textAddMobile p16 w-700 primary-txt">
                        {textAddMobile}
                      </span>
                    </p>
                  ) : (
                    <p className="p16 w-700 primary-txt">
                      {' '}
                      {languageData.fr.add}
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
        );

      case 'catalogue':
        return (
          <div className={`header-wrapper fl_row jc_s ai_c rd12 fl_wrap gp20`}>
            <div className="table-info fl_col gp10">
              {title && (
                <p className="p18 w-900 DarkJungleGreen-txt">{title}</p>
              )}
            </div>
            <div className="fl_row gp10">
              {!isEtablishement && (
                <ButtonElement
                  reverse
                  buttonStyle="btn-white-primary"
                  type="IconButton"
                  buttonSize="large"
                  text={languageData.global.catalogue.glossaire}
                  icon={GlossaireIconPrimary}
                  onClick={onClickGlossaire}
                />
              )}
              {isEtablishement && (
                <ButtonElement
                  reverse
                  buttonStyle="btn-white-primary"
                  type="IconButton"
                  buttonSize="large"
                  text={languageData.global.catalogue.stockStatus}
                  icon={CatalogueIconPrimary}
                  onClick={onClickEtatDeStock}
                />
              )}

              {!isEtablishement && (
                <ButtonElement
                  onClick={onClickOpenCart}
                  reverse
                  buttonStyle="btn-primary"
                  type="IconButton"
                  buttonSize="large"
                  text={languageData.global.catalogue.CreateCart}
                  icon={AddElementCircleWhiteIcon}
                />
              )}
            </div>
          </div>
        );
    }
  };
  return (
    <>
      <CardContainer
        type="standard"
        ClassName={`${type === 'save' ? 'save-settings' : ''} ${haserror ? 'pd20-b' : ''}  ${className ? className : ''}`}
      >
        <div className={`table-header  type_${type}`}>{HeaderContent()}</div>
      </CardContainer>
    </>
  );
};

export default TableHeaderCustom;
