import * as actionTypes from '../../actions/catalogues/actionTypes';
const initialState = {
  loading: false,
  data: null,
  error: null,
};

const multipleLevelsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.MULTIPLE_LEVELS_REQUEST:
      return { ...state, loading: true, error: null };
    case actionTypes.MULTIPLE_LEVELS_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case actionTypes.UPDATE_SUCCESS:
      return { ...state, loading: false, data: null };
    case actionTypes.MULTIPLE_LEVELS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default multipleLevelsReducer;
