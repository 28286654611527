import * as actionTypes from '../../actions/catalogues/actionTypes';

const initialState = {
  selectAllMenu: false,
  selectedIdsMenu: [] as number[],
};

export const selectionMenuReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_SELECT_ALL_MENU:
      return { ...state, selectAllMenu: action.payload };
    case actionTypes.ClEAR_SELECT_ALL_MENU:
      return { ...state, selectAllMenu: false, selectedIdsMenu: [] };
    case actionTypes.SET_SELECTED_IDS_MENU:
      return { ...state, selectedIdsMenu: action.payload };
    default:
      return state;
  }
};
export default selectionMenuReducer;
