import { AxiosResponse } from 'axios';
import { catalogueApi } from '../../../services/apiHandlers/catalogueApi';
import { NotificationErrorProvider } from '../../../utils/Notificationprovider';
import {
  CategoryPayLoad,
  categoryAction,
} from '../../../models/services/module.category';
import toast from 'react-hot-toast';
import { fetchActionCategoriesFailure, fetchActionCategoriesSuccess } from '../../actions/catalogues/actions';
import { put } from 'redux-saga/effects';
export function* startGetAllCategory(action: categoryAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.getCategoryList(action.noEmpty);
    if (response.data) {
      action._ && action._(response.data?.data?.result??[]);
    }
  } catch (error: any) {
    NotificationErrorProvider(error);
  }
}
export function* postCategory(action: categoryAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.postCategory(
      action.category as CategoryPayLoad,
    );
    if (response.data) {
      action._ && action._();
    }
  } catch (error: any) {
    NotificationErrorProvider(error);
  }
}
export function* getCategoryById(action: categoryAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.getCategoryById(
      action.id as string,
    );
    if (response.data) {
      action._ && action._(response.data?.data?.result);
    }
  } catch (error: any) {
    NotificationErrorProvider(error);
  }
}
export function* deleteCategoryById(action: categoryAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.deleteCategoryById(
      action.id as string,
    );
    if (response.data) {
      action._ && action._(response.data?.data?.result[0]);
    }
  } catch (error: any) {
    NotificationErrorProvider(error);
  }
}

const messages: Record<string, string> = {
  '0': 'Suppression effectuée avec succès',
  '1': 'Activation effectuée avec succès',
  '2': 'Désactivation effectuée avec succès',
};
export function* actionMultipleCategoriesReducerSaga(action: any) {
  try {
    const { IdEntreprise, ActionCategories, data, _ } = action.payload;
    const response: AxiosResponse =
      yield catalogueApi.actionMultipleCategoriesReducer(
        IdEntreprise,
        ActionCategories,
        data,
      );
    if (response.data) {
      yield put(fetchActionCategoriesSuccess(response?.data?.data?.result));
      if (messages[ActionCategories]) {
        toast.success(messages[ActionCategories]);
      }
      _ && _();
    }
  } catch (error: any) {
    fetchActionCategoriesFailure(error);
  }
}