import React, { useState } from 'react';
import TimerSelect from './TimerSelect';
import ButtonElement from '../../../../components/button/button';
import addBtnFullBgBleu from '../../../../assets/icons/addBtnFullBgBleu.svg';
import TrashIconRed from '../../../../assets/icons/TrashIconRed.svg';
import SaveIconWhiteBtn from '../../../../assets/icons/SaveIconWhiteBtn.svg';
import { DaySelector } from './DaySelector';

/**
 * PlageHoraire component description.
 *
 * @PlageHoraire
 *
 */

const PlageHoraire = ({
  workHours,
  onChange,
  onAdd,
  onDelete,
  handleTimeChange,
  loading,
}: any) => {
  if (loading) return <div>loading...</div>;

  function isBeginTimeGreaterThanEndTime(
    beginTime: string,
    endTime: string,
  ): boolean {
    const [beginHour, beginMinute] = beginTime.split(':').map(Number);
    const [endHour, endMinute] = endTime.split(':').map(Number);
    const beginTotalMinutes = beginHour * 60 + beginMinute;
    const endTotalMinutes = endHour * 60 + endMinute;
    return beginTotalMinutes > endTotalMinutes;
  }
  const days = [
    'Lundi',
    'Mardi',
    'Mercredi',
    'Jeudi',
    'Vendredi',
    'Samedi',
    'Dimanche',
  ];

  return (
    <div className="PlageHoraire tab-content-section">
      <div className="fl_col">
        <div className="repeater-wrapper fl_col gp20">
          {workHours?.map((timeRange: any, index: any) => {
            return (
              <div className="block-wrapper fl_col gp20">
                <div className="time-repeater rd12">
                  <div className="date-picker fl_row gp10">
                    {days?.map((day, dayIndex) => {
                      return (
                        <DaySelector
                          key={day}
                          day={day}
                          dayIndex={dayIndex}
                          isSelected={timeRange.days.includes(dayIndex + 1)}
                          onChange={() => onChange(index, dayIndex + 1)}
                          showNextDay={isBeginTimeGreaterThanEndTime(
                            timeRange.beginTime,
                            timeRange.endTime,
                          )}
                          endTime={timeRange.endTime}
                        />
                      );
                    })}
                  </div>
                </div>
                <div className="timer-setter fl_row jc_s ai_e">
                  <div className="row gp30 ai_e">
                    <div className="timer-field fl_col gp8">
                      <p className="p18 w-500">De</p>
                      <TimerSelect
                        dropdown
                        label="Temps moyen de Livraison"
                        value={timeRange?.beginTime}
                        onChange={(e: any) =>
                          handleTimeChange(index, 'beginTime', e.target.value)
                        }
                      />
                    </div>
                    <div className="timer-field fl_col gp8">
                      <p className="p18 w-500 ">Jusqu’a</p>
                      <TimerSelect
                        dropdown
                        label="Temps moyen de Livraison"
                        value={timeRange?.endTime}
                        onChange={(e: any) =>
                          handleTimeChange(index, 'endTime', e.target.value)
                        }
                      />
                    </div>
                    {isBeginTimeGreaterThanEndTime(
                      timeRange.beginTime,
                      timeRange.endTime,
                    ) && <span className="p16 pd10-t-b">(+1 jour)</span>}
                  </div>

                  <ButtonElement
                    buttonStyle="btn-red-light"
                    type={'IconButton'}
                    text={'Supprimer'}
                    buttonSize={'large'}
                    onClick={() => onDelete(index)}
                    icon={TrashIconRed}
                    reverse
                  />
                </div>
              </div>
            );
          })}
        </div>
        <div className="fl_row jc_fe pd20-r-l">
          <button
            className="add-repeater p16 fl_row ai_c jc_s w-500"
            onClick={onAdd}
          >
            <img src={addBtnFullBgBleu} alt="" />
            Ajouter
          </button>
        </div>
      </div>
      {/* <div className="fl_row jc_fe">
        <button className="save-btn">
          <img src={SaveIconWhiteBtn} alt="" />
          Sauvegarder
        </button>
      </div> */}
    </div>
  );
};

export default PlageHoraire;
