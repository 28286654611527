import React, { useEffect, useState } from 'react';
import ButtonElement from '../../../components/button/button';
import ArticlesIconPrimary from '../../../assets/icons/ArticlesIconPrimary.svg';
import InputField from '../../../components/Input/Input';
import SwitcherCheckbox from '../../../components/Catalogue/SwitcherCheckbox';
import Modal from '../../../components/Modal/Modal';
import ModalArticles from './ModalArticles';
import { Family } from '../../../models/services/module.catalogue';

/**
 * Modal component description.
 *
 * @Modal
 *
 */

interface AddRecompensePropsPage {
  onClickSave?: () => void;
  onClickClose?: () => void;
  isActivated?: boolean;
  onChange?: () => void;
  handleInputChange?: (e: any) => void;
  rewardSettings?: any;
  fetchArticles?: () => void;
  articles?: any;
  setshowArticles?: any;
  showArticles?: any;
  handleArrayChange?: any;
  handleCheckboxChange?: any;
  checheked?: boolean;
  errors?: any;
  setArticleName?: any;
  families?: any;
  handleRadioChange?: any;
  filteredItems?: any;
  setFilteredItems?: any;
  inputValue?: any;
  setInputValue?: any;

  filteredItemssubfamily?: any;
  setFilteredItemssubfamily?: any;
  inputValuesubfamily?: any;
  setInputValuesubfamily?: any;
  subFamiles?: any;
}

const AddRecompensePage: React.FC<AddRecompensePropsPage> = ({
  onClickSave,
  onClickClose,
  isActivated,
  onChange,
  handleInputChange,
  rewardSettings,
  fetchArticles,
  articles,
  setshowArticles,
  showArticles,
  handleArrayChange,
  handleCheckboxChange,
  checheked,
  errors,
  setArticleName,
  families,
  handleRadioChange,
  filteredItems,
  setFilteredItems,
  inputValue,
  setInputValue,
  filteredItemssubfamily,
  setFilteredItemssubfamily,
  inputValuesubfamily,
  setInputValuesubfamily,
  subFamiles,
}) => {
  const [selectedItemOne, setSelectedItemOne] = useState<string | number>(0);
  const [selectedItemTwo, setSelectedItemTwo] = useState<string | number>(1);
  const [statusArticles, setStatusArticles] = useState(false);
  const [statusPanier, setStatusPanier] = useState(false);
  useEffect(() => {
    setArticleName('');
    setInputValue('');
    setInputValuesubfamily('');
  }, [showArticles]);
  return (
    <div>
      <div className="fl_col gp40 pd40-t-b">
        <div className="fl_row ai_c jc_s">
          <div className="fl_row jc_fe w-full designation-field">
            <div className="fl_row  w-full ai_e gp40">
              <InputField
                isRequired={true}
                name="designation"
                placeholder="ex. Récompense d'hiver"
                // value={rewardSettings?.designation}
                type="text"
                label={'Désignation'}
                style="form"
                isDisabled={false}
                onChange={handleInputChange}
                haserror={errors?.designation ? true : false}
                errorMsg={errors?.designation}
              />
            </div>
          </div>
        </div>
        <div className="fl_col gp10">
          <div className="fl_row gp40 tab-one">
            <div className=" fl_row fl-1 gp40">
              <div className="fl_col gp20 fl-1">
                <fieldset
                  className={`fl_row gp20 ${rewardSettings?.isSubscriptionRewardActive ? 'active-fieldset' : ''}`}
                >
                  <legend className="pd10-r-l">
                    <SwitcherCheckbox
                      ischecked={rewardSettings?.isSubscriptionRewardActive}
                      onChange={handleInputChange}
                      label={'Récompense de bienvenue'}
                      name="isSubscriptionRewardActive"
                    />
                  </legend>
                  <div className="pd20-r-l">
                    <InputField
                      isRequired={true}
                      name="subscriptionRewardTotalPt"
                      type="number"
                      label={'Nombre de points à gagner'}
                      style="form"
                      isDisabled={false}
                      onChange={handleInputChange}
                      haserror={
                        errors?.subscriptionRewardTotalPt ? true : false
                      }
                      errorMsg={errors?.subscriptionRewardTotalPt}
                      decimal
                    />
                  </div>
                </fieldset>
              </div>
              {/* <span className="diver-bar"></span> */}
              <div className="fl_col gp20 fl-1">
                <fieldset
                  className={`fl_row gp20 ${rewardSettings?.isSubscriptionRewardActive ? 'active-fieldset' : ''}`}
                >
                  <legend className="pd10-r-l">
                    <SwitcherCheckbox
                      ischecked={rewardSettings?.isFirstOrderRewardActive}
                      onChange={handleInputChange}
                      label={'Récompense sur 1er achat'}
                      name="isFirstOrderRewardActive"
                    />
                  </legend>
                  <div className="pd20-r-l">
                    <InputField
                      isRequired={true}
                      name="firstOrderRewardTotalPt"
                      type="number"
                      label={'Nombre de points à gagner'}
                      style="form"
                      isDisabled={false}
                      onChange={handleInputChange}
                      haserror={errors?.firstOrderRewardTotalPt ? true : false}
                      errorMsg={errors?.firstOrderRewardTotalPt}
                      decimal
                    />
                  </div>
                </fieldset>
              </div>
              {/* <span className="diver-bar"></span> */}

              <div className="fl_col gp20 fl-1">
                <fieldset
                  className={`fl_row gp20 ${rewardSettings?.isVisitsRewardActive ? 'active-fieldset' : ''}`}
                >
                  <legend className="pd10-r-l">
                    <SwitcherCheckbox
                      ischecked={rewardSettings?.isVisitsRewardActive}
                      onChange={handleInputChange}
                      label={'Récompense sur nombre commande'}
                      name="isVisitsRewardActive"
                    />
                  </legend>
                  <div className="fl_row gp20 pd20-r-l">
                    <InputField
                      isRequired={true}
                      name="nbOrdersForVisitsReward"
                      type="number"
                      label={'Nombre de visite requis'}
                      style="form"
                      isDisabled={false}
                      onChange={handleInputChange}
                      haserror={errors?.nbOrdersForVisitsReward ? true : false}
                      errorMsg={errors?.nbOrdersForVisitsReward}
                      decimal
                    />
                    <InputField
                      isRequired={true}
                      name="visitsRewardTotalPt"
                      type="number"
                      label={'Nombre de points à gagner'}
                      style="form"
                      isDisabled={false}
                      onChange={handleInputChange}
                      haserror={errors?.visitsRewardTotalPt ? true : false}
                      errorMsg={errors?.visitsRewardTotalPt}
                      decimal
                    />
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
          <hr className="mg20-t-b" />
          <div className="tab-two w-fit">
            <div className="fl_row gp40">
              <div className="fl_row gp20">
                <fieldset
                  className={`fl_row gp20 ${rewardSettings?.isArticlesReward ? 'active-fieldset' : ''}`}
                >
                  <legend>
                    <div className="pd20-r-l">
                      <SwitcherCheckbox
                        ischecked={rewardSettings?.isArticlesReward}
                        onChange={
                          handleInputChange
                          //setStatusArticles((prevState) => !prevState);
                        }
                        label={'Remise sur les articles'}
                        name="isArticlesReward"
                      />
                    </div>
                  </legend>

                  <div className="fl_row gp40 ai_c fl-1">
                    <InputField
                      isRequired={true}
                      name="articleRewardTotalPtRequired"
                      type="number"
                      label={'Nbr de points requis'}
                      style="form"
                      isDisabled={false}
                      onChange={handleInputChange}
                      haserror={
                        errors?.articleRewardTotalPtRequired ? true : false
                      }
                      errorMsg={errors?.articleRewardTotalPtRequired}
                      decimal
                    />
                    <InputField
                      isRequired={true}
                      name="articleRewardDiscountValue"
                      type="number"
                      label={'Valeur de remise'}
                      style="form"
                      isDisabled={false}
                      onChange={handleInputChange}
                      haserror={
                        errors?.articleRewardDiscountValue ? true : false
                      }
                      errorMsg={errors?.articleRewardDiscountValue}
                      // decimal
                    />
                    <div className="fl_row gp40 ai_c">
                      <div className="fl_col gp5">
                        <p className="p12 w-600 DarkBlueGray-txt label-field">
                          Type de remise
                        </p>
                        <div className="toggle-container">
                          <div className="item-type">
                            <input
                              type="radio"
                              id="toggle-euro-1"
                              name="articleRewardDiscountType"
                              checked={
                                rewardSettings.articleRewardDiscountType === 0
                              }
                              onChange={() =>
                                handleRadioChange(
                                  0,
                                  'articleRewardDiscountType',
                                )
                              }
                            />
                            <label
                              htmlFor="toggle-euro-1"
                              className="toggle-item"
                              id="euro"
                            >
                              €
                            </label>
                          </div>

                          <div className="item-type">
                            <input
                              type="radio"
                              id="toggle-percent-1"
                              name="articleRewardDiscountType"
                              checked={
                                rewardSettings.articleRewardDiscountType === 1
                              }
                              onChange={() =>
                                handleRadioChange(
                                  1,
                                  'articleRewardDiscountType',
                                )
                              }
                            />
                            <label
                              htmlFor="toggle-percent-1"
                              className="toggle-item"
                              id="percent"
                            >
                              %
                            </label>
                          </div>
                        </div>
                      </div>
                      <ButtonElement
                        reverse
                        buttonStyle="btn-white-primary"
                        type={'IconButton'}
                        text={'Articles'}
                        buttonSize={'large'}
                        onClick={fetchArticles}
                        icon={ArticlesIconPrimary}
                        className="article-btn"
                      />
                    </div>
                  </div>
                </fieldset>
              </div>
              <div className="fl_col gp20 fl-1">
                <fieldset
                  className={`fl_row gp20 ${rewardSettings?.isCartReward ? 'active-fieldset' : ''}`}
                >
                  <legend>
                    <div className="pd20-r-l">
                      <SwitcherCheckbox
                        ischecked={rewardSettings?.isCartReward}
                        onChange={
                          handleInputChange
                          //setStatusPanier((prevState) => !prevState);
                        }
                        label={'Remise sur panier'}
                        name="isCartReward"
                      />
                    </div>
                  </legend>
                  <div className="fl_row gp40 ai_c">
                    <InputField
                      isRequired={true}
                      name="cartRewardTotalPtRequired"
                      type="number"
                      label={'Nbr de points requis'}
                      style="form"
                      isDisabled={false}
                      onChange={handleInputChange}
                      haserror={
                        errors?.cartRewardTotalPtRequired ? true : false
                      }
                      errorMsg={errors?.cartRewardTotalPtRequired}
                      decimal
                    />
                    <InputField
                      isRequired={true}
                      name="cartRewardDiscountValue"
                      type="number"
                      label={'Valeur de remise'}
                      style="form"
                      isDisabled={false}
                      onChange={handleInputChange}
                      haserror={errors?.cartRewardDiscountValue ? true : false}
                      errorMsg={errors?.cartRewardDiscountValue}
                      // decimal
                    />
                    <div className="fl_col gp5">
                      <p className="p12 w-600 DarkBlueGray-txt label-field">
                        Type de remise
                      </p>
                      <div className="toggle-container">
                        <div className="item-type">
                          <input
                            type="radio"
                            id="toggle-euro-2"
                            name="cartRewardDiscountType"
                            checked={
                              rewardSettings.cartRewardDiscountType === 0
                            }
                            onChange={() =>
                              handleRadioChange(0, 'cartRewardDiscountType')
                            }
                          />
                          <label
                            htmlFor="toggle-euro-2"
                            className="toggle-item"
                            id="euro"
                          >
                            €
                          </label>
                        </div>

                        <div className="item-type">
                          <input
                            type="radio"
                            id="toggle-percent-2"
                            name="cartRewardDiscountType"
                            checked={
                              rewardSettings.cartRewardDiscountType === 1
                            }
                            onChange={() =>
                              handleRadioChange(1, 'cartRewardDiscountType')
                            }
                          />
                          <label
                            htmlFor="toggle-percent-2"
                            className="toggle-item"
                            id="percent"
                          >
                            %
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="ModalDeletePointFidelite"
        version="custom"
        visibility={showArticles}
        close
        maxWidth={640}
        content={
          <ModalArticles
            families={families}
            articles={articles}
            handleArrayChange={handleArrayChange}
            handleCheckboxChange={handleCheckboxChange}
            setArticleName={setArticleName}
            checheked={checheked}
            onClickValidate={() => setshowArticles(false)}
            rewardSettings={rewardSettings}
            onClickClose={() => setshowArticles(false)}
            filteredItems={filteredItems}
            setFilteredItems={setFilteredItems}
            inputValue={inputValue}
            setInputValue={setInputValue}
            filteredItemssubfamily={filteredItemssubfamily}
            setFilteredItemssubfamily={setFilteredItemssubfamily}
            inputValuesubfamily={inputValuesubfamily}
            setInputValuesubfamily={setInputValuesubfamily}
            subFamiles={subFamiles}
            // isActivated
            // onChange={}
          />
        }
        onClickClose={() => setshowArticles(false)}
      />
    </div>
  );
};

export default AddRecompensePage;
