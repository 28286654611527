import React from 'react';
import RemoveProductIcon from '../../assets/icons/RemoveProductIcon.svg';
import MarketsIconGrey from '../../assets/icons/MarketsIconGrey.svg';
import TouchpadIconGrey from '../../assets/icons/TouchpadIconGrey.svg';
import MenuPlaceHolder from '../../assets/images/MenuPlaceHolder.png';
/**
 * CartBox component description.
 *
 * @CartBox
 *
 */

interface CartBoxProps {
  name: string;
  img?: string;
  price?: string;
  prefix?: string;
  OnClickMarkets?: () => void;
  OnClickSettings?: () => void;
  onSelect?: () => void;
  selected?: boolean;
  hideAllOption?: boolean;
  background?: string;
}

const CartBox: React.FC<CartBoxProps> = ({
  name,
  img,
  OnClickMarkets,
  OnClickSettings,
  onSelect,
  selected,
  hideAllOption,
  background,
}) => {
  return (
    <div
      className={`CartBox ${selected ? 'selected' : ''} `}
      style={{ backgroundColor: background ? background : '' }}
    >
      <div className="item-wrapper fl_col ai_c">
        <img
          onClick={onSelect}
          width={150}
          src={img || MenuPlaceHolder}
          alt=""
          className="item-img"
        />
        <div className="item-footer">
          <div className="footer-wrapper fl_col ai_c gp8">
            <p
              title={name}
              className="p16 Independence-txt w-700 txt_center mx1"
            >
              {name}
            </p>
            {/*  <p className="p14 Independence-txt w-700 price">
              {price} <span>{prefix}</span>
            </p> */}

            {!hideAllOption && (
              <div className="fl_row gp10">
                {OnClickMarkets && (
                  <img
                    className="c-p"
                    src={MarketsIconGrey}
                    alt=""
                    onClick={OnClickMarkets}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartBox;
