import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import ButtonElement from '../../../components/button/button';
import PlusIconWhite from '../../../assets/icons/PlusIconWhite.svg';
import InputField from '../../../components/Input/InputCatalogue';
import SearchIconPrimary from '../../../assets/icons/SearchIconPrimary.svg';
import { useLocation } from 'react-router-dom';
import RadioButtonGroup from '../../../components/radioButton/RadioButtonGroup';
import { useDispatch } from 'react-redux';
import {
  ClearSelectAllMenu,
  fetchActionCategoriesRequest,
  fetchActionMenusRequest,
  getAlllevels,
  requestMultipleLevels,
  setSelectAll,
  setSelectAllCategories,
  setSelectAllMenu,
  setSelectedIds,
  setSelectedIdsCategories,
  setSelectedIdsMenu,
  startGetCategories,
  startGetMenu,
} from '../../../store/actions/catalogues/actions';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { serviceTabsModels } from '../../../models/services/module.tabs';
import { Menu } from '../../../models/services/module.catalogue';
import { CategorySingle } from '../../../models/services/module.category';
export default function TopSearchBarCatalogueContainer({
  onChange,
  onShowModal,
}: {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onShowModal: (value: boolean) => void;
}) {
  const [selectedAction, setSelectedAction] = useState<number | null>(null);
  const [levels, setlevels] = useState<any>();
  const [menus, setmenus] = useState<Menu[]>([]);
  const [categories, setcategories] = useState<CategorySingle[]>([]);
  const activeTabContent = useSelector(
    (state: { tabs: serviceTabsModels.TabsState }) =>
      state.tabs.activeTabContent,
  );
  const location = useLocation();
  const refInput = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  const selectAll = useSelector(
    (state: { selectionReducer: { selectAll: boolean } }) =>
      state.selectionReducer.selectAll,
  );

  const selectedIds = useSelector(
    (state: { selectionReducer: { selectedIds: number[] } }) =>
      state.selectionReducer.selectedIds,
  );

  const selectAllMenu = useSelector(
    (state: { selectionMenuReducer: { selectAllMenu: boolean } }) =>
      state.selectionMenuReducer.selectAllMenu,
  );
  const selectedIdsMenu = useSelector(
    (state: { selectionMenuReducer: { selectedIdsMenu: number[] } }) =>
      state.selectionMenuReducer.selectedIdsMenu,
  );

  const selectAllCategories = useSelector(
    (state: { selectionCategoriesReducer: { selectAllCategories: boolean } }) =>
      state.selectionCategoriesReducer.selectAllCategories,
  );
  const selectedIdsCategories = useSelector(
    (state: {
      selectionCategoriesReducer: { selectedIdsCategories: number[] };
    }) => state.selectionCategoriesReducer.selectedIdsCategories,
  );
  const searchArrayReducer = useSelector(
    (state: any) => state.searchArrayReducer.searchArrayResults,
  );

  const searchArrayResultsCategories = useSelector(
    (state: any) => state.searchArrayReducer.searchArrayResultsCategories,
  );

  const searchArrayResultsMenu = useSelector(
    (state: any) => state.searchArrayReducer.searchArrayResultsMenu,
  );

  const MenuPlace = useSelector(
    (state: { MenuReducer: { MenuPlace: string } }) =>
      state.MenuReducer.MenuPlace,
  );

  useEffect(() => {
    let currentPath = location.pathname;

    if (currentPath.includes('catalogue')) {
      if (refInput.current) {
        console.log('GGGGG');
        refInput.current.value = '';
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    dispatch(
      getAlllevels((_) => {
        setlevels(_ as any);
      }),
    );

    dispatch(
      startGetMenu((_) => {
        setmenus(_ as any);
      }),
    );

    dispatch(
      startGetCategories((_) => {
        setcategories(_ as CategorySingle[]);
      }),
    );
  }, []);
  useEffect(() => {
    dispatch(ClearSelectAllMenu());
  }, [MenuPlace]);

  const handleSelectAllChange = (e: any) => {
    const isChecked = e.target.checked;
    dispatch(setSelectAll(isChecked));

    if (isChecked) {
      const allIds =
        searchArrayReducer?.length == 0
          ? levels?.map((level: any) => level.id)
          : searchArrayReducer?.map((level: any) => level.id);
      dispatch(setSelectedIds(allIds));
    } else {
      dispatch(setSelectedIds([]));
    }
  };
  //slect all for the menu
  const handleSelectAllChangeMenu = (e: any) => {
    const isChecked = e.target.checked;
    dispatch(setSelectAllMenu(isChecked));

    if (isChecked) {
      const allIds =
        searchArrayResultsMenu?.length == 0
          ? menus?.map((menu: any) => menu.id)
          : searchArrayResultsMenu?.map((menu: any) => menu.id);

      dispatch(setSelectedIdsMenu(allIds));
    } else {
      dispatch(setSelectedIdsMenu([]));
    }
  };

  //slect all for the Categories
  const handleSelectAllChangeCategories = (e: any) => {
    const isChecked = e.target.checked;
    dispatch(setSelectAllCategories(isChecked));

    if (isChecked) {
      const allIds =
        searchArrayResultsCategories?.length == 0
          ? categories?.map((categorie: any) => categorie.id)
          : searchArrayResultsCategories?.map((categorie: any) => categorie.id);

      dispatch(setSelectedIdsCategories(allIds));
    } else {
      dispatch(setSelectedIdsCategories([]));
    }
  };

  useEffect(() => {
    dispatch(
      getAlllevels((_) => {
        setlevels(_ as any);
      }),
    );
  }, []);

  useEffect(() => {
    dispatch(
      startGetMenu((_) => {
        setmenus(_ as any);
      }),
    );
  }, []);

  useEffect(() => {
    dispatch(
      startGetCategories((_) => {
        setcategories(_ as CategorySingle[]);
      }),
    );
  }, []);

  useEffect(() => {
    const hasAllSelected =
      selectedIds?.length ===
      (searchArrayReducer?.length === 0
        ? levels?.length
        : searchArrayReducer?.length);

    dispatch(setSelectAll(hasAllSelected));
  }, [selectedIds, levels, searchArrayReducer]);

  useEffect(() => {
    const hasAllSelected =
      selectedIdsMenu?.length ===
      (searchArrayResultsMenu?.length === 0
        ? menus?.length
        : searchArrayResultsMenu?.length);

    dispatch(setSelectAllMenu(hasAllSelected));
  }, [selectedIdsMenu, menus]);

  //useEffect for the categories
  useEffect(() => {
    const hasAllSelected =
      selectedIdsCategories?.length ===
      (searchArrayResultsCategories?.length === 0
        ? categories?.length
        : searchArrayResultsCategories?.length);

    dispatch(setSelectAllCategories(hasAllSelected));
  }, [selectedIdsCategories, categories]);

  const handleSubmit = (action: string) => {
    if (selectedIds?.length == 0) {
      toast.error('Veuillez sélectionner des articles');
      return;
    }

    // if (action === '0') {
    //   const isConfirmed = confirm('Etes-vous sûr de vouloir supprimer ?');
    //   if (!isConfirmed) {
    //     dispatch(setSelectedIds([]));
    //     setSelectedAction(null);
    //     return;
    //   }
    // }
    // if (action === '1') {
    //   const isConfirmed = confirm(
    //     'Etes-vous sûr de vouloir Activer Visibilité?',
    //   );
    //   if (!isConfirmed) {
    //     dispatch(setSelectedIds([]));
    //     setSelectedAction(null);
    //     return;
    //   }
    // }

    // if (action === '2') {
    //   const isConfirmed = confirm(
    //     'Etes-vous sûr de vouloir Désactiver Visibilité ?',
    //   );
    //   if (!isConfirmed) {
    //     dispatch(setSelectedIds([]));
    //     setSelectedAction(null);
    //     return;
    //   }
    // }

    dispatch(
      requestMultipleLevels(
        parseInt(activeTabContent?.companyId ?? ''),
        parseInt(action),
        selectedIds,
        () => {
          dispatch(getAlllevels());
          dispatch(setSelectedIds([]));
          setSelectedAction(null);
        },
      ),
    );
  };

  const handleSubmitMenu = (action: string) => {
    if (selectedIdsMenu?.length == 0) {
      toast.error('Veuillez sélectionner des articles');
      return;
    }

    // if (action === '0') {
    //   const isConfirmed = confirm('Etes-vous sûr de vouloir supprimer ?');
    //   if (!isConfirmed) {
    //     dispatch(setSelectedIdsMenu([]));
    //     setSelectedAction(null);
    //     return;
    //   }
    // }
    // if (action === '1') {
    //   const isConfirmed = confirm(
    //     'Etes-vous sûr de vouloir Activer Visibilité?',
    //   );
    //   if (!isConfirmed) {
    //     dispatch(setSelectedIdsMenu([]));
    //     setSelectedAction(null);
    //     return;
    //   }
    // }

    // if (action === '2') {
    //   const isConfirmed = confirm(
    //     'Etes-vous sûr de vouloir Désactiver Visibilité ?',
    //   );
    //   if (!isConfirmed) {
    //     dispatch(setSelectedIdsMenu([]));
    //     setSelectedAction(null);
    //     return;
    //   }
    // }

    dispatch(
      fetchActionMenusRequest(
        parseInt(activeTabContent?.companyId ?? ''),
        parseInt(action),
        selectedIdsMenu,
        () => {
          dispatch(startGetMenu());
          dispatch(setSelectedIdsMenu([]));
          setSelectedAction(null);
        },
      ),
    );
  };

  const handleSubmitCategories = (action: string) => {
    if (selectedIdsCategories?.length == 0) {
      toast.error('Veuillez sélectionner des articles');
      return;
    }

    // if (action === '0') {
    //   const isConfirmed = confirm('Etes-vous sûr de vouloir supprimer ?');
    //   if (!isConfirmed) {
    //     dispatch(setSelectedIdsCategories([]));
    //     setSelectedAction(null);
    //     return;
    //   }
    // }
    // if (action === '1') {
    //   const isConfirmed = confirm(
    //     'Etes-vous sûr de vouloir Activer Visibilité?',
    //   );
    //   if (!isConfirmed) {
    //     dispatch(setSelectedIdsCategories([]));
    //     setSelectedAction(null);
    //     return;
    //   }
    // }

    // if (action === '2') {
    //   const isConfirmed = confirm(
    //     'Etes-vous sûr de vouloir Désactiver Visibilité ?',
    //   );
    //   if (!isConfirmed) {
    //     dispatch(setSelectedIdsCategories([]));
    //     setSelectedAction(null);
    //     return;
    //   }
    // }

    dispatch(
      fetchActionCategoriesRequest(
        parseInt(activeTabContent?.companyId ?? ''),
        parseInt(action),
        selectedIdsCategories,
        () => {
          dispatch(startGetCategories());
          dispatch(setSelectedIdsCategories([]));
          setSelectedAction(null);
        },
      ),
    );
  };

  return (
    <div className="TopSearchBarCatalogueContainer fl_col rd8 pd20-r-l">
      <div className="bar-wrapper fl_row jc_s ai_c">
        <div className="search-inp fl_row">
          <InputField
            reff={refInput}
            type="text"
            name="searchCatalogue"
            placeholder="Recherche..."
            // value=""
            icon={SearchIconPrimary}
            onChange={onChange}
            style="search"
            className="rd20 p10 br1LightGrey"
          />
        </div>
        <div className="add-btn fl_row">
          <ButtonElement
            onClick={() => onShowModal(true)}
            className="btnSearch"
            icon={PlusIconWhite}
            radius="rd2"
            textSize="p15 w-500"
            text="Nouveau"
            type="IconButton"
            buttonStyle="btn-primary"
            buttonSize={'medium'}
            textColor="white-txt"
          />
        </div>
      </div>
      <div className="multi-actions-filter">
        {MenuPlace === 'Categories' ||
        MenuPlace === 'Menu' ||
        MenuPlace === 'Level' ? (
          <div className="fl_row gp40 filter-selection">
            <div className="fl_row ai_c gp10">
              <input
                className="checkbox-item"
                type="checkbox"
                checked={
                  MenuPlace === 'Level'
                    ? selectAll
                    : MenuPlace === 'Menu'
                      ? selectAllMenu
                      : MenuPlace === 'Categories'
                        ? selectAllCategories
                        : false
                }
                onChange={
                  MenuPlace === 'Level'
                    ? handleSelectAllChange
                    : MenuPlace === 'Menu'
                      ? handleSelectAllChangeMenu
                      : MenuPlace === 'Categories'
                        ? handleSelectAllChangeCategories
                        : () => {
                            return;
                          }
                }
              />
              <label>Tout sélectionner</label>
            </div>
            {selectedIds?.length == 0 &&
            selectedIdsMenu?.length == 0 &&
            selectedIdsCategories?.length == 0 ? (
              ''
            ) : (
              <RadioButtonGroup
                selectedAction={selectedAction}
                setSelectedAction={setSelectedAction}
                handleSubmit={
                  MenuPlace === 'Level'
                    ? handleSubmit
                    : MenuPlace === 'Menu'
                      ? handleSubmitMenu
                      : MenuPlace === 'Categories'
                        ? handleSubmitCategories
                        : () => {
                            return;
                          }
                }
              />
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
