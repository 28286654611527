import React from 'react';
import Step0 from './Step0';
import Step1_Redevance from './Step1_Redevance';
import { useDispatch, useSelector } from 'react-redux';
import { serviceTaxesModels } from '../../../../../models/services/module.taxesInfos';
import CountriesFlagsAndCodes, {
  CountriesFlagsAndCodesItem,
} from '../../../../../data/CountriesFlagsAndCode';
import { extractDigits } from '../../../../../utils/PhoneNumbersUtils';
import { isValidFiscalInformation } from '../../../../../utils/isValidFiscalInformation';
import tabs_list from '../../../../../data/tabsList';
import { unsavedChangesRegister } from '../../../../../store/actions/actions';
import { useUnsavedChanges } from '../../useUnsavedChanges';
import useLegalFormLogic from '../../../FormWizzard/useLegalFormLogic';
import { serviceFormesJuridiquesModels } from '../../../../../models/services/module.fomesJuridiques';
import formatDateToISOString from '../../../../../utils/DateFormatter';
import { Step1GeneralInfo } from './Step1GeneralInfo';
import StepWizzard from '../StepWizzard';
import { serviceEtablissementModels } from '../../../../../models/services/module.etablissement';

export default function Step1() {
  const dispatch = useDispatch();
  const [phoneCode, setPhoneCode] = React.useState<string>();
  const verifResponse = useSelector(
    (state: { taxesInfos: serviceTaxesModels.TaxesInfosState }) =>
      state.taxesInfos.taxesInfosResponseEtablissement,
  );
  console.log('verifResponse', verifResponse);
  const addEtablissementStep = useSelector(
    (state: { etablissement: serviceEtablissementModels.EtablissementState }) =>
      state.etablissement.addEtablissementStep,
  );
  const { newFormData } = useUnsavedChanges();
  const { formes } = useLegalFormLogic();
  const onSelectItem = (selectedValue: string, field?: string) => {
    let data;
    switch (field) {
      case 'country':
        data = selectedValue;
        break;
      case 'legal_form_id':
        data = formes?.find(
          (item: serviceFormesJuridiquesModels.FormesJuridiques) =>
            item.designation === selectedValue,
        )?.id;
        break;
      default:
        const selectedItem = CountriesFlagsAndCodes?.find(
          (item: CountriesFlagsAndCodesItem) => item.code === selectedValue,
        );

        if (selectedItem) {
          data = selectedItem.phoneCode?.toString();
          setPhoneCode(selectedItem.phoneCode?.toString());
        }
    }

    if (field) {
      const array = [
        {
          key: tabs_list[1].name,
          value: {
            type: 'action',
            payload: {
              payload: {
                ...newFormData.payload,
                generalInformation: {
                  ...newFormData?.payload?.generalInformation,
                  [field]: data,
                },
              },
            },
          },
        },
      ];
      dispatch(unsavedChangesRegister(array));
    }
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let data;
    switch (name) {
      case 'construction_date':
        data = formatDateToISOString(value);
        break;
      case 'fixed_line_telephone_number':
        data =  extractDigits(value);
        break;
      case 'mobile_telephone_number':
        data = extractDigits(value);
        break;
      default:
        data = value;
        break;
    }
    const array = [
      {
        key: tabs_list[1].name,
        value: {
          type: 'action',
          payload: {
            payload: {
              ...newFormData?.payload,
              generalInformation: {
                ...newFormData?.payload?.generalInformation,
                [name]: data,
              },
            },
          },
        },
      },
    ];
    dispatch(unsavedChangesRegister(array));
  };
  return (
    <div className="fl_col gp10">
      <Step0 />
      {isValidFiscalInformation(verifResponse) ? (
        <React.Fragment>
          <StepWizzard
            className="FormWizzardContentCard fl_col gp20 ai_c"
            stepNumber={1}
            isActive={addEtablissementStep === 1}
          >
            <Step1GeneralInfo
              handleChange={handleChange}
              onSelect={onSelectItem}
            />
          </StepWizzard>

          {/* <Step1_Redevance /> */}
        </React.Fragment>
      ) : null}
    </div>
  );
}
