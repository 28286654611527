import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IngredientsNav from '../../components/Catalogue/navbar/IngredientsNav';
import {
  ArticleSaved,
  Composition,
  Family,
  getArticleParams,
} from '../../models/services/module.catalogue';
import {
  dragCompostion,
  getArticle,
  getFamily,
  getSubFamily,
  updateCompositions,
  updateDeletedIngredients,
} from '../../store/actions/catalogues/actions';
import { NotificationErrorProvider } from '../../utils/Notificationprovider';
import { convertToOptions } from '../../utils/catalogueHelper/FamilyHelper';
import { convertArticleToComposition } from '../../utils/catalogueHelper/articleHelper';
export default function IngredientNavbarContainer(props: any) {
  const [families, setfamilies] = useState<Family[]>();
  const compositions = useSelector(
    (state: { compositionStates: { compositions: Composition[] } }) =>
      state.compositionStates.compositions,
  );
  const deletedIngredients = useSelector(
    (state: { compositionStates: { deletedIngredients: Composition[] } }) =>
      state.compositionStates.deletedIngredients,
  );
  const pathname = props.currentPath ?? '';

  const id = pathname?.split('/').pop();

  const pathEdit = pathname.includes('edit');
  const compositionsChange = useSelector(
    (state: { compositionStates: { compositionsChange: number } }) =>
      state.compositionStates.compositionsChange,
  );
  const [subFamiles, setsubFamiles] = useState<Family[]>();
  const [paramsArticle, setparamsArticle] = useState<getArticleParams>({
    pageSize: 1000,
  });
  const sidebarMode = useSelector(
    (state: {
      uiCatalogue: {
        sideBarMode: 'basic' | 'ing' | 'alg' | 'menu' | 'category';
      };
    }) => state.uiCatalogue.sideBarMode,
  );
  const [articles, setArticles] = useState<ArticleSaved[]>();
  const dispatch = useDispatch();
  const fetchFamily = async () => {
    try {
      dispatch(
        getFamily((_) => {
          setfamilies(_);
        }),
      );
    } catch (error) {
      NotificationErrorProvider(error);
    }
  };
  const getSubfmilyFn = async (idFamily: number) => {
    try {
      dispatch(
        getSubFamily(idFamily, (_) => {
          let subFamiles = _?.inverseIdSubFamilyNavigation;
          setsubFamiles(subFamiles);
        }),
      );
    } catch (error) {
      console.log(error);
    }
  };
  const withDisabledArticles = (
    compositions: Composition[],
    articles: ArticleSaved[],
  ): ArticleSaved[] =>
    articles?.map((article) => {
      const disabledSet = new Set(
        compositions?.map((composition) =>
          pathEdit && composition.idIngredient
            ? composition.idIngredient
              ? composition.idIngredient
              : composition.idArticle
            : composition.idArticle,
        ),
      );
      id ? disabledSet.add(parseInt(id)) : null;
      return {
        ...article,
        selected: false,
        disabled: disabledSet.has(article.id),
      };
    });
  const fetchArticles = async (params?: getArticleParams) => {
    try {
      dispatch(
        getArticle(params as getArticleParams, (_) => {
          setArticles(withDisabledArticles(compositions, _ as ArticleSaved[]));
        }),
      );
    } catch (error) {
      NotificationErrorProvider(error);
    }
  };
  useEffect(() => {
    if (compositions?.length >= 0)
      setArticles(
        withDisabledArticles(compositions, articles as ArticleSaved[]),
      );
  }, [compositions, compositionsChange]);
  const onchange = (type: 'family' | 'subfamily', value: number) => {
    if (value == -1) {
      type == 'family' ? setsubFamiles([]) : null;
      setparamsArticle(
        type == 'subfamily'
          ? { idFamily: paramsArticle.idFamily, pageSize: 1000 }
          : { pageSize: 1000 },
      );
    } else {
      if (type == 'family') {
        setsubFamiles([]);
        getSubfmilyFn(value);
        setparamsArticle({
          idFamily: value,
          pageSize: 1000,
        });
      } else {
        setparamsArticle({
          ...paramsArticle,
          idSubFamily: value,
          pageSize: 1000,
        });
      }
    }
  };
  useEffect(() => {
    fetchFamily();
  }, []);
  useEffect(() => {
    fetchArticles(paramsArticle);
  }, [paramsArticle]);
  const onDrag = (Article: ArticleSaved) => {
    let t = convertArticleToComposition(Article);

    let composition =
      deletedIngredients.findIndex((c) => c.idIngredient == t.id) > -1
        ? deletedIngredients[
            deletedIngredients.findIndex((c) => t.id == c.idIngredient)
          ]
        : convertArticleToComposition(Article);
    if (deletedIngredients.findIndex((c) => c.idIngredient == t.id) > -1) {
      dispatch(
        updateDeletedIngredients(
          deletedIngredients.filter((i) => i.idIngredient != t.id),
        ),
      );
    }
    console.log({composition});
    dispatch(dragCompostion(composition as Composition));
  };
  const onDragSelected = (Articles: ArticleSaved[]) => {
    let updatedDeletedIngredients = [...deletedIngredients];
    let compositionsArray = Articles.map((Article) => {
      let t = convertArticleToComposition(Article);
      let composition =
        updatedDeletedIngredients.findIndex((c) => c.idIngredient == t.id) > -1
          ? updatedDeletedIngredients[
              updatedDeletedIngredients.findIndex((c) => t.id == c.idIngredient)
            ]
          : convertArticleToComposition(Article);

      if (
        updatedDeletedIngredients.findIndex((c) => c.idIngredient == t.id) > -1
      ) {
        updatedDeletedIngredients = updatedDeletedIngredients.filter(
          (i) => i.idIngredient != t.id,
        );
      }
      return composition as Composition;
    });

    dispatch(updateDeletedIngredients(updatedDeletedIngredients));
    dispatch(updateCompositions([...compositions, ...compositionsArray]));
  };
  useEffect(() => {
    if (compositionsChange > 0)
      setTimeout(() => {
        //  scrollToBottomOfTable('ingredientsTable');
      }, 100);
  }, [compositionsChange]);
  const onSelect = (article: ArticleSaved, checked: boolean, all?: boolean) => {
    if (articles)
      setArticles(
        all
          ? articles.map((c) => ({ ...c, selected: checked }))
          : articles.map((c) =>
              c.id === article.id ? { ...c, selected: checked } : c,
            ),
      );
  };
  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setparamsArticle({
      ...paramsArticle,
      nom: e.target.value,
      pageSize: 1000,
    });
  };
  return (
    <div className="h-full">
      <IngredientsNav
        hideTitle={sidebarMode == 'category'}
        hidePadding={sidebarMode == 'category'}
        allChecked={
          articles?.filter((article) => article.selected).length ==
          articles?.length
        }
        onSearch={onSearch}
        topAddEnabled={
          articles && articles?.filter((ar) => ar.selected).length > 0
        }
        onDragSelected={() =>
          onDragSelected(
            (articles as ArticleSaved[]).filter(
              (ar) => ar.selected && !ar.disabled,
            ),
          )
        }
        onSelect={onSelect}
        onDrag={onDrag}
        articles={articles as ArticleSaved[]}
        onChange={onchange}
        families={convertToOptions(families ?? [])}
        subFamilies={convertToOptions(subFamiles ?? [])}
      ></IngredientsNav>
    </div>
  );
}
