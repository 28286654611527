import React from 'react';
import ButtonElement from '../../components/button/button';
import CartCheckboxChildElement from '../../components/Carts/CartCheckboxChildElement';
import CartCheckboxParentElement from '../../components/Carts/CartCheckboxParentElement';
import { languageData } from '../../i18n';
import CloseBtnPrimary from '../../../assets/icons/CloseBtnPrimary.svg';
import InputField from '../../components/Input/Input';
import CheckBox from '../../components/Catalogue/Checkbox';
import CartCheckboxParentElementFidelite from '../../components/Carts/CartCheckboxParentElementFidelite';

/**
 * Modal component description.
 *
 * @Modal
 *
 */

interface ModalPointsDeVentesProps {
  onClickValidate: () => void;
  onClickClose: () => void;
  loading?: boolean;
  establishments?: any;
  onChange?: any;
  updateBook?: any;
  checked?: boolean;
  selectedBooks?: any;
  localBooks?: any;
  handleCheckboxChange?: any;
  error?: boolean;
  confirmeffectedIstablishmentfalse?: any;
  listOfEstablisment?:any
}

const ModalPointsDeVentes: React.FC<ModalPointsDeVentesProps> = ({
  onClickValidate,
  onClickClose,
  loading,
  establishments,
  checked,
  onChange,
  updateBook,
  selectedBooks,
  localBooks,
  handleCheckboxChange,
  confirmeffectedIstablishmentfalse,
  error,
  listOfEstablisment
}) => {
  console.log('localBooksssss', localBooks);
  return (
    <div className="ModalPointsDeVentes">
      <div className="listing-list fl_col">
        <div className="step-header">
          <div className="fl_row gp40 ai_c jc_s header-container">
            {/* <p className="p16 w-700">Liste des établissements</p> */}
            {/* <div className="search-inp fl-1">
              <InputField
                // onChange={() => alert('')}
                type="text"
                placeholder="Recherche..."
                style="search"
              />
            </div> */}
          </div>
        </div>
        <CartCheckboxParentElementFidelite
          name={languageData.fr.navMenu.selectionnerTous}
          isChecked={checked}
          onChange={onChange}
        >
          {localBooks?.map((establishment: any) => (
            <CartCheckboxChildElement
              name={establishment?.commercial_Name}
              //isChecked={establishment?.isChecked}
              isChecked={establishment?.isChecked}
              onChange={() => handleCheckboxChange(establishment?.id)}
            />
          ))}
        </CartCheckboxParentElementFidelite>
      </div>
      <div className="ModalFooter">
        <div className="wrapperFooter">
          <div className="fl_row gp12">
            <div className="fl-1">
              <ButtonElement
                type={'TextButton'}
                text={languageData.fr.Modal.cancel}
                buttonSize={'small'}
                onClick={onClickClose}
              />
            </div>
            <div className="fl-3">
              <ButtonElement
                type={'TextButton'}
                text="Valider"
                buttonSize={'small'}
                // isDisabled={loading || localBooks.length == 0}
                buttonStyle="btn-primary"
                onClick={onClickValidate}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalPointsDeVentes;
